import React from "react";
import { withStyles, Theme, createStyles, Typography, WithStyles, IconButton, SvgIcon } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

export type TItemComponentInkDrops = { color_name: string, color_name_color: string, color: string, up_drops: number, down_drops: number, up_drops_cost: string, down_drops_cost: string };

export interface IPropsComponentInkDrops extends WithStyles<typeof styles> {
    items: Array<TItemComponentInkDrops>;
    up_ink_text: string;
    up_cost_text: string;
    down_ink_text: string;
    down_cost_text: string;
    go_right_icon?: React.ReactElement;
    go_left_icon?: React.ReactElement;
    onRight?: () => void;
    onLeft?: () => void;
}

enum TCurrentView {
    INK,
    COST
}

interface IStateComponentInkDrops {
    current_view: TCurrentView
}

const styles = (theme: Theme) => createStyles({
    oneRow:
    {
        flex: 1,
        textAlign: "center"
    },
    oneRowHighlight:
    {
        flex: 1,
        backgroundColor: "rgba(0,0,0,0.2)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    change_button:
    {

    },
    textResize:
    {
        fontSize: "0.9em"
    }
});

class ComponentInkDrops extends React.Component<IPropsComponentInkDrops, IStateComponentInkDrops>
{
    readonly state =
        {
            current_view: TCurrentView.COST
        }


    render() {
        let classes = this.props.classes;
        return (
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", position: "relative" }}>



                <div className={classes.oneRowHighlight} style={{ maxHeight: "2vw" }}>
                    {this.props.go_left_icon ?
                        <IconButton onClick={this.props.onLeft}>
                            {this.props.go_left_icon}
                        </IconButton>
                        : null}
                    <Typography style={{ flex: 1, textAlign: "center", fontSize: "100%" }}>{this.state.current_view == TCurrentView.INK ? this.props.up_ink_text : this.props.up_cost_text}</Typography>
                    {this.props.go_right_icon ?
                        <IconButton onClick={this.props.onRight}>
                            {this.props.go_right_icon}
                        </IconButton>
                        : null}

                </div>
                <div className={classes.oneRow} style={{ display: "flex" }}>
                    {this.props.items.map(item =>
                        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                            <div style={{ flex: 1 }}></div>
                            <div className={classes.oneRow} style={{ flex: 0 }}>
                                <Typography style={{ textAlign: "right" }} className={classes.textResize}>{(this.state.current_view === TCurrentView.INK ? item.up_drops : item.up_drops_cost).toLocaleString("en")}</Typography>
                            </div>
                            <div className={classes.oneRow} style={{ backgroundColor: item.color, textAlign: "center", padding: 0, margin: 0, maxHeight: 12, width: "100%", display: "flex" }}>
                                <Typography style={{ color: item.color_name_color, fontWeight: "bold", fontSize: 12, textAlign: "center", flex: 1, lineHeight: 1, padding: 0, margin: 0 }}>{item.color_name}</Typography>
                            </div>
                            <div className={classes.oneRow} style={{ flex: 0 }}>
                                <Typography style={{ textAlign: "right" }} className={classes.textResize}>{(this.state.current_view === TCurrentView.INK ? item.down_drops : item.down_drops_cost).toLocaleString("en")}</Typography>
                            </div>
                            <div style={{ flex: 1 }}></div>
                        </div>
                    )}
                    <ToggleButtonGroup
                        value={this.state.current_view}
                        exclusive
                        orientation="vertical"
                        aria-label="cost vs ink"
                        style={{ height: "100%" }}
                        onChange={(e, new_view) => { this.setState({ current_view: new_view }) }}
                    >
                        <ToggleButton value={TCurrentView.COST} aria-label="cost" style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0, height: "50%", borderRadius: 0 }}>
                            <SvgIcon>
                                <path fill="currentColor" d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z" />
                            </SvgIcon>
                        </ToggleButton>
                        <ToggleButton value={TCurrentView.INK} aria-label="ink" style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0, height: "50%", borderRadius: 0 }}>
                            <SvgIcon className={classes.change_button}>
                                <path fill="currentColor" d="M12,3.77L11.25,4.61C11.25,4.61 9.97,6.06 8.68,7.94C7.39,9.82 6,12.07 6,14.23A6,6 0 0,0 12,20.23A6,6 0 0,0 18,14.23C18,12.07 16.61,9.82 15.32,7.94C14.03,6.06 12.75,4.61 12.75,4.61L12,3.77M12,6.9C12.44,7.42 12.84,7.85 13.68,9.07C14.89,10.83 16,13.07 16,14.23C16,16.45 14.22,18.23 12,18.23C9.78,18.23 8,16.45 8,14.23C8,13.07 9.11,10.83 10.32,9.07C11.16,7.85 11.56,7.42 12,6.9Z" />
                            </SvgIcon>
                        </ToggleButton>

                    </ToggleButtonGroup>
                </div>
                <div className={classes.oneRowHighlight} style={{ maxHeight: "2vw" }}>
                    <Typography style={{ flex: 1, textAlign: "center", fontSize: "100%" }}>{this.state.current_view == TCurrentView.INK ? this.props.down_ink_text : this.props.down_cost_text}</Typography>
                </div>

            </div>
        )
    }


}

export default withStyles(styles, { withTheme: true })(ComponentInkDrops);