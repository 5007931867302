"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgStatistics = exports.StatisticsTexts = exports.StatisticsItems = exports.StatisticsItem = void 0;
class StatisticsItem {
}
exports.StatisticsItem = StatisticsItem;
class StatisticsItems {
}
exports.StatisticsItems = StatisticsItems;
class StatisticsTexts {
}
exports.StatisticsTexts = StatisticsTexts;
class MsgStatistics {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgStatistics = MsgStatistics;
