import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      overflowY: "auto",
      color: theme.palette.text.primary
    },
    entriesContainer: {
      padding: 5,
      height: 0
    },
    entry: {
      fontWeight: "bold"
    }
});

interface PropsComponentLog extends WithStyles<typeof styles> {
  log_entries: Array<string>
}

class ComponentLog extends React.Component<PropsComponentLog, {}> {


  render() {
    
    let classes = this.props.classes;
    
    let log_entries = this.props.log_entries.map((item, index) => {
        return (
            <Typography variant="subtitle1" className={classes.entry} key={index}>
                {item}
            </Typography>
        )
    });

    return (
     <div className={classes.root}>
         <div className={classes.entriesContainer}>
          {log_entries}
         </div>
     </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ComponentLog);