"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgRegisterView = void 0;
class MsgRegisterView {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgRegisterView = MsgRegisterView;
;
