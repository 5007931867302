import { Typography, LinearProgress } from "@material-ui/core";
import React, { useState } from "react"

export function ConnectionLostBar(visible: boolean, offset?: string) {
  // const [dmn_click_counter, set_dmn_click_counter] = useState(0)
  return (
    true ?
      <div style={{ width: "100%", marginLeft: offset ?? "1vw"}}
        // onClick={()=>{set_dmn_click_counter(dmn_click_counter+1)}}
        hidden={visible}
        aria-labelledby="form-dialog-title"
      >
        <Typography style={{ color: "#000000" }}>Connection was lost to the controller. Reconnecting...</Typography>
        <LinearProgress style={{ marginTop: 20 }} color="secondary" />

      </div> : <></>
  );
}
