import * as React from 'react';
import ComponentLog from "./core/ComponentLog";
import { ComponentTabPanel } from "./core/ComponentTabPanel";
import { ResourceQueueItemJob } from "./core/ResourceQueueItemJob";
import { Tabs, Tab, SvgIcon } from '@material-ui/core';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ComponentJobLibraryPage from './job-library-page/ComponentJobLibraryPage';
import ComponentDashboardPage from './dashboard-page/ComponentDashboardPage';
import ComponentJobEditorPage from './job-editor-page/ComponentJobEditorPage';
import { TStateSwitchComponentInchChangeMm } from './ComponentInchChangeMm';
import { withTranslation, WithTranslation } from 'react-i18next';
import ComponentMaintenancePage from './maintenance-page/ComponentMaintenancePage';
import ComponentSettingsPage from './settings-page/ComponentSettingsPage';
import { TViewUnit } from '@ginf/inkstorm-msg';
import { RootState } from './RootReducer';
import { connect, ConnectedProps } from 'react-redux';
import { TTypeActionReducerApp } from './ReducerApp';
import ComponentQuickripPage from './quickrip-page/ComponentQuickripPage';
import ComponentIFrame from './ComponentIFrame';
import { PostEventListener } from './PostMessage_Listener';

export interface IConfigOnePage {
  id: number;
  page_url: string;
  page_translation_text: string;
  page_icon: string;
}


declare global {
  interface Window {
    inkstorm_config?: {
      actor_socketio_registry_url?: string;
      dashboard_side_frame_url?: string,
      component_frame_url?: string,
      maintenance_pages?: Array<IConfigOnePage>;
      mm_inch_dashboard: boolean,
      mm_inch_job_library: boolean,
      mm_inch_job_editor: boolean,
      settings_pages?: Array<IConfigOnePage>;
      quickrip_pages?: Array<IConfigOnePage>;
    },
    oem_config?: {
      maintenance_pages?: Array<IConfigOnePage>;
      settings_pages?: Array<IConfigOnePage>;
      dashboard_side_frame_url?: string,
      component_frame_url?: string,

      mm_inch_dashboard?: boolean,
      mm_inch_job_library?: boolean,
      mm_inch_job_editor?: boolean,
    }
  }
}

const styles = (theme: Theme) => createStyles({
  tabs_indicator: {
    backgroundColor: theme.palette.text.primary
  },
  tabs: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default
  },
  tab: {
    flex: 1,
    maxWidth: 500
  },
  tab_selected: {
    backgroundColor: theme.palette.action.hover
  },
  dummy_page: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "row",
    height: "100%"
  },
  iframe: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.default,
  }
});



export interface IReduxStateApp {
  mm_or_inch: TStateSwitchComponentInchChangeMm;
  log_entries: Array<string>;
}

const mapState = (state: RootState): IReduxStateApp => (
  {
    mm_or_inch: state.App.mm_or_inch,
    log_entries: state.App.log_entries
  });

const mapDispatch = {
  unitViewChanged: (new_unit: TStateSwitchComponentInchChangeMm) => ({ type: TTypeActionReducerApp.USER_CHANGED_UNIT_VIEW, data: new_unit }),
}

// Do the first half of the `connect()` call separately, 
// before declaring the component
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector> & WithTranslation;


interface StateApp {
  selected_tab_id: number;
  tab_position: string;
  job_library_page_visible: boolean;
  show_log: boolean;
  show_frame: boolean;
  theme_text: string;
  editing: { filename: string, filepath: string }
}


interface PropsApp extends PropsFromRedux, WithStyles<typeof styles> {
  onThemeChange: (theme: string) => void;
}

class App extends React.Component<PropsApp, StateApp> {


  constructor(props: PropsApp) {
    super(props);

    let log_entries = new Array<{ timestamp: Date, message: string }>();

    log_entries.push({ timestamp: new Date(), message: "Application started" });

    let theme = localStorage.getItem("theme");
    this.state = {
      selected_tab_id: 0,
      tab_position: "bottom",
      job_library_page_visible: false,
      show_log: false,
      show_frame: false,
      theme_text: theme == null ? "default" : theme,
      editing: { filename: "", filepath: "" }
    }

    this.onPostMessage.bind(this)
    const event_listener = new PostEventListener(this.onPostMessage.bind(this))
  }
  onPostMessage(event: MessageEvent) {
    let pages = ["dashboard", "job_library", "job_editor", "maintenance", "settings", "quickrip"]
    if (pages.includes(event.data)) {
      let index = pages.indexOf(event.data)
      console.log("onPostMessage", event)
      this.setState({ ...this.state, selected_tab_id: index })
    }
  }
  componentDidMount() {
    document.title = "InkStorm Controller";
  }

  mmOrInchChanged(unit: TStateSwitchComponentInchChangeMm) {
    this.props.unitViewChanged(unit);
  }

  job_library_page: typeof ComponentJobLibraryPage | undefined;

  renderPages() {

    const classes = this.props.classes;

    let log_component =
      <ComponentLog log_entries={this.props.log_entries}></ComponentLog>;

    let frame_component = window.oem_config?.component_frame_url && window.oem_config.component_frame_url !== ""
      ? <ComponentIFrame frame_url={window.oem_config?.component_frame_url}></ComponentIFrame>
      : undefined

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <ComponentTabPanel index={this.state.selected_tab_id} value={0}>
          <ComponentDashboardPage onShowLogToggle={() => {
            this.setState({ show_log: !this.state.show_log });
          }}
            log_component={log_component}

            onShowFrameToggle={() => {
              this.setState({ show_frame: !this.state.show_frame });
            }}
            frame_component={frame_component}

            show_log={this.state.show_log}
            show_frame={this.state.show_frame}
            onMmInchChange={this.mmOrInchChanged.bind(this)}
            currentMmInchState={this.props.mm_or_inch}
          ></ComponentDashboardPage>
        </ComponentTabPanel>
        <ComponentTabPanel index={this.state.selected_tab_id} value={1}>
          <ComponentJobLibraryPage
            visible={this.state.job_library_page_visible}
            log_component={log_component}
            onPrintRequested={() => { this.setState({ selected_tab_id: 0 }) }}
            onEditRequested={(filename: string, filepath: string) => { this.setState({ selected_tab_id: 2, editing: { filename: filename, filepath: filepath } }) }}
            onMmInchChange={this.mmOrInchChanged.bind(this)}
            currentMmInchState={this.props.mm_or_inch}
          ></ComponentJobLibraryPage>
        </ComponentTabPanel>
        <ComponentTabPanel index={this.state.selected_tab_id} value={2}>
          <ComponentJobEditorPage
            onShowLogToggle={() => {
              this.setState({ show_log: !this.state.show_log });
            }}
            log_component={log_component}
            onShowFrameToggle={() => {
              this.setState({ show_frame: !this.state.show_frame });
            }}
            frame_component={frame_component}

            show_log={this.state.show_log}
            show_frame={this.state.show_frame}
            filename={this.state.editing.filename}
            filepath={this.state.editing.filepath}
            onCancel={() => { this.setState({ editing: { filename: "", filepath: "" } }) }}
            onPrintRequested={() => { this.setState({ selected_tab_id: 0 }) }}
            onEditRequestedFromHistory={(filename, filepath) => { this.setState({ editing: { filename: filename, filepath: filepath } }) }}
            onMmInchChange={this.mmOrInchChanged.bind(this)}
            currentMmInchState={this.props.mm_or_inch}></ComponentJobEditorPage>
        </ComponentTabPanel>
        <ComponentTabPanel index={this.state.selected_tab_id} value={3}>
          <ComponentMaintenancePage onShowLogToggle={() => {
            this.setState({ show_log: !this.state.show_log });
          }}
            log_component={log_component}
            show_log={this.state.show_log}
            this_page_visible={this.state.selected_tab_id === 3}></ComponentMaintenancePage>
        </ComponentTabPanel>
        <ComponentTabPanel index={this.state.selected_tab_id} value={4}>
          <ComponentSettingsPage onShowLogToggle={() => {
            this.setState({ show_log: !this.state.show_log });
          }}
            log_component={log_component}
            onShowFrameToggle={() => {
              this.setState({ show_frame: !this.state.show_frame });
            }}
            frame_component={frame_component}

            show_log={this.state.show_log}
            show_frame={this.state.show_frame}
            this_page_visible={this.state.selected_tab_id === 4}></ComponentSettingsPage>
        </ComponentTabPanel>
        <ComponentTabPanel index={this.state.selected_tab_id} value={5}>
          <ComponentQuickripPage onShowLogToggle={() => {
            this.setState({ show_log: !this.state.show_log });
          }}
            log_component={log_component}
            show_log={this.state.show_log}
            this_page_visible={this.state.selected_tab_id === 5}></ComponentQuickripPage>
        </ComponentTabPanel>
      </div>
    );
  }


  tabChange(event: React.ChangeEvent<{}>, new_value: number) {
    setTimeout(() => {
      if (new_value == 1) {
        this.setState(
          {
            selected_tab_id: new_value
          }, () => {
            setTimeout(() => {
              this.setState({ job_library_page_visible: true });
            }, 400);
          });
      } else {

        this.setState(
          {
            selected_tab_id: new_value,
            job_library_page_visible: false
          }
        );
      }

    }, 0);

  }


  render() {

    const { t, classes } = this.props;

    let pages = this.renderPages();

    let tabs =
      <Tabs onChange={this.tabChange.bind(this)} value={this.state.selected_tab_id} classes={{ indicator: classes.tabs_indicator, root: classes.tabs }}>
        <Tab onDoubleClick={(event) => window.location.reload()} classes={{ root: classes.tab, selected: classes.tab_selected }} label={t("dashboard")} icon={<DashboardIcon></DashboardIcon>} />
        <Tab classes={{ root: classes.tab, selected: classes.tab_selected }} label={t("job_library")} icon={<SvgIcon><path fill="currentColor" d="M9 3V18H12V3H9M12 5L16 18L19 17L15 4L12 5M5 5V18H8V5H5M3 19V21H21V19H3Z" /></SvgIcon>} />
        <Tab classes={{ root: classes.tab, selected: classes.tab_selected }} label={t("job_editor")} icon={<EditIcon></EditIcon>} />
        <Tab classes={{ root: classes.tab, selected: classes.tab_selected }} label={t("maintenance")} icon={<SvgIcon>
          <path fill="currentColor" d="M21.71 20.29L20.29 21.71A1 1 0 0 1 18.88 21.71L7 9.85A3.81 3.81 0 0 1 6 10A4 4 0 0 1 2.22 4.7L4.76 7.24L5.29 6.71L6.71 5.29L7.24 4.76L4.7 2.22A4 4 0 0 1 10 6A3.81 3.81 0 0 1 9.85 7L21.71 18.88A1 1 0 0 1 21.71 20.29M2.29 18.88A1 1 0 0 0 2.29 20.29L3.71 21.71A1 1 0 0 0 5.12 21.71L10.59 16.25L7.76 13.42M20 2L16 4V6L13.83 8.17L15.83 10.17L18 8H20L22 4Z" />
        </SvgIcon>} />
        <Tab classes={{ root: classes.tab, selected: classes.tab_selected }} label={t("settings")} icon={
          <SvgIcon>
            <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12A2,2 0 0,0 12,10M10,22C9.75,22 9.54,21.82 9.5,21.58L9.13,18.93C8.5,18.68 7.96,18.34 7.44,17.94L4.95,18.95C4.73,19.03 4.46,18.95 4.34,18.73L2.34,15.27C2.21,15.05 2.27,14.78 2.46,14.63L4.57,12.97L4.5,12L4.57,11L2.46,9.37C2.27,9.22 2.21,8.95 2.34,8.73L4.34,5.27C4.46,5.05 4.73,4.96 4.95,5.05L7.44,6.05C7.96,5.66 8.5,5.32 9.13,5.07L9.5,2.42C9.54,2.18 9.75,2 10,2H14C14.25,2 14.46,2.18 14.5,2.42L14.87,5.07C15.5,5.32 16.04,5.66 16.56,6.05L19.05,5.05C19.27,4.96 19.54,5.05 19.66,5.27L21.66,8.73C21.79,8.95 21.73,9.22 21.54,9.37L19.43,11L19.5,12L19.43,13L21.54,14.63C21.73,14.78 21.79,15.05 21.66,15.27L19.66,18.73C19.54,18.95 19.27,19.04 19.05,18.95L16.56,17.95C16.04,18.34 15.5,18.68 14.87,18.93L14.5,21.58C14.46,21.82 14.25,22 14,22H10M11.25,4L10.88,6.61C9.68,6.86 8.62,7.5 7.85,8.39L5.44,7.35L4.69,8.65L6.8,10.2C6.4,11.37 6.4,12.64 6.8,13.8L4.68,15.36L5.43,16.66L7.86,15.62C8.63,16.5 9.68,17.14 10.87,17.38L11.24,20H12.76L13.13,17.39C14.32,17.14 15.37,16.5 16.14,15.62L18.57,16.66L19.32,15.36L17.2,13.81C17.6,12.64 17.6,11.37 17.2,10.2L19.31,8.65L18.56,7.35L16.15,8.39C15.38,7.5 14.32,6.86 13.12,6.62L12.75,4H11.25Z" />
          </SvgIcon>
        } />
        <Tab classes={{ root: classes.tab, selected: classes.tab_selected }} style={{ textTransform: "none" }} label={"QuickRIP"} icon={<img alt='logo.svg' style={{ width: 24, height: 24 }} src="ripe_logo.svg" />} />
      </Tabs>;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {this.state.tab_position == "top" ? tabs : null}
        {pages}
        {this.state.tab_position == "bottom" ? tabs : null}
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true })(connector(App)));