import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { ResourceQueueItem } from "./ResourceQueueItem";
import ComponentResourceQueueItemJob from "./ComponentResourceQueueItemJob";
import { ResourceQueueItemJob } from './ResourceQueueItemJob';
import { connect, ConnectedProps } from 'react-redux';
import { TIdentifierReducerQueue, TTypeActionReducerQueue, TDataReducerQueue } from './ReducerQueue';

const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.background.default,
        height: "100%",
        width: "100%"
    }
});

/**
 * Type of the queue.
 * When the type is JOB, the ComponentResourceQueue renders components of type ComponentResourceQueueItemJob for each item in the resources array.
 */
export enum QueueType
{
    JOB
}

export interface IStateResourceQueue
{
    resources: Map<TIdentifierReducerQueue, TDataReducerQueue>;
}

/**
 * @param state - Redux state of the application
 * @returns - Returns the substate used by the ComponentResourceQueue. 
 * When the view actor gets message from the model, the redux state is updated and the component re-renders.
 */
const mapState = (state: any) =>
{
    return {
        resources: state.Queue.resources
    } as IStateResourceQueue
}

/**
 * Dispatch functions used in ComponentResourceQueue to create redux actions.
 * Actions are handled in the view actor, and for every redux action a message is sent to the controller actor.
 */
const mapDispatch = {
    moveUpResource: (queue_id_prm: TIdentifierReducerQueue, resource_id_prm: string) => ({ type: TTypeActionReducerQueue.MOVE_UP_RESOURCE, resource_id: resource_id_prm, queue_id: queue_id_prm }),
    moveDownResource: (queue_id_prm: TIdentifierReducerQueue, resource_id_prm: string) => ({ type: TTypeActionReducerQueue.MOVE_DOWN_RESOURCE, resource_id: resource_id_prm, queue_id: queue_id_prm }),
    pauseResource: (queue_id_prm: TIdentifierReducerQueue, resource_id_prm: string) => ({ type: TTypeActionReducerQueue.PAUSE_RESOURCE, resource_id: resource_id_prm, queue_id: queue_id_prm }),
    startResource: (queue_id_prm: TIdentifierReducerQueue, resource_id_prm: string) => ({ type: TTypeActionReducerQueue.START_RESOURCE, resource_id: resource_id_prm, queue_id: queue_id_prm }),
    cancelResource: (queue_id_prm : TIdentifierReducerQueue, resource_id_prm: string) => ({ type: TTypeActionReducerQueue.CANCEL_RESOURCE, resource_id: resource_id_prm, queue_id: queue_id_prm })
}

/**
 * Connect the ComponentResourceQueue component to the redux store
 */
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface PropsComponentResourceQueue extends PropsFromRedux, WithStyles<typeof styles>
{
    type: QueueType;
    id: TIdentifierReducerQueue
}

class ComponentResourceQueue extends React.Component<PropsComponentResourceQueue, {}> {

    render()
    {
        let classes = this.props.classes;
        if (this.props.resources !== undefined)
        {
            let own_resources = this.props.resources.get(this.props.id);
            if (own_resources !== undefined && own_resources.length !== 0)
            {
                let resources = own_resources.map((item) =>
                {
                    if (this.props.type == QueueType.JOB)
                    {
                        return (
                            <div style={{ padding: 7 }}>
                                <ComponentResourceQueueItemJob
                                    {...(item as ResourceQueueItemJob)}
                                    onMoveJobDown={() => { this.props.moveDownResource(this.props.id, item.resource_id) }}
                                    onMoveJobUp={() => { this.props.moveUpResource(this.props.id, item.resource_id) }}
                                    onPauseJob={() => { this.props.pauseResource(this.props.id, item.resource_id) }}
                                    onCancelJob={() => { this.props.cancelResource(this.props.id, item.resource_id) }}
                                    onStartJob={() => { this.props.startResource(this.props.id, item.resource_id) }}
                                >

                                </ComponentResourceQueueItemJob>
                            </div>)
                    }
                    return null;
                });
                return (
                    <div>{resources}</div>
                );
            }
            else
            {
                return null;
            }
        }
        else
        {
            return null;
        }


    }
}

export default connector(withStyles(styles, { withTheme: true })(ComponentResourceQueue));