import { IReduxStateJobEditorPage } from "./ComponentJobEditorPage";
import { MsgEngineStatus, MsgRestrictionView, MsgJobStatisticsInkStorm, MsgViewConfig } from "@ginf/inkstorm-msg";
import { TComponentResourceLibrarySideCopies } from "../core/ComponentResourceLibrarySide";
const initialState : IReduxStateJobEditorPage =
{
    engine_status: new MsgEngineStatus(),
    job: new MsgJobStatisticsInkStorm(),
    view_restrictions: new MsgRestrictionView(),
    view_config: new MsgViewConfig(),
    show_connection_lost_message:false

};



export enum TTypeActionReducerJobEditorPage
{
  /* accepted actions */
  REFRESH_ENGINE_STATE = "TTypeActionReducerJobEditorPage_REFRESH_ENGINE_STATE",
  LOAD_JOB = "TTypeActionReducerJobEditorPage_LOAD_JOB",
  REFRESH_VIEW_RESTRICTIONS = "TTypeActionReducerJobEditorPage_REFRESH_VIEW_RESTRICTIONS",
  REFRESH_VIEW_CONFIG = "TTypeActionReducerJobEditorPage_REFRESH_VIEW_CONFIG",

  /* emitted actions */
  GET_DETAILS = "TTypeActionReducerJobEditorPage_GET_DETAILS",
  CANCEL_EDITING = "TTypeActionReducerJobEditorPage_CANCEL_EDITING",
  PRINT_JOBS = "TTypeActionReducerJobEditorPage_PRINT_JOBS",
  RENAME_JOB = "TTypeActionReducerJobEditorPage_RENAME_JOB",
  CHANGE_COPYNUMBER_JOB = "TTypeActionReducerJobEditorPage_CHANGE_COPYNUMBER_JOB",
}



export type TActionReducerJobEditorPage = 
/*accepted actions*/
  { type: TTypeActionReducerJobEditorPage.REFRESH_ENGINE_STATE, data: MsgEngineStatus }
| { type: TTypeActionReducerJobEditorPage.LOAD_JOB, data: MsgJobStatisticsInkStorm }
| { type: TTypeActionReducerJobEditorPage.REFRESH_VIEW_RESTRICTIONS, data: MsgRestrictionView }
| { type: TTypeActionReducerJobEditorPage.REFRESH_VIEW_CONFIG, data: MsgViewConfig }

/*emitted actions*/
| { type: TTypeActionReducerJobEditorPage.GET_DETAILS, data: string }
| { type: TTypeActionReducerJobEditorPage.PRINT_JOBS, data: TComponentResourceLibrarySideCopies }
| { type: TTypeActionReducerJobEditorPage.RENAME_JOB, data: {job_id: string, new_name: string} }
| { type: TTypeActionReducerJobEditorPage.CHANGE_COPYNUMBER_JOB, data: {job_id: string, new_copy_number: number} }


export function ReducerJobEditorPage(
    state = initialState,
    action: TActionReducerJobEditorPage
  ): IReduxStateJobEditorPage
  {
    
    switch (action.type)
    {
        case TTypeActionReducerJobEditorPage.REFRESH_ENGINE_STATE:
          return {...state, engine_status: action.data};
        break;

        case TTypeActionReducerJobEditorPage.LOAD_JOB:
          console.log("job is: ", action.data)
          return {...state, job: action.data};
        break;

        case TTypeActionReducerJobEditorPage.REFRESH_VIEW_RESTRICTIONS:
          return {...state, view_restrictions: action.data};
        break;

        case TTypeActionReducerJobEditorPage.REFRESH_VIEW_CONFIG:
          return {...state, view_config: action.data};
        break;

      default:
        return state;
    }
  }