import { WithStyles, Theme, createStyles, withStyles, Typography, } from "@material-ui/core";
import React from "react";

export type TItemComponentColoumnDataShow = 
{ data_caption: string, data: string | number };

export interface IPropsComponentColoumnDataShow extends WithStyles<typeof styles>
{
    items: Array<TItemComponentColoumnDataShow>;
    title_text: string;
    title_icon: React.ReactNode
}




const styles = (theme: Theme) => createStyles({
   
});

class ComponentColoumnDataShow extends React.Component<IPropsComponentColoumnDataShow, {}>
{



    render()
    {
        let classes = this.props.classes;
        return (
           <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
               <div style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,0.3)", marginBottom: 5, padding: 5}}>
                   {this.props.title_icon}<Typography style={{marginLeft:5}}>{this.props.title_text}</Typography>
               </div>
               <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
                    {this.props.items.map(item =>
                        <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                            <div style={{flex:1}}><Typography style={{fontWeight: "bold", textAlign: "center"}}>{item.data}</Typography></div>
                            <Typography style={{backgroundColor: "rgba(0,0,0,0.1)", textAlign: "center", fontSize: "80%"}}>{item.data_caption}</Typography>
                        </div>)}
               </div>
           </div>
        )
    }


}

export default withStyles(styles, { withTheme: true })(ComponentColoumnDataShow);