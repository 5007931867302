import { WithStyles, Theme, createStyles, withStyles, Typography, LinearProgress, } from "@material-ui/core";
import React from "react";


export interface IPropsComponentLabeledProgressBar extends WithStyles<typeof styles>
{
    value: number,
    title_text: string;
    title_icon: React.ReactNode
}




const styles = (theme: Theme) => createStyles({
   
});

const CustomProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%"
        },
        colorPrimary: {
            backgroundColor: "rgba(0,0,0,0.4)"
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#1a90ff',
        },
    }),
)(LinearProgress);

class ComponentLabeledProgressBar extends React.Component<IPropsComponentLabeledProgressBar, {}>
{



    render()
    {
        let classes = this.props.classes;
        return (
           <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
               <div style={{flex:1}}>
                   <CustomProgress variant="determinate" value={this.props.value} />
               </div>
               <div style={{display: "flex", alignItems: "center", justifyContent: "center", padding: 5, backgroundColor: "rgba(0,0,0,0.3)"}}>
                    {this.props.title_icon}<Typography style={{marginLeft: 5}}>{this.props.title_text}</Typography>
               </div>
           </div>
        )
    }


}

export default withStyles(styles, { withTheme: true })(ComponentLabeledProgressBar);