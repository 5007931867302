import React, { SyntheticEvent } from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import PauseOutlinedIcon from '@material-ui/icons/PauseOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { TIdentifierReducerQueue, TDataReducerQueue } from './ReducerQueue';
import { TTypeActionReducerQueueControl } from './ReducerQueueControl'
import { connect, ConnectedProps } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

const styles = (theme: Theme) => createStyles({
  button: {
    height: 60,
    width: 140,
    color: theme.palette.text.primary
  },
  pause_button: {
    backgroundColor: "#fdd835",
    '&:hover': {
      backgroundColor: "#c6a700"
    }
  },
  start_button: {
    backgroundColor: "#43a047",
    '&:hover': {
      backgroundColor: "#00701a"
    }
  },
  clear_all_button: {
    backgroundColor: "#d32f2f",
    '&:hover': {
      backgroundColor: "#9a0007"
    }
  }
});

export interface IStateQueueControl
{
  paused: Map<TIdentifierReducerQueue, boolean>;
  resources: Map<TIdentifierReducerQueue, TDataReducerQueue>;
}


const mapState = (state: any) =>
{
  return {
    paused: state.QueueControl.paused,
    resources: state.Queue.resources
  } as IStateQueueControl;
}

const mapDispatch = {
  pauseQueue: (queue_id_prm: TIdentifierReducerQueue) => ({ type: TTypeActionReducerQueueControl.PAUSE_QUEUE, queue_id: queue_id_prm }),
  startQueue: (queue_id_prm: TIdentifierReducerQueue) => ({ type: TTypeActionReducerQueueControl.START_QUEUE, queue_id: queue_id_prm }),
  cancelQueue: (queue_id_prm: TIdentifierReducerQueue) => ({ type: TTypeActionReducerQueueControl.CANCEL_QUEUE, queue_id: queue_id_prm })
}

// Do the first half of the `connect()` call separately, 
// before declaring the component
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector> & WithTranslation;

export interface PropsQueueControl extends PropsFromRedux, WithStyles<typeof styles>
{
  id: TIdentifierReducerQueue
}

class ComponentQueueControl extends React.Component<PropsQueueControl, {}> {

  render()
  {
    const { t, classes } = this.props;


    let state_button = <Button
    className={classes.button}
    variant="contained"
    classes={{ root: classes.pause_button }}
    startIcon={<PauseOutlinedIcon />}
    onClick={() =>
    {
      this.props.pauseQueue(this.props.id);
    }}>
    {t("queue_control_pause")}
</Button>;



    if (this.props.paused !== undefined)
    {
      let paused = this.props.paused.get(this.props.id);
      if (paused !== undefined && paused === true)
      {
        state_button = <Button
        className={classes.button}
        classes={{ root: classes.start_button }}
        variant="contained"
        startIcon={<PlayArrowOutlinedIcon />}
        onClick={() =>
        {
          this.props.startQueue(this.props.id);
        }}>
        {t("queue_control_start")}
        </Button>;
      }
    }

    let own_resources = this.props.resources.get(this.props.id);
    let show_cancel = false;
    if (own_resources !== undefined && own_resources.length > 0) show_cancel = true;

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {state_button}
        {show_cancel ?
        <Button
          className={classes.button}
          style={{ marginTop: 10 }}
          variant="contained"
          classes={{ root: classes.clear_all_button }}
          startIcon={<HighlightOffOutlinedIcon />}
          onClick={() =>
          {
            this.props.cancelQueue(this.props.id);
          }}>
          {t("queue_control_clear")}
        </Button>
        : null }
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(connector(ComponentQueueControl)));