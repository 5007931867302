import React, { SyntheticEvent } from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { Card, Typography, IconButton, SvgIcon } from '@material-ui/core';
import { ResourceQueueItemJob } from "./ResourceQueueItemJob";
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { withTranslation, WithTranslation } from 'react-i18next';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    width: "100%",
    borderRadius: 0
  },
  image_src: {
    maxWidth: 50,
    maxHeight: 50,
    minWidth: 50,
    minHeight: 50,
    objectFit: "contain",
    marginRight: 10
  },
  large_image_src: {
    objectFit: "contain"
  },
  main_container: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  main_container_processing: {
    backgroundColor: theme.palette.action.selected,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  icon: {
    width: 56,
    height: 56
  },
  iconButton: {
    padding: 0
  }
});

/**
 * Layout of the item. Currently LARGE_IMAGE is not supported.
 */
enum Layout
{
  SMALL_IMAGE,
  LARGE_IMAGE
}

interface StateComponentResourceQueueItemJob
{
  layout: Layout;
}

/**
 * These functions are connected to the redux store in ComponentResourceQueue.
 */
interface PropsComponentResourceQueueItemJob extends WithStyles<typeof styles>, ResourceQueueItemJob
{
  onMoveJobUp: () => void,
  onMoveJobDown: () => void,
  onPauseJob: () => void,
  onStartJob: () => void,
  onCancelJob: () => void
}

class ComponentResourceQueueItem extends React.Component<PropsComponentResourceQueueItemJob & WithTranslation, StateComponentResourceQueueItemJob> {

  changeLayoutClick()
  {
    /*if(this.img_loaded) {
      if(this.state.layout == Layout.LARGE_IMAGE) {
        this.setState({layout: Layout.SMALL_IMAGE});
      } else {
        this.setState({layout: Layout.LARGE_IMAGE});
      }
    }*/
  }

  constructor(props: PropsComponentResourceQueueItemJob & WithTranslation)
  {
    super(props);
    this.state = {
      layout: Layout.SMALL_IMAGE
    }
  }

  img_width: number = -1;
  img_height: number = -1;
  img_loaded: boolean = false;

  render()
  {

    const { t, classes } = this.props;

    let job_properties =
      [
        (<div style={{ display: "flex", flex: 1, flexDirection: "column", marginRight: 10 }}>
          <Typography variant="subtitle1">{this.props.name}</Typography>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography variant="subtitle1">{this.props.status}</Typography>
            {this.props.copies !== undefined && this.props.copies_done !== undefined ? <Typography variant="subtitle1">{this.props.copies_done + "/" + this.props.copies + " " + t("job_queue_copies")}</Typography> : null }
            {this.props.pages_done !== undefined && this.props.total_pages !== undefined ? <Typography variant="subtitle1">{this.props.pages_done + "/" + this.props.total_pages + " " + t("job_queue_pages")}</Typography> : null }
          </div>
        </div>),
        (<div style={{ display: "flex", flexDirection: "row" }}>
          {this.props.paused ?
          <IconButton className={classes.iconButton} onClick={this.props.onStartJob}>
          <PlayCircleFilledWhiteOutlinedIcon className={classes.icon}></PlayCircleFilledWhiteOutlinedIcon>
        </IconButton>
         : 
          <IconButton className={classes.iconButton} onClick={this.props.onPauseJob}>
            <PauseCircleOutlineIcon className={classes.icon}></PauseCircleOutlineIcon>
          </IconButton>
          }
          <IconButton className={classes.iconButton} onClick={this.props.onCancelJob}>
            <HighlightOffIcon className={classes.icon}></HighlightOffIcon>
          </IconButton>
          {/*
          <IconButton className={classes.iconButton}>
            <SvgIcon className={classes.icon}>
            <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4M9,9V15H15V9" />
            </SvgIcon>
          </IconButton>*/
          }
          <IconButton className={classes.iconButton} onClick={this.props.onMoveJobUp}>
            <ExpandLessIcon className={classes.icon}></ExpandLessIcon>
          </IconButton>
          <IconButton className={classes.iconButton} onClick={this.props.onMoveJobDown}>
            <ExpandMoreIcon className={classes.icon}></ExpandMoreIcon>
          </IconButton>
        </div>)];

    let content;
    if (this.state.layout == Layout.SMALL_IMAGE)
    {
      content =
        <div className={
          this.props.processing ? classes.main_container_processing : classes.main_container}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {/*<IconButton style={{ marginRight: 10 }} className={classes.iconButton} onClick={this.changeLayoutClick.bind(this)}>
              <ArrowRightIcon className={classes.icon}></ArrowRightIcon>
        </IconButton>*/}
            <img className={classes.image_src} onLoad={
              (e: SyntheticEvent<HTMLImageElement>) =>
              {
                this.img_width = (e.target as HTMLImageElement).naturalWidth;
                this.img_height = (e.target as HTMLImageElement).naturalHeight;
                this.img_loaded = true;
              }
            } src={this.props.thumbnail_uri} />
          </div>
          {job_properties}
        </div>;
    } else if (this.img_width > this.img_height)
    {
      content =
        <div className={
          this.props.processing ? classes.main_container_processing : classes.main_container}
          style={{ flexDirection: "column", alignItems: "normal" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <IconButton onClick={this.changeLayoutClick.bind(this)}>
              <ArrowRightIcon></ArrowRightIcon>
            </IconButton>
            <div style={{ flexDirection: "column", display: "flex", flex: 1 }}>
              <img className={classes.large_image_src} style={{ maxHeight: 300, maxWidth: "100%" }} onLoad={
                (e: SyntheticEvent<HTMLImageElement>) =>
                {
                  this.img_width = (e.target as HTMLImageElement).naturalWidth;
                  this.img_height = (e.target as HTMLImageElement).naturalHeight;
                  this.img_loaded = true;
                }
              } src={this.props.thumbnail_uri} />
              <div style={{ display: "flex", alignItems: "center", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                {job_properties}
              </div>
            </div>
          </div>
        </div>;
    } else
    {
      content =
        <div className={
          this.props.processing ? classes.main_container_processing : classes.main_container}
          style={{ width: "100%" }}>
          <div style={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center" }}>
            <IconButton onClick={this.changeLayoutClick.bind(this)}>
              <ArrowRightIcon></ArrowRightIcon>
            </IconButton>
            <div style={{ flexDirection: "row", display: "flex", flex: 1 }}>
              <img className={classes.large_image_src} style={{ maxHeight: 300 }} onLoad={
                (e: SyntheticEvent<HTMLImageElement>) =>
                {
                  this.img_width = (e.target as HTMLImageElement).naturalWidth;
                  this.img_height = (e.target as HTMLImageElement).naturalHeight;
                  this.img_loaded = true;
                }
              } src={this.props.thumbnail_uri} />
              <div style={{ display: "flex", justifyItems: "center", alignItems: "center", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                {job_properties}
              </div>
            </div>
          </div>
        </div>;
    }

    return (
      <Card raised={true} className={classes.root}>
        {content}
      </Card>
    );
  }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(ComponentResourceQueueItem));