"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgAddQueueProcessResourceJob = exports.ViewOfProcessResourceJob = void 0;
const MsgProcessResource_1 = require("./MsgProcessResource");
class ViewOfProcessResourceJob extends MsgProcessResource_1.ViewOfProcessResource {
    constructor(init) {
        super(init);
        Object.assign(this, init);
    }
}
exports.ViewOfProcessResourceJob = ViewOfProcessResourceJob;
class MsgAddQueueProcessResourceJob extends MsgProcessResource_1.MsgAddQueueProcessResource {
    constructor(init) {
        super(init);
        Object.assign(this, init);
    }
}
exports.MsgAddQueueProcessResourceJob = MsgAddQueueProcessResourceJob;
