import React from 'react';
import { Theme, WithStyles, createStyles, withStyles, Grid, Paper, Button, SvgIcon, ButtonBase, Typography, CircularProgress, List, ListItem, Divider, TextField, IconButton } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { MsgEngineStatus, MsgJobStatisticsInkStorm, MsgRestrictionView, MsgViewConfig } from '@ginf/inkstorm-msg';
import { RootState } from '../RootReducer';
import { connect, ConnectedProps } from 'react-redux';
import PrintIcon from '@material-ui/icons/Print';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ComponentToolbarButton from "../core/ComponenToolbarButton";
import ComponentThumbnail from "../core/ComponentThumbnail";
import ComponentInkDrops, { TItemComponentInkDrops } from '../core/ComponentInkDrops';
import { TComponentResourceLibrarySideCopies } from "../core/ComponentResourceLibrarySide";
import ShortTextIcon from '@material-ui/icons/ShortText';
import { TTypeActionReducerJobEditorPage } from './ReducerJobEditorPage';
import { ComponentInchChangeMm, TStateSwitchComponentInchChangeMm } from '../ComponentInchChangeMm';
import { withTranslation, WithTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ConnectionLostBar } from '../ConnectionLostBar';

const styles = (theme: Theme) => createStyles({
    root: {
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        display: "flex",
        flexDirection: "column",
        "& ul li": {
            paddingLeft: 6,
            paddingRight: 6,
            paddingTop: 5,
            paddingBottom: 5
        }
    },
    navigation_bar: {
        minHeight: 40,
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
    },
    icons_container: {
        width: 60,
        marginRight: 12,
        display: "flex",
        justifyContent: "center"
    },
    icon: {
        padding: 0
    },
    toolbar: {
        minWidth: 72,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.palette.background.default
    },
    gridMain: {
        flex: 1,
        padding: 10
    },
    editorPaper: {
        marginLeft: 10,
        marginBottom: 10,
        backgroundColor: theme.palette.background.default
    },
    editorPaperSidebar: {
        backgroundColor: theme.palette.background.default,
        "& ul li": {
            textAlign: "center"
        }
    },
    printSettingsText: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        flex: 1,
        height: 34

    },
    printSettingsInput: {
        flex: 1,

    },
    inputField: {
        width: "100%"
    },
    jobeditorElementHeader: {
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.2)",
        padding: 5,
        boxSizing: "border-box",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    jobeditorElementHeaderText: {
        marginLeft: 5,
        fontSize: 14
    },
    sidebar: {
        minWidth: 180,
        width: 180,
        backgroundColor: theme.palette.background.default,
        display: "flex",
        flexDirection: "column"
    },
    sidebarButton:
    {
        flex: 1,
        fontSize: "1.2em",
        backgroundColor: "rgba(255,255,255,0.15)",
        margin: 5
    },
    lasteditedEntry: {
        display: "flex",
        maxHeight: 100,
        margin: 10,
        backgroundColor: theme.palette.background.default,
        padding: 10
    }
});

interface StateComponentJobEditorPage {
    show_log: boolean;
    ink_text: { up_cost: string, up_ink: string, down_cost: string, down_ink: string };
    copy_number_edit: number;
    job_name_edit: string;
    job_edit_history: Array<{ filename: string, filepath: string, thumbnailuri: string, edit_time: string }>;
}

export interface IReduxStateJobEditorPage {
    engine_status: MsgEngineStatus,
    job: MsgJobStatisticsInkStorm,
    view_restrictions: MsgRestrictionView,
    view_config: MsgViewConfig,
    show_connection_lost_message: boolean
}


const mapState = (state: RootState): IReduxStateJobEditorPage => (
    {
        engine_status: state.JobEditorPage.engine_status,
        job: state.JobEditorPage.job,
        view_restrictions: state.JobEditorPage.view_restrictions,
        view_config: state.JobEditorPage.view_config,
        show_connection_lost_message: state.SettingsPage.show_connection_lost_message
    });

const mapDispatch = {
    getDetails: (job_filepath_prm: string) => ({ type: TTypeActionReducerJobEditorPage.GET_DETAILS, data: job_filepath_prm }),
    cancelEditing: () => ({ type: TTypeActionReducerJobEditorPage.CANCEL_EDITING }),
    printJobs: (jobs_prm: TComponentResourceLibrarySideCopies) => ({ type: TTypeActionReducerJobEditorPage.PRINT_JOBS, data: jobs_prm }),
    renameJob: (job_filepath_prm: string, new_job_name_prm: string) => ({ type: TTypeActionReducerJobEditorPage.RENAME_JOB, data: { job_id: job_filepath_prm, new_name: new_job_name_prm } }),
    changeCopyNumber: (job_filepath_prm: string, new_copy_number_prm: number) => ({ type: TTypeActionReducerJobEditorPage.CHANGE_COPYNUMBER_JOB, data: { job_id: job_filepath_prm, new_copy_number: new_copy_number_prm } }),
}

// Do the first half of the `connect()` call separately, 
// before declaring the component
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector> & WithTranslation;

interface PropsComponentJobEditorPage extends PropsFromRedux, WithStyles<typeof styles> {
    show_log: boolean;
    show_frame: boolean;
    log_component: JSX.Element;
    frame_component?: JSX.Element;
    onShowLogToggle: () => void;
    onShowFrameToggle: () => void;
    onCancel: () => void;
    onPrintRequested: () => void;
    filename: string;
    filepath: string;
    onEditRequestedFromHistory: (filename: string, filepath: string) => void;
    onMmInchChange: (value: TStateSwitchComponentInchChangeMm) => void;
    currentMmInchState: TStateSwitchComponentInchChangeMm;
}

class ComponentJobEditorPage extends React.Component<PropsComponentJobEditorPage, StateComponentJobEditorPage> {

    readonly state: StateComponentJobEditorPage =
        {
            show_log: false,
            ink_text: { up_ink: i18n.t("job_editor_ink_usage_per_copy"), up_cost: i18n.t("job_editor_cost_per_copy"), down_ink: i18n.t("job_editor_ink_usage_for_job"), down_cost: i18n.t("job_editor_cost_for_job") },
            copy_number_edit: 1,
            job_name_edit: "",
            job_edit_history: new Array()
        };

    constructor(props: PropsComponentJobEditorPage) {
        super(props);

        i18n.on("languageChanged", () => {
            this.setState({ ink_text: { up_ink: i18n.t("job_editor_ink_usage_per_copy"), up_cost: i18n.t("job_editor_cost_per_copy"), down_ink: i18n.t("job_editor_ink_usage_for_job"), down_cost: i18n.t("job_editor_cost_for_job") } });
        });
    }


    componentDidMount() {
        if (this.props.filepath !== "") {
            this.props.getDetails(this.props.filepath);
        }
    }

    componentDidUpdate(prevProps: PropsComponentJobEditorPage) {
        if (prevProps.filepath !== this.props.filepath && this.props.filepath !== "") {
            this.props.getDetails(this.props.filepath);
            let history = this.state.job_edit_history;
            var today = new Date();

            history.unshift({ filename: this.props.filename, filepath: this.props.filepath, thumbnailuri: "", edit_time: today.toLocaleString("en-US") });
            history = history.slice(0, 8);
            this.setState({ job_edit_history: history });
        }

        if (prevProps.job.FilePath !== this.props.job.FilePath) {
            let history = this.state.job_edit_history;

            if (this.props.job.PreviewURI !== undefined) {
                history[0].thumbnailuri = this.props.job.PreviewURI || "";
            }
            if (this.props.job.CopyNumber !== undefined && this.state.copy_number_edit !== this.props.job.CopyNumber) {
                this.setState({ copy_number_edit: this.props.job.CopyNumber, job_edit_history: history });
            }

            if (this.props.job.FileName !== undefined && this.state.job_name_edit !== this.props.job.FileName) {
                this.setState({ job_name_edit: this.props.job.FileName, job_edit_history: history });
            }
        }


    }

    ink_text = { up_ink: "Ink usage per copy", up_cost: "Cost per copy", down_ink: "Ink usage for job", down_cost: "Cost for job" };


    renderJobStatistics() {
        const { classes } = this.props;
        if (this.props.job.job_editor_panel_up_right === undefined) {
            return <Paper elevation={0} className={classes.editorPaperSidebar}>

                <div className={classes.jobeditorElementHeader}>
                    <SvgIcon>
                        <path fill="currentColor" d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
                    </SvgIcon>
                    <Typography className={classes.jobeditorElementHeaderText}>
                        {i18n.t("job_editor_job_statistics")}
                    </Typography>
                </div>
                <List>
                    <ListItem>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <SvgIcon style={{ paddingRight: 5 }}>
                                <path fill="currentColor" d="M18,2V6H6V2H18M19,11A1,1 0 0,0 20,10A1,1 0 0,0 19,9A1,1 0 0,0 18,10A1,1 0 0,0 19,11M16,18V13H8V18H16M19,7A3,3 0 0,1 22,10V16H18V20H6V16H2V10A3,3 0 0,1 5,7H19M15,24V22H17V24H15M11,24V22H13V24H11M7,24V22H9V24H7Z" />
                            </SvgIcon>
                            <Typography><strong>{i18n.t("job_editor_number_of_prints")}</strong></Typography></div>
                    </ListItem>
                    <ListItem>
                        <Typography style={{ width: "100%" }}>{this.props.job.Statistics?.number_of_prints}</Typography>

                    </ListItem>
                    <Divider />
                    <ListItem>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <SvgIcon style={{ paddingRight: 5 }}>
                                <path fill="currentColor" d="M11,17A1,1 0 0,0 12,18A1,1 0 0,0 13,17A1,1 0 0,0 12,16A1,1 0 0,0 11,17M11,3V7H13V5.08C16.39,5.57 19,8.47 19,12A7,7 0 0,1 12,19A7,7 0 0,1 5,12C5,10.32 5.59,8.78 6.58,7.58L12,13L13.41,11.59L6.61,4.79V4.81C4.42,6.45 3,9.05 3,12A9,9 0 0,0 12,21A9,9 0 0,0 21,12A9,9 0 0,0 12,3M18,12A1,1 0 0,0 17,11A1,1 0 0,0 16,12A1,1 0 0,0 17,13A1,1 0 0,0 18,12M6,12A1,1 0 0,0 7,13A1,1 0 0,0 8,12A1,1 0 0,0 7,11A1,1 0 0,0 6,12Z" />
                            </SvgIcon>
                            <Typography><strong>{i18n.t("job_editor_average_print_time_per_copy")}</strong></Typography></div>
                    </ListItem>
                    <ListItem>
                        <Typography style={{ width: "100%" }}>{this.props.job.Statistics?.average_print_time_per_copy} {i18n.t("job_editor_seconds")}</Typography>

                    </ListItem>
                    <Divider />
                </List>
            </Paper>
        }
        else {
            return <Paper elevation={0} className={classes.editorPaperSidebar}>

                <div className={classes.jobeditorElementHeader}>
                    {this.props.job.job_editor_panel_up_right.icon !== undefined ? <img src={this.props.job.job_editor_panel_up_right.icon} /> : <></>}
                    <Typography className={classes.jobeditorElementHeaderText}>
                        {this.props.job.job_editor_panel_up_right.title || i18n.t("job_editor_job_statistics")}
                    </Typography>
                </div>
                <List>
                    {
                        this.props.job.job_editor_panel_up_right.ColumnData.map((item, index) => {
                            return <> <ListItem>
                                <div style={{ width: "100%", textAlign: "center" }}>
                                    {item.icon !== undefined ? <img src={item.icon} /> : <></>}
                                    <Typography><strong>{item.text}</strong></Typography>
                                </div>
                            </ListItem>
                                <ListItem>
                                    <Typography style={{ width: "100%" }}>{item.value}</Typography>

                                </ListItem>
                                <Divider />
                            </>
                        })
                    }

                </List>
            </Paper>
        }

    }
    renderEditor() {

        let classes = this.props.classes;


        let ink_items: Array<TItemComponentInkDrops> = [];

        if (this.props.job.Statistics != undefined) {
            ink_items.push({
                color: "cyan",
                color_name: this.props.job.Statistics!.ink_usage_per_copy_ml[0].color,
                color_name_color: "gray",
                up_drops: this.props.job.Statistics!.ink_usage_per_copy_ml[0].quantity,
                down_drops: this.props.job.Statistics!.ink_usage_for_job_ml[0].quantity,
                up_drops_cost: "$" + this.props.job.Statistics!.ink_usage_per_copy_price[0].quantity.toLocaleString("en"),
                down_drops_cost: "$" + this.props.job.Statistics!.ink_usage_for_job_price[0].quantity.toLocaleString("en")
            });

            ink_items.push({
                color: "magenta",
                color_name: this.props.job.Statistics!.ink_usage_per_copy_ml[1].color,
                color_name_color: "white",
                up_drops: this.props.job.Statistics!.ink_usage_per_copy_ml[1].quantity,
                down_drops: this.props.job.Statistics!.ink_usage_for_job_ml[1].quantity,
                up_drops_cost: "$" + this.props.job.Statistics!.ink_usage_per_copy_price[1].quantity.toLocaleString("en"),
                down_drops_cost: "$" + this.props.job.Statistics!.ink_usage_for_job_price[1].quantity.toLocaleString("en")
            });

            ink_items.push({
                color: "yellow",
                color_name: this.props.job.Statistics!.ink_usage_per_copy_ml[2].color,
                color_name_color: "gray",
                up_drops: this.props.job.Statistics!.ink_usage_per_copy_ml[2].quantity,
                down_drops: this.props.job.Statistics!.ink_usage_for_job_ml[2].quantity,
                up_drops_cost: "$" + this.props.job.Statistics!.ink_usage_per_copy_price[2].quantity.toLocaleString("en"),
                down_drops_cost: "$" + this.props.job.Statistics!.ink_usage_for_job_price[2].quantity.toLocaleString("en")
            });

            ink_items.push({
                color: "black",
                color_name: this.props.job.Statistics!.ink_usage_per_copy_ml[3].color,
                color_name_color: "white",
                up_drops: this.props.job.Statistics!.ink_usage_per_copy_ml[3].quantity,
                down_drops: this.props.job.Statistics!.ink_usage_for_job_ml[3].quantity,
                up_drops_cost: "$" + this.props.job.Statistics!.ink_usage_per_copy_price[3].quantity.toLocaleString("en"),
                down_drops_cost: "$" + this.props.job.Statistics!.ink_usage_for_job_price[3].quantity.toLocaleString("en"),
            });

            ink_items.push({
                color: "gray",
                color_name: this.props.job.Statistics!.ink_usage_per_copy_ml[4].color,
                color_name_color: "gray",
                up_drops: this.props.job.Statistics!.ink_usage_per_copy_ml[4].quantity,
                down_drops: this.props.job.Statistics!.ink_usage_for_job_ml[4].quantity,
                up_drops_cost: "$" + this.props.job.Statistics!.ink_usage_per_copy_price[4].quantity.toLocaleString("en"),
                down_drops_cost: "$" + this.props.job.Statistics!.ink_usage_for_job_price[4].quantity.toLocaleString("en")
            });
        }




        return (
            <>
                <div style={{ flex: 1, display: "flex" }}>
                    <Grid container spacing={0} className={classes.gridMain}>
                        <Grid item xs={6} style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
                            {
                                this.props.job.PreviewURI !== undefined ?

                                    <ComponentThumbnail
                                        key={this.props.job.PreviewURI}
                                        doResizeAndBlurEffect={true}
                                        image_url={this.props.job.PreviewURI}
                                        fillHeight={true}
                                    ></ComponentThumbnail>


                                    :
                                    <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}><CircularProgress /></div>

                            }
                        </Grid>
                        <Grid item xs={6}>
                            <Paper elevation={3} className={classes.editorPaper}>
                                <List>
                                    <ListItem>
                                        <div className={classes.printSettingsText}>
                                            <SvgIcon style={{ paddingRight: 5 }}>
                                                <path fill="currentColor" d="M10 20H6V4H13V9H18V12.1L20 10.1V8L14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H10V20M20.2 13C20.3 13 20.5 13.1 20.6 13.2L21.9 14.5C22.1 14.7 22.1 15.1 21.9 15.3L20.9 16.3L18.8 14.2L19.8 13.2C19.9 13.1 20 13 20.2 13M20.2 16.9L14.1 23H12V20.9L18.1 14.8L20.2 16.9Z" />
                                            </SvgIcon>
                                            <Typography>{i18n.t("job_editor_job_name")}</Typography></div>
                                        <div className={classes.printSettingsInput}><TextField className={classes.inputField} id="outlined-basic" variant="outlined" onChange={(e) => { this.setState({ job_name_edit: e.target.value }) }} value={this.state.job_name_edit} /></div>

                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <div className={classes.printSettingsText}>
                                            <SvgIcon style={{ paddingRight: 5 }}>
                                                <path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
                                            </SvgIcon>
                                            <Typography>{i18n.t("job_editor_copy_number")}</Typography></div>
                                        <div className={classes.printSettingsInput}><TextField type="number" className={classes.inputField} id="outlined-basic" variant="outlined" onChange={(e) => { this.setState({ copy_number_edit: Number(e.target.value) }) }} value={this.state.copy_number_edit} /></div>

                                    </ListItem>

                                </List>
                            </Paper>


                            {this.props.view_restrictions.ShowStatistics === true ?
                                <Paper elevation={3} className={classes.editorPaper} style={{ height: 160 }}>
                                    <ComponentInkDrops
                                        items={ink_items}
                                        up_ink_text={this.state.ink_text.up_ink}
                                        down_ink_text={this.state.ink_text.down_ink}
                                        up_cost_text={this.state.ink_text.up_cost}
                                        down_cost_text={this.state.ink_text.down_cost}
                                    />
                                </Paper> : <></>}
                        </Grid>
                    </Grid>
                    <div className={classes.sidebar}>
                        {this.props.view_restrictions.ShowStatistics === true ?

                            this.renderJobStatistics() : <></>}
                        <Paper elevation={0} className={classes.editorPaperSidebar}>
                            <div className={classes.jobeditorElementHeader}>
                                <SvgIcon>
                                    <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M6,7H18V9H6V7M6,11H18V13H6V11M6,15H18V17H6V15Z" />
                                </SvgIcon>
                                <Typography className={classes.jobeditorElementHeaderText}>
                                    {i18n.t("job_editor_job_control")}
                                </Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Button variant="outlined" className={classes.sidebarButton} onClick={() => {
                                    if (this.props.job.FileName !== this.state.job_name_edit) {
                                        this.props.renameJob(this.props.filepath, this.state.job_name_edit);
                                    }

                                    if (this.props.job.CopyNumber !== this.state.copy_number_edit) {
                                        this.props.changeCopyNumber(this.props.filepath, this.state.copy_number_edit);
                                    }

                                }} startIcon={
                                    <SvgIcon style={{ fontSize: "1.2em" }}>
                                        <path fill="currentColor" d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
                                    </SvgIcon>
                                }>{i18n.t("job_editor_job_control_save")}</Button>
                                <Button variant="outlined" className={classes.sidebarButton} onClick={() => {
                                    if (this.props.job.FilePath !== undefined) {
                                        this.props.printJobs([{ id: this.props.job.FilePath, copy: this.state.copy_number_edit, name: this.props.filename }]);
                                        this.props.onPrintRequested();
                                    }
                                }
                                } startIcon={
                                    <SvgIcon style={{ fontSize: "1.2em" }}>
                                        <path fill="currentColor" d="M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z" />
                                    </SvgIcon>
                                }>{i18n.t("job_editor_job_control_print")}</Button>
                                <Button variant="outlined" className={classes.sidebarButton} onClick={() => { this.props.onCancel(); this.props.cancelEditing(); }} startIcon={
                                    <SvgIcon style={{ fontSize: "1.2em" }}>
                                        <path fill="currentColor" d="M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z" />
                                    </SvgIcon>
                                }>{i18n.t("job_editor_job_control_cancel")}</Button>

                            </div>
                        </Paper>

                        <div style={{ flexGrow: 1 }}></div>
                        {window.oem_config?.mm_inch_job_library ?

                            <ComponentInchChangeMm mmOrInch={this.props.currentMmInchState} valueChanged={this.props.onMmInchChange} /> : <></>}



                    </div>
                </div>

            </>
        );
    }

    renderPlaceholder() {
        return (
            <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}><Typography style={{ fontSize: "3vw", color: "gray" }}>{i18n.t("job_editor_spaceholder_text")}</Typography></div>
        );
    }

    renderHistory() {
        let classes = this.props.classes;

        return (
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                <div style={{ flex: 1 }}>
                    <Grid container spacing={0}>
                        {this.state.job_edit_history.map(job =>
                            <Grid item xs={6}>
                                <Paper elevation={3} className={classes.lasteditedEntry}>
                                    <ButtonBase style={{ flex: 1 }} onClick={() => { this.props.onEditRequestedFromHistory(job.filename, job.filepath) }}>
                                        <div style={{ width: 90, height: 90 }}>
                                            <ComponentThumbnail
                                                doResizeAndBlurEffect={true}
                                                image_url={job.thumbnailuri}
                                                fillHeight={true}
                                            ></ComponentThumbnail>
                                        </div>

                                        <div style={{ flex: 1 }}>
                                            <List>
                                                <ListItem><Typography><strong>{i18n.t("job_editor_job_name")}: </strong>{job.filename}</Typography></ListItem>
                                                <Divider />
                                                <ListItem><Typography><strong>{i18n.t("job_editor_edit_time")}: </strong>{job.edit_time}</Typography></ListItem>
                                            </List>

                                        </div>
                                    </ButtonBase>

                                </Paper></Grid>)
                        }
                    </Grid>
                </div>
            </div>
        );
    }

    render() {

        const { t, classes } = this.props;

        let engine_state = this.props.view_config.EngineStates[this.props.engine_status.EngineState!];

        return (
            <div className={classes.root}>

                <div className={classes.navigation_bar}>
                    <div className={classes.icons_container}>
                        <IconButton className={classes.icon}>
                            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                        </IconButton>
                        <IconButton className={classes.icon}>
                            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                        </IconButton>
                    </div>
                    {this.props.show_connection_lost_message ?
                        ConnectionLostBar(!this.props.show_connection_lost_message, "0.3vw") : <></>
                    }
                    <div style={{ flex: 1 }}>{this.props.filename != "" ? <Typography><strong>Editing: </strong>{this.props.filename}</Typography> : null}</div>

                    <PrintIcon />
                    <Typography style={{ marginRight: 10, marginLeft: 10 }}>{engine_state}</Typography>

                </div>


                <div style={{ flex: 1, display: "flex" }}>
                    <div className={classes.toolbar}>
                        <div style={{ flex: 1 }}>
                        </div>
                        {this.props.frame_component !== undefined ? <ToggleButton value={this.props.show_frame} style={{ padding: 0, borderRadius: 0 }} selected={this.props.show_frame} onChange={() => { this.props.onShowFrameToggle() }}>
                            <ComponentToolbarButton text="Frame">
                                <ShortTextIcon></ShortTextIcon>
                            </ComponentToolbarButton>
                        </ToggleButton> : null}
                        <ToggleButton value={this.props.show_log} style={{ padding: 0, borderRadius: 0 }} selected={this.props.show_log} onChange={() => { this.props.onShowLogToggle() }}>
                            <ComponentToolbarButton text="LOG">
                                <ShortTextIcon></ShortTextIcon>
                            </ComponentToolbarButton>
                        </ToggleButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        {this.props.filepath !== "" ? this.renderEditor() : (this.state.job_edit_history.length > 0 ? this.renderHistory() : this.renderPlaceholder())}
                        <div style={{ height: this.props.show_frame ? 150 : 0, bottom: 65, zIndex: 10 }}>
                            {this.props.frame_component}
                        </div>
                        <div style={{ height: this.props.show_log ? 90 : 0, /* position:"absolute", */ bottom: 65, zIndex: 10, width: "50%" }}>
                            {this.props.log_component}
                        </div>
                    </div>
                    <div>

                    </div>

                </div>


            </div>
        )
    }
}
export default withTranslation()(withStyles(styles, { withTheme: true })(connector(ComponentJobEditorPage)));