import React from 'react';
import { Theme, WithStyles, createStyles, withStyles, Grid, Paper, Button, SvgIcon, ButtonBase, Typography, CircularProgress, List, ListItem, Divider, TextField, IconButton } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { MsgEngineStatus, MsgViewConfig } from '@ginf/inkstorm-msg';
import { RootState } from '../RootReducer';
import { connect, ConnectedProps } from 'react-redux';
import PrintIcon from '@material-ui/icons/Print';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ComponentToolbarButton from "../core/ComponenToolbarButton";
import ShortTextIcon from '@material-ui/icons/ShortText';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ToggleButtonGroup } from '@material-ui/lab';
import { IConfigOnePage } from '../App';
import { ConnectionLostBar } from '../ConnectionLostBar';

const styles = (theme: Theme) => createStyles({
    root: {
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        display: "flex",
        flexDirection: "column",
        "& ul li": {
            paddingLeft: 6,
            paddingRight: 6,
            paddingTop: 5,
            paddingBottom: 5
        }
    },
    navigation_bar: {
        minHeight: 40,
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
    },
    icons_container: {
        width: 60,
        marginRight: 12,
        display: "flex",
        justifyContent: "center"
    },
    icon: {
        padding: 0
    },
    toolbar: {
        minWidth: 72,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.palette.background.default
    },
    left_button: {
        padding: 0,
        borderRadius: 0
    }
});

interface StateComponentQuickripPage {
    show_log: boolean;
    current_page: number;
}

export interface IReduxStateQuickripPage {
    engine_status: MsgEngineStatus,
    view_config: MsgViewConfig,
    show_connection_lost_message:boolean
}


const mapState = (state: RootState): IReduxStateQuickripPage => (
    {
        engine_status: state.MaintenancePage.engine_status,
        view_config: state.MaintenancePage.view_config,
        show_connection_lost_message:state.SettingsPage.show_connection_lost_message
    });

const mapDispatch = {

}

// Do the first half of the `connect()` call separately, 
// before declaring the component
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector> & WithTranslation;

interface PropsComponentQuickripPage extends PropsFromRedux, WithStyles<typeof styles> {
    show_log: boolean;
    log_component: JSX.Element;
    onShowLogToggle: () => void;
    this_page_visible: boolean;
}

class ComponentQuickripPage extends React.Component<PropsComponentQuickripPage, StateComponentQuickripPage> {

    readonly state: StateComponentQuickripPage =
        {
            show_log: false,
            current_page: 0
        };

    private iframe_refs_: Map<number, React.RefObject<HTMLIFrameElement>>;
    private pages_to_display_: Array<IConfigOnePage>

    constructor(props: PropsComponentQuickripPage) {
        super(props);
        this.iframe_refs_ = new Map();

        this.pages_to_display_ = new Array<IConfigOnePage>();

        if (window.inkstorm_config?.quickrip_pages !== undefined) {
            for (let page of window.inkstorm_config?.quickrip_pages) {
                this.pages_to_display_.push(page);
            }
        }


        for (let page of this.pages_to_display_) {
            this.iframe_refs_.set(page.id, React.createRef());
        }
    }

    componentDidUpdate(prevprops: PropsComponentQuickripPage) {
        if (!prevprops.this_page_visible && this.props.this_page_visible && this.pages_to_display_.length === 1) {
            this.iframe_refs_.get(this.state.current_page)?.current?.contentWindow?.postMessage("scrollToLastTop", "*");
        }
    }



    render() {

        const { t, classes } = this.props;

        let engine_state = this.props.view_config.EngineStates[this.props.engine_status.EngineState!];



        return (
            <div className={classes.root}>

                <div className={classes.navigation_bar}>
                    <div className={classes.icons_container}>
                        <IconButton className={classes.icon}>
                            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                        </IconButton>
                        <IconButton className={classes.icon}>
                            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                        </IconButton>
                    </div>

                    {this.props.show_connection_lost_message ?
                        ConnectionLostBar(!this.props.show_connection_lost_message, "0.2vw") : <></>}
                    <div style={{ flex: 1 }}></div>

                    <PrintIcon />
                    <Typography style={{ marginRight: 10, marginLeft: 10 }}>{engine_state}</Typography>

                </div>

                {this.pages_to_display_.length > 1 ?
                    <div style={{ flex: 1, display: "flex" }}>
                        <div className={classes.toolbar}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                <ToggleButtonGroup
                                    value={this.state.current_page}
                                    orientation="vertical"
                                    exclusive
                                    onChange={(event, nextview) => {
                                        this.setState({ current_page: nextview !== null ? nextview : this.state.current_page }, () => {
                                            this.iframe_refs_.get(Number(nextview))?.current?.contentWindow?.postMessage("scrollToLastTop", "*");
                                        });
                                    }}
                                >
                                    {this.pages_to_display_.map(page =>
                                        <ToggleButton value={page.id} className={classes.left_button}>
                                            <ComponentToolbarButton text={t(page.page_translation_text)} style={{ height: 80 }}>
                                                <SvgIcon>
                                                    <path fill="currentColor" d={page.page_icon} />
                                                </SvgIcon>
                                            </ComponentToolbarButton>
                                        </ToggleButton>)}
                                </ToggleButtonGroup>
                            </div>
                            <ToggleButton value={this.props.show_log} style={{ padding: 0, borderRadius: 0 }} selected={this.props.show_log} onChange={() => { this.props.onShowLogToggle() }}>
                                <ComponentToolbarButton text="LOG">
                                    <ShortTextIcon></ShortTextIcon>
                                </ComponentToolbarButton>
                            </ToggleButton>

                        </div>
                        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                            {this.pages_to_display_.map(page =>
                                <iframe ref={this.iframe_refs_.get(page.id)} src={page.page_url} style={{ width: "100%", height: "100%", display: this.state.current_page === page.id ? "block" : "none" }} frameBorder={0}></iframe>)}
                            <div style={{ height: this.props.show_log ? 90 : 0, position: "absolute", bottom: 65, zIndex: 10, width: "50%" }}>
                                {this.props.log_component}
                            </div>
                        </div>

                    </div>
                    : <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        <iframe ref={this.iframe_refs_.get(0)} src={this.pages_to_display_[0].page_url} style={{ width: "100%", height: "100%" }} frameBorder={0}></iframe>
                        <div style={{ height: this.props.show_log ? 90 : 0, position: "absolute", bottom: 65, zIndex: 10, width: "50%" }}>
                            {this.props.log_component}
                        </div>
                    </div>}


            </div>
        )
    }
}
export default withTranslation()(withStyles(styles, { withTheme: true })(connector(ComponentQuickripPage)));