import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, Grid, Divider } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: 10
    },
    grid_item: {
        display: "flex",
        flexDirection: "row"
    },
    name: {

    },
    value: {
        textAlign: "right",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
    }
});

interface PropsComponentFileInfo extends WithStyles<typeof styles>
{
    file_info: Array<{ name: string, value: string }>;
    text: string;
}

class ComponentFileInfo extends React.Component<PropsComponentFileInfo, {}> {

    render()
    {

        let classes = this.props.classes;
        if (this.props.file_info !== undefined)
        {

            let file_infos = this.props.file_info.map((item, index) =>
            {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography variant="subtitle2" className={classes.name}>
                            {item.name + ":"}
                        </Typography>
                        <Typography variant="subtitle2" className={classes.value}>
                            {item.value}
                        </Typography>
                    </div>
                )
            });




            return (
                <div className={classes.root}>
                    <Typography variant="subtitle1">{this.props.text}</Typography>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "column", marginTop: 5 }}>
                        {file_infos}
                    </div>
                </div>
            )
        } else return null;
    }
}

export default withStyles(styles, { withTheme: true })(ComponentFileInfo);