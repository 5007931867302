import { IStateResourceQueue, QueueType } from './ComponentResourceQueue'
import { ResourceQueueItem } from './ResourceQueueItem';


const initialState : IStateResourceQueue =
{
    resources: new Map()
};

export type TIdentifierReducerQueue = string;

export enum TTypeActionReducerQueue
{
  /* accepted actions */
  REFRESH_ITEMS = "TTypeActionReducerQueue_REFRESH_ITEMS",

  /* emitted actions */
  MOVE_UP_RESOURCE = "TTypeActionReducerQueue_MOVE_UP_RESOURCE",
  MOVE_DOWN_RESOURCE = "TTypeActionReducerQueue_MODE_DOWN_RESOURCE",
  PAUSE_RESOURCE = "TTypeActionReducerQueue_PAUSE_RESOURCE",
  START_RESOURCE = "TTypeActionReducerQueue_START_RESOURCE",
  CANCEL_RESOURCE = "TTypeActionReducerQueue_CANCEL_RESOURCE"
}


export type TDataReducerQueue = Array<ResourceQueueItem>;

export type TActionReducerQueue = 
/*accepted actions*/
  { queue_id: TIdentifierReducerQueue, type: TTypeActionReducerQueue.REFRESH_ITEMS, data: TDataReducerQueue }

/* emitted actions */
 | { queue_id: TIdentifierReducerQueue, type: TTypeActionReducerQueue.MOVE_UP_RESOURCE, resource_id: string }
 | { queue_id: TIdentifierReducerQueue, type: TTypeActionReducerQueue.MOVE_DOWN_RESOURCE, resource_id: string }
 | { queue_id: TIdentifierReducerQueue, type: TTypeActionReducerQueue.PAUSE_RESOURCE, resource_id: string }
 | { queue_id: TIdentifierReducerQueue, type: TTypeActionReducerQueue.START_RESOURCE, resource_id: string }
 | { queue_id: TIdentifierReducerQueue, type: TTypeActionReducerQueue.CANCEL_RESOURCE, resource_id: string }


export function ReducerQueue(
    state = initialState,
    action: TActionReducerQueue
  ): IStateResourceQueue
  {
    
    switch (action.type)
    {
        case TTypeActionReducerQueue.REFRESH_ITEMS:
          let m : Map<TIdentifierReducerQueue, TDataReducerQueue> = new Map();
          state.resources.forEach((val,key) => {
            m.set(key,val);
          });
          m.set(action.queue_id,action.data);
          return {...state, resources: m};
        break;

      default:
        return state;
    }
  }