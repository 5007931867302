"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewOfItemJobLibraryInkStorm = void 0;
const MsgJobLibrary_1 = require("./MsgJobLibrary");
class ViewOfItemJobLibraryInkStorm extends MsgJobLibrary_1.ViewOfItemJobLibrary {
    constructor(init) {
        super(init);
        Object.assign(this, init);
    }
}
exports.ViewOfItemJobLibraryInkStorm = ViewOfItemJobLibraryInkStorm;
