import { Grid, Switch, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { unit } from 'mathjs';


export enum TStateSwitchComponentInchChangeMm
{
    MM = "mm",
    INCH = "inch"
}

export interface PropsComponentInchChangeMm
{
    mmOrInch: TStateSwitchComponentInchChangeMm
    valueChanged: (value: TStateSwitchComponentInchChangeMm) => void
}

const CustomSwitch = withStyles({
    switchBase: {
        color: "#005aa4",
        '&$checked': {
            color: "#005aa4",

        },
        '&$checked + $track': {
            backgroundColor: "#cee9ff",

        },
    },
    checked: {
    },
    track: {
        backgroundColor: "#cee9ff",
    },
})(Switch);



export function CovertInchToMmString(inch: string) { return String(unit(Number(inch),"inch").toNumber("mm")) };
export function ConvertInchToMmNumber(inch: number) { return unit(inch,"inch").toNumber("mm") };
export function ConvertMmToInchString(mm: string) { return String(unit(Number(mm),"mm").toNumber("inch")) };
export function ConvertMmToInchNumber(mm: number) { return unit(mm,"mm").toNumber("inch") };

export function ConvertMmToGivenTypeNumber(mm: number, given_type: TStateSwitchComponentInchChangeMm) { return given_type === TStateSwitchComponentInchChangeMm.MM ? mm : ConvertMmToInchNumber(mm)  };
export function ConvertInchToGivenTypeNumber(inch: number, given_type: TStateSwitchComponentInchChangeMm) { return given_type === TStateSwitchComponentInchChangeMm.INCH ? inch : ConvertInchToMmNumber(inch)  };

export class ComponentInchChangeMm extends React.Component<PropsComponentInchChangeMm, {}>
{
    render()
    {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {this.props.mmOrInch != TStateSwitchComponentInchChangeMm.MM ?
                    <Typography>mm</Typography>
                    :
                    <Typography><strong>mm</strong></Typography>}
                <CustomSwitch color="primary" checked={this.props.mmOrInch == TStateSwitchComponentInchChangeMm.INCH} onChange={(e) =>
                {
                    if (e.target.checked == true)
                    {
                        console.log("switching to inch")
                        this.props.valueChanged(TStateSwitchComponentInchChangeMm.INCH)
                    }
                    else
                    {
                        console.log("switching to mm")
                        this.props.valueChanged(TStateSwitchComponentInchChangeMm.MM)
                    }
                }} />
                {this.props.mmOrInch != TStateSwitchComponentInchChangeMm.INCH ?
                    <Typography>inch</Typography>
                    :
                    <Typography><strong>inch</strong></Typography>}
            </div>
        );
    }
}