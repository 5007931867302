import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createMuiTheme, Theme } from '@material-ui/core/styles';
import { ActorSocketIOViewMain } from './ActorSocketIOViewMain';
import { uuid } from 'uuidv4';
import { Provider } from 'react-redux';
import './i18n';



const default_theme = createMuiTheme({
  palette: {
    type: "light",
    background: {
      default: "#f5f5f5",
    },
    primary: {
      main: "#00000"
    },
    text: {
      //primary: ""
    }
  }
});

const dark_theme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "#2C3E50",
    },
    primary: {
      main: "#fffff"
    },
    text: {
      //primary: ""
    }
  }

});

let configured_theme = localStorage.getItem("theme");

let theme: Theme | undefined;

if (configured_theme != null && configured_theme == "dark_theme")
{
  theme = dark_theme;
} else
{
  theme = default_theme;
}


console.log("hostname: ", window.location.hostname);
console.log("registry: ", localStorage.getItem("backend_url"))
let client_ = new ActorSocketIOViewMain("main_view_" + uuid(), 
  localStorage.getItem("backend_url") || window.inkstorm_config?.actor_socketio_registry_url || "http://localhost:9090");



ReactDOM.render(

  <Provider store={client_.AppStore}>
    <ThemeProvider theme={theme}>
      <App onThemeChange={
        (theme) =>
        {
          if (typeof (Storage) !== "undefined")
          {
            localStorage.setItem("theme", theme);
            window.location.reload();
          } else
          {
            alert("No web storage support");
          }
        }
      } />
     
    </ThemeProvider>
  </Provider>
  ,
  document.getElementById('root')
);

export { client_ as ViewActor};

