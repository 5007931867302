"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgUnitChange = exports.TViewUnit = void 0;
var TViewUnit;
(function (TViewUnit) {
    TViewUnit["INCH"] = "TViewUnit_INCH";
    TViewUnit["MM"] = "TViewUnit_MM";
})(TViewUnit = exports.TViewUnit || (exports.TViewUnit = {}));
class MsgUnitChange {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgUnitChange = MsgUnitChange;
