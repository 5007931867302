"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgViewOfQueueProcessResource = exports.MsgAddQueueProcessResource = exports.MsgControlQueueProcessResource = exports.MsgModifyQueueProcessResource = exports.TStatusQueueProcessResource = exports.TActionControlQueueProcessResource = exports.TActionModifyQueueProcessResource = exports.ViewOfProcessResource = void 0;
class ViewOfProcessResource {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.ViewOfProcessResource = ViewOfProcessResource;
var TActionModifyQueueProcessResource;
(function (TActionModifyQueueProcessResource) {
    TActionModifyQueueProcessResource[TActionModifyQueueProcessResource["CANCEL"] = 0] = "CANCEL";
    TActionModifyQueueProcessResource[TActionModifyQueueProcessResource["MOVE_UP"] = 1] = "MOVE_UP";
    TActionModifyQueueProcessResource[TActionModifyQueueProcessResource["MOVE_DOWN"] = 2] = "MOVE_DOWN";
    TActionModifyQueueProcessResource[TActionModifyQueueProcessResource["PAUSE"] = 3] = "PAUSE";
    TActionModifyQueueProcessResource[TActionModifyQueueProcessResource["START"] = 4] = "START";
})(TActionModifyQueueProcessResource = exports.TActionModifyQueueProcessResource || (exports.TActionModifyQueueProcessResource = {}));
var TActionControlQueueProcessResource;
(function (TActionControlQueueProcessResource) {
    TActionControlQueueProcessResource[TActionControlQueueProcessResource["START_QUEUE"] = 0] = "START_QUEUE";
    TActionControlQueueProcessResource[TActionControlQueueProcessResource["PAUSE_QUEUE"] = 1] = "PAUSE_QUEUE";
    TActionControlQueueProcessResource[TActionControlQueueProcessResource["CANCEL_QUEUE"] = 2] = "CANCEL_QUEUE";
})(TActionControlQueueProcessResource = exports.TActionControlQueueProcessResource || (exports.TActionControlQueueProcessResource = {}));
var TStatusQueueProcessResource;
(function (TStatusQueueProcessResource) {
    TStatusQueueProcessResource[TStatusQueueProcessResource["RUNNING"] = 0] = "RUNNING";
    TStatusQueueProcessResource[TStatusQueueProcessResource["PAUSED"] = 1] = "PAUSED";
})(TStatusQueueProcessResource = exports.TStatusQueueProcessResource || (exports.TStatusQueueProcessResource = {}));
class MsgModifyQueueProcessResource {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgModifyQueueProcessResource = MsgModifyQueueProcessResource;
class MsgControlQueueProcessResource {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgControlQueueProcessResource = MsgControlQueueProcessResource;
class MsgAddQueueProcessResource {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgAddQueueProcessResource = MsgAddQueueProcessResource;
class MsgViewOfQueueProcessResource {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgViewOfQueueProcessResource = MsgViewOfQueueProcessResource;
