import React from 'react';

interface PropsComponentTabPanel {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export class ComponentTabPanel extends React.Component<PropsComponentTabPanel, {}> {
  
    render() {
        return (
            <div
                style={{width: "100%", height: "100%"}}
              role="tabpanel"

              hidden={this.props.value !== this.props.index}
              id={`simple-tabpanel-${this.props.index}`}
              aria-labelledby={`simple-tab-${this.props.index}`}
            >
                {this.props.children}
            </div>
          ); 
    }

}
