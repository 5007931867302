"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgContainerStatus = exports.ContainerStatus = void 0;
class ContainerStatus {
    constructor() {
        this.ContainerId = "";
        this.ContainerName = "";
        this.ContainerStatusText = "";
    }
}
exports.ContainerStatus = ContainerStatus;
class MsgContainerStatus {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgContainerStatus = MsgContainerStatus;
