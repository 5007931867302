import React from 'react';
import { withStyles, Theme, createStyles, WithStyles, WithTheme } from '@material-ui/core/styles';
import { Button, Typography, Divider, TextField, IconButton, InputLabel, ThemeProvider, SvgIcon } from '@material-ui/core';
import ComponentFileInfo from './ComponentFileInfo';
import PrintIcon from '@material-ui/icons/Print';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withTranslation, WithTranslation } from 'react-i18next';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  fixTopContainer: {
    display: "flex",
    flexDirection: "column",
  },
  fixBottomContainer: {
    display: "flex",
    color: theme.palette.text.primary,
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
    height: 200
  },
  fileInfoContainer: {
    display: "flex",
    flex: 1
  },
  buttonTextContainer: {
    width: "100%"
  },
  textFieldLabel: {
    color: theme.palette.text.primary
  },
  button: {
    height: 60,
    width: 140,
    color: theme.palette.text.primary
  },
  clear_selection_button: {
    backgroundColor: "#d32f2f",
    '&:hover': {
      backgroundColor: "#9a0007"
    }
  },
  print_button: {
    backgroundColor: "rgba(255,255,255,0.15)",
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  print_all_button: {
    backgroundColor: "rgba(255,255,255,0.15)",
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  copy_icon: {
    color: theme.palette.text.primary,
    marginRight: 10,
    marginLeft: 10
  },
  scroll_icon: {
    height: 30
  }
});

export type TComponentResourceLibrarySideCopies = Array<{ id: string, name: string, copy: number }>;

interface IStateComponentResourceLibrarySide
{
  scroll_buttons_visible: boolean;
}

interface PropsComponentResourceLibrarySide extends WithStyles<typeof styles>
{
  show_clear_selected: boolean;
  onClearSelected: () => void;
  copies: TComponentResourceLibrarySideCopies;
  onCopiesChange: (copies: TComponentResourceLibrarySideCopies) => void;
  resource_library_info: Array<{ name: string, value: string }>;
  resource_info: Array<{ name: string, value: string }>;
  header_text_library_info: string;
  header_text_resource_info: string;
  header_text_control: string;
  onPrintFolderClicked: () => void;
  onPrintSelectedClicked: () => void;
}

class ComponentResourceLibrarySide extends React.Component<PropsComponentResourceLibrarySide & WithTranslation, IStateComponentResourceLibrarySide> {

  private copies_full_ref_ : React.RefObject<HTMLDivElement>;
  private copies_content_ref_ : React.RefObject<HTMLDivElement>;
  private copies_to_scroll_ref_ : React.RefObject<HTMLDivElement>;

  readonly state = 
  {
    scroll_buttons_visible: false
  }

  constructor(props: PropsComponentResourceLibrarySide & WithTranslation)
  {
    super(props);
    this.copies_full_ref_ = React.createRef();
    this.copies_content_ref_ = React.createRef();
    this.copies_to_scroll_ref_ = React.createRef();
  }

  componentDidUpdate(prevprops: PropsComponentResourceLibrarySide)
  {  
    if (this.props.copies.length !== prevprops.copies.length)
    {
      this.setState({scroll_buttons_visible: this.scrollButtonsShouldBeVisible()})
    }
  }

  scrollButtonsShouldBeVisible() : boolean
  {
    let ref_content = this.copies_content_ref_.current;
    let ref_full = this.copies_full_ref_.current;

    return (
      ref_content !== undefined && ref_content !== null &&
      ref_full !== undefined && ref_full !== null &&
      ref_content.clientWidth !== 0 && 
      ref_content.clientWidth < ref_full.clientWidth);
  }

  render()
  {

    const { t, classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.fixTopContainer}>
          <ComponentFileInfo file_info={this.props.resource_library_info} text={this.props.header_text_library_info}></ComponentFileInfo>
          <div style={{ marginTop: 8, textAlign: "center" }} >
            <div hidden={!this.props.show_clear_selected}>
              <Button
                className={classes.button}
                classes={{ root: classes.clear_selection_button }}
                onClick={() => { this.props.onClearSelected() }}
                variant="outlined"
                startIcon={<ClearIcon></ClearIcon>}>
                <div className={classes.buttonTextContainer}>{t("job_library_clear_selection")}</div>
              </Button>
            </div>
          </div>
        </div>

        <div className={classes.fileInfoContainer}>
          <div ref={this.copies_full_ref_}  hidden={!this.props.show_clear_selected} style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <div>
              <ComponentFileInfo file_info={this.props.resource_info} text={this.props.header_text_resource_info}></ComponentFileInfo>
            </div>
            {this.scrollButtonsShouldBeVisible() ? 
             <IconButton className={classes.scroll_icon} onClick={()=>{this.copies_to_scroll_ref_.current?.scrollBy({behavior: 'smooth', top: -30})}}>
                <ExpandLessIcon />
             </IconButton> : null }
            <div ref={this.copies_to_scroll_ref_} style={{ display: "flex", alignItems: "center", flexDirection: "column", flex: "1", overflowY: "auto"}}>
                <div ref={this.copies_content_ref_} style={{height: 0}}>
              {this.props.copies.map((res, index) =>
                (
                  <>
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", backgroundColor: index % 2 == 0 ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.2)", paddingBottom: 5 }}>
                      <Typography variant="subtitle2" className={classes.textFieldLabel} style={{ width: "100%" }}>
                        <span style={{marginLeft: 10}}>
                        {res.name}
                        </span>
                      </Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FileCopyIcon className={classes.copy_icon}></FileCopyIcon>
                        <IconButton style={{ padding: 0 }} onClick={() => 
                        {
                          let new_copies = this.props.copies;
                          new_copies.forEach(item => { if (item.id===res.id) { item.copy = item.copy > 1 ? item.copy - 1 : 0}});
                          this.props.onCopiesChange(new_copies);
                        }}>
                          <RemoveIcon></RemoveIcon>
                        </IconButton>
                        <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          InputLabelProps={{
                            className: classes.textFieldLabel
                          }}
                          style={{ flex: 1, marginLeft: 10, marginRight: 10, marginTop: 0 }} value={res.copy}></TextField>
                        <IconButton style={{ padding: 0 }} onClick={() => 
                          { 
                            let new_copies = this.props.copies;
                            new_copies.forEach(item => { if (item.id===res.id) { item.copy = item.copy + 1}});
                            this.props.onCopiesChange(new_copies);
                          }}>
                          <AddIcon></AddIcon>
                        </IconButton>
                      </div>
                    </div>
                  </>
                ))}
                </div>
            </div>
            {this.scrollButtonsShouldBeVisible() ? 
            <IconButton className={classes.scroll_icon} onClick={()=>{this.copies_to_scroll_ref_.current?.scrollBy({behavior: 'smooth', top: 30})}}>
                <ExpandMoreIcon />
             </IconButton> : null }

          </div>
        </div>

        <div className={classes.fixBottomContainer}>
          <Typography variant="subtitle1">{this.props.header_text_control}</Typography>
          <Divider style={{ marginBottom: 8 }} />
          <Button
            onClick={this.props.onPrintFolderClicked}
            className={classes.button}
            classes={{ root: classes.print_all_button }}
            style={{ marginBottom: 8, marginTop: 5 }}
            variant="outlined"
            startIcon={<PrintIcon></PrintIcon>}>
            <div className={classes.buttonTextContainer}>{t("job_library_print_folder")}</div>
          </Button>
          <div hidden={!this.props.show_clear_selected} >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button
              onClick={this.props.onPrintSelectedClicked}
                className={classes.button}
                classes={{ root: classes.print_button }}
                style={{ marginBottom: 8 }}
                variant="outlined"
                startIcon={<PrintIcon></PrintIcon>}>
                <div className={classes.buttonTextContainer}>{t("job_library_print_selected")}</div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(ComponentResourceLibrarySide));