"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgEngineStatus = exports.ItemIconTextValue = exports.BoxIconTextValue = exports.OEMPercentage = exports.OEMNumber = exports.StatusInk = void 0;
class StatusInk {
}
exports.StatusInk = StatusInk;
class OEMNumber {
    constructor() {
        this.Label = "";
        this.Value = 0;
        this.Icon = "";
        this.NumOfDecimalPlaces = 0;
        this.Unit = "";
    }
}
exports.OEMNumber = OEMNumber;
class OEMPercentage {
    constructor() {
        this.Label = "";
        this.Value = 0;
        this.Icon = "";
    }
}
exports.OEMPercentage = OEMPercentage;
class BoxIconTextValue {
    constructor() {
        this.icon = undefined;
        this.title = undefined;
        this.ColumnData = [];
    }
}
exports.BoxIconTextValue = BoxIconTextValue;
class ItemIconTextValue {
    constructor() {
        this.text = "";
        this.value = "";
        this.icon = "";
    }
}
exports.ItemIconTextValue = ItemIconTextValue;
class MsgEngineStatus {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgEngineStatus = MsgEngineStatus;
