"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgActionHost = exports.TActionHost = void 0;
var TActionHost;
(function (TActionHost) {
    TActionHost[TActionHost["S_FUNCTION_1"] = 0] = "S_FUNCTION_1";
    TActionHost[TActionHost["S_FUNCTION_2"] = 1] = "S_FUNCTION_2";
    TActionHost[TActionHost["S_FUNCTION_3"] = 2] = "S_FUNCTION_3";
    TActionHost[TActionHost["S_FUNCTION_4"] = 3] = "S_FUNCTION_4";
    TActionHost[TActionHost["S_FUNCTION_5"] = 4] = "S_FUNCTION_5";
    TActionHost[TActionHost["S_FUNCTION_6"] = 5] = "S_FUNCTION_6";
    TActionHost[TActionHost["S_FUNCTION_7"] = 6] = "S_FUNCTION_7";
    TActionHost[TActionHost["S_FUNCTION_8"] = 7] = "S_FUNCTION_8";
    TActionHost[TActionHost["S_FUNCTION_9"] = 8] = "S_FUNCTION_9";
    TActionHost[TActionHost["S_FUNCTION_10"] = 9] = "S_FUNCTION_10";
    TActionHost[TActionHost["SHUTDOWN"] = 10] = "SHUTDOWN";
    TActionHost[TActionHost["REBOOT"] = 11] = "REBOOT";
    TActionHost[TActionHost["GET_CONTAINERS_STATUS"] = 12] = "GET_CONTAINERS_STATUS";
    TActionHost[TActionHost["REBOOT_CONTAINER"] = 13] = "REBOOT_CONTAINER";
})(TActionHost = exports.TActionHost || (exports.TActionHost = {}));
class MsgActionHost {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgActionHost = MsgActionHost;
