import { combineReducers } from 'redux'
import { ReducerQueue } from './core/ReducerQueue';
import { ReducerQueueControl } from "./core/ReducerQueueControl";
import { ReducerDashboardPage } from './dashboard-page/ReducerDashboardPage'
import { ReducerComponentJobLibraryPage } from './job-library-page/ReducerComponentJobLibraryPage'
import { ReducerJobEditorPage } from './job-editor-page/ReducerJobEditorPage'
import { ReducerMaintenancePage } from './maintenance-page/ReducerMaintenancePage';
import { ReducerSettingsPage } from './settings-page/ReducerSettingsPage';
import { ReducerApp } from './ReducerApp';

export const rootReducer = combineReducers({
    Queue: ReducerQueue,
    QueueControl: ReducerQueueControl,
    DashboardPage: ReducerDashboardPage,
    Library: ReducerComponentJobLibraryPage,
    JobEditorPage: ReducerJobEditorPage,
    MaintenancePage: ReducerMaintenancePage,
    SettingsPage: ReducerSettingsPage,
    App: ReducerApp
  })

export type RootState = ReturnType<typeof rootReducer>