"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgViewConfig = void 0;
class MsgViewConfig {
    constructor(init) {
        this.EngineStates = {};
        this.JobQueueItemStates = {};
        Object.assign(this, init);
    }
}
exports.MsgViewConfig = MsgViewConfig;
