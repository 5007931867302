import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Button, Drawer, DialogTitle, Dialog, DialogContentText, TextField, DialogContent, DialogActions, SvgIcon } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CancelIcon from '@material-ui/icons/Cancel';
import { withTranslation, WithTranslation } from 'react-i18next';
import i18n from 'i18next';

const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.background.default,
        minHeight: 40,
        color: theme.palette.text.primary,
        display: "flex",
        alignItems: "center"
    },
    iconsContainer: {
        width: 60,
        display: "flex",
        justifyContent: "center"
    },
    icon: {
        padding: 0
    },
    button: {
        padding: 0,
        minWidth: 0
    },
    tree_item_label: {
        fontSize: 20,
        padding: 10,
        width: "100%",
        flexDirection: "row"
    },
    tree_view:
    {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary
    }
});

export class FoldersComponentFolderBar
{
    public DirectoryName: string | undefined;
    public DirectoryPath: string | undefined;
    public SubDirectories: Array<FoldersComponentFolderBar> | undefined;

    public constructor(init?: Partial<FoldersComponentFolderBar>)
    {
        Object.assign(this, init);
    }
}

interface PropsComponentFolderBar extends WithStyles<typeof styles> 
{
    folders: FoldersComponentFolderBar;
    should_show_moving: boolean;
    onFolderChange: (folder_prm: string) => void,
    onCreateFolder: (folder_prm: string) => void,
    onDeleteFolder: (folder_prm: string) => void,
    onMoveFolder: (folder_src_prm: string, folder_dest_prm: string) => void,
    onRenameFolder: (folder_src_prm: string, new_folder_name_prm: string) => void,
    onMoveItemsToFolder: (folder_prm: string | undefined) => void
}

interface StateComponentFolderBar
{
    actual_folder: string;
    folder_tree_open: boolean;
    new_folder_dialog_open: boolean;
    delete_folder_dialog_open: boolean;
    rename_folder_dialog_open: boolean;
    folder_moving_happening: boolean;
    action_folder_base: string;
    new_folder_name: string;
    folder_editing_happening: boolean;
}

class ComponentFolderBar extends React.Component<PropsComponentFolderBar & WithTranslation, StateComponentFolderBar> {

    readonly state =
        {
            actual_folder: this.props.folders !== undefined && this.props.folders.DirectoryPath !== undefined ? this.props.folders.DirectoryPath : "",
            folder_tree_open: false,
            new_folder_dialog_open: false,
            delete_folder_dialog_open: false,
            rename_folder_dialog_open: false,
            folder_moving_happening: false,
            action_folder_base: "",
            new_folder_name: "",
            folder_editing_happening: false
        }

    componentDidUpdate(prevProps: PropsComponentFolderBar, prevState: StateComponentFolderBar)
    {
        if (this.props.folders !== undefined && this.props.folders.DirectoryPath !== undefined)
        {
            if (JSON.stringify(this.props.folders).indexOf('"' + this.state.actual_folder + '"') === -1)
            {
                /* current folder does not exist anymore */
                this.props.onFolderChange(this.props.folders.DirectoryPath);
                this.setState({ actual_folder: this.props.folders.DirectoryPath });
            }
        }
    }

    renderFolderTree(folder_prm: FoldersComponentFolderBar)
    {
        let classes = this.props.classes;
        if (folder_prm.DirectoryName !== undefined && folder_prm.DirectoryPath !== undefined)
        {
            return (
                <TreeItem classes={{ label: classes.tree_item_label }} nodeId={folder_prm.DirectoryPath} label={

                    <>
                        {folder_prm.DirectoryName}

                        {this.props.should_show_moving ?
                            <IconButton onClick={(event) =>
                            {
                                event.stopPropagation();
                                if (folder_prm.DirectoryPath !== undefined)
                                {
                                    this.props.onMoveItemsToFolder(folder_prm.DirectoryPath);
                                }
                            }}>
                                <SvgIcon>
                                    <path fill="currentColor" d="M19,20H5V4H7V7H17V4H19M12,2A1,1 0 0,1 13,3A1,1 0 0,1 12,4A1,1 0 0,1 11,3A1,1 0 0,1 12,2M19,2H14.82C14.4,0.84 13.3,0 12,0C10.7,0 9.6,0.84 9.18,2H5A2,2 0 0,0 3,4V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V4A2,2 0 0,0 19,2Z" />
                                </SvgIcon>
                            </IconButton>
                            : null}

                        {!this.state.folder_editing_happening && !this.props.should_show_moving ?
                            <span style={{ textAlign: "right" }}>
                                <IconButton onClick={(event) =>
                                {
                                    event.stopPropagation();
                                    this.setState({ action_folder_base: folder_prm.DirectoryPath || "", folder_editing_happening: true })
                                }}>
                                    <SvgIcon>
                                        <path fill="currentColor" d="M21,11.11C20.92,11.11 20.72,11.21 20.62,11.31L19.62,12.31L21.72,14.42L22.72,13.41C22.92,13.21 22.92,12.81 22.72,12.61L21.42,11.31C21.32,11.21 21.22,11.11 21,11.11M19.12,12.91L13,18.92V21H15.12L21.22,14.92L19.12,12.91M21,8V8.11L19,10.11V8H3V18H11V20H3A2,2 0 0,1 1,18V6C1,4.91 1.9,4 3,4H9L11,6H19C20.12,6 21,6.91 21,8Z" />
                                    </SvgIcon>
                                </IconButton>
                            </span>
                            : null}


                        {this.state.folder_editing_happening && this.state.action_folder_base === folder_prm.DirectoryPath ?
                            <>
                                <br />
                                <span style={{ backgroundColor: "rgba(0,0,0,0.2)", padding: 10 }}>

                                    {!this.state.folder_moving_happening ?
                                        <>
                                            <IconButton onClick={(event) =>
                                            {
                                                event.stopPropagation();
                                                this.setState({ action_folder_base: folder_prm.DirectoryPath || "", new_folder_dialog_open: true, folder_editing_happening: false })
                                            }}>
                                                <CreateNewFolderIcon />
                                            </IconButton>
                                            {this.props.folders.DirectoryPath !== folder_prm.DirectoryPath ?
                                                <>
                                                    <IconButton onClick={(event) =>
                                                    {
                                                        event.stopPropagation();
                                                        this.setState({ action_folder_base: folder_prm.DirectoryPath || "", delete_folder_dialog_open: true, folder_editing_happening: false })
                                                    }}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                    <IconButton onClick={(event) =>
                                                    {
                                                        event.stopPropagation();
                                                        this.setState({ action_folder_base: folder_prm.DirectoryPath || "", folder_moving_happening: true, folder_editing_happening: false })
                                                    }}>
                                                        <SvgIcon>
                                                            <path fill="currentColor" d="M19,3L13,9L15,11L22,4V3M12,12.5A0.5,0.5 0 0,1 11.5,12A0.5,0.5 0 0,1 12,11.5A0.5,0.5 0 0,1 12.5,12A0.5,0.5 0 0,1 12,12.5M6,20A2,2 0 0,1 4,18C4,16.89 4.9,16 6,16A2,2 0 0,1 8,18C8,19.11 7.1,20 6,20M6,8A2,2 0 0,1 4,6C4,4.89 4.9,4 6,4A2,2 0 0,1 8,6C8,7.11 7.1,8 6,8M9.64,7.64C9.87,7.14 10,6.59 10,6A4,4 0 0,0 6,2A4,4 0 0,0 2,6A4,4 0 0,0 6,10C6.59,10 7.14,9.87 7.64,9.64L10,12L7.64,14.36C7.14,14.13 6.59,14 6,14A4,4 0 0,0 2,18A4,4 0 0,0 6,22A4,4 0 0,0 10,18C10,17.41 9.87,16.86 9.64,16.36L12,14L19,21H22V20L9.64,7.64Z" />
                                                        </SvgIcon>
                                                    </IconButton>
                                                    <IconButton onClick={(event) =>
                                                    {
                                                        event.stopPropagation();
                                                        this.setState({ action_folder_base: folder_prm.DirectoryPath || "", new_folder_name: folder_prm.DirectoryName || "", rename_folder_dialog_open: true, folder_editing_happening: false })
                                                    }}>
                                                        <SvgIcon>
                                                            <path fill="currentColor" d="M17,7H22V17H17V19A1,1 0 0,0 18,20H20V22H17.5C16.95,22 16,21.55 16,21C16,21.55 15.05,22 14.5,22H12V20H14A1,1 0 0,0 15,19V5A1,1 0 0,0 14,4H12V2H14.5C15.05,2 16,2.45 16,3C16,2.45 16.95,2 17.5,2H20V4H18A1,1 0 0,0 17,5V7M2,7H13V9H4V15H13V17H2V7M20,15V9H17V15H20Z" />
                                                        </SvgIcon>
                                                    </IconButton>
                                                </> : null}
                                        </>
                                        : null}
                                </span>
                            </>

                            : null}

                        {this.state.folder_moving_happening && folder_prm.DirectoryPath.indexOf(this.state.action_folder_base) === -1 ?
                            <IconButton onClick={(event) =>
                            {
                                event.stopPropagation();
                                this.setState({ action_folder_base: "", folder_moving_happening: false, folder_editing_happening: false });
                                if (folder_prm.DirectoryPath !== undefined)
                                {
                                    this.props.onMoveFolder(this.state.action_folder_base, folder_prm.DirectoryPath);
                                }
                            }}>
                                <SvgIcon>
                                    <path fill="currentColor" d="M19,20H5V4H7V7H17V4H19M12,2A1,1 0 0,1 13,3A1,1 0 0,1 12,4A1,1 0 0,1 11,3A1,1 0 0,1 12,2M19,2H14.82C14.4,0.84 13.3,0 12,0C10.7,0 9.6,0.84 9.18,2H5A2,2 0 0,0 3,4V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V4A2,2 0 0,0 19,2Z" />
                                </SvgIcon>
                            </IconButton>
                            : null}




                    </>

                }>
                    {folder_prm.SubDirectories !== undefined ?
                        folder_prm.SubDirectories.map(folder => this.renderFolderTree(folder))
                        : null
                    }
                </TreeItem>
            );
        }
        else return null;
    }

    handleFolderChange(folder_prm: string)
    {
        this.props.onFolderChange(folder_prm);
        this.setState({ actual_folder: folder_prm });
    }

    handleCloseNewFolderDialog()
    {
        this.setState({ new_folder_dialog_open: false, new_folder_name: "" });
    }

    handleRenameFolderDialog()
    {
        this.setState({ rename_folder_dialog_open: false, new_folder_name: "" });
    }

    handleCloseDeleteFolderDialog()
    {
        this.setState({ delete_folder_dialog_open: false });
    }

    renderDeleteFolderDialog()
    {
        return (
            <Dialog open={this.state.delete_folder_dialog_open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{i18n.t("job_library_delete_folder")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {i18n.t("job_library_remove_folders_warn")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDeleteFolderDialog.bind(this)} color="primary">
                        {i18n.t("job_library_cancel")}
                    </Button>
                    <Button onClick={() => 
                    {
                        this.props.onDeleteFolder(this.state.action_folder_base);
                        this.setState({ delete_folder_dialog_open: false, action_folder_base: "" });
                    }} color="primary">
                        {i18n.t("job_library_remove")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderNewFolderDialog()
    {
        return (
            <Dialog open={this.state.new_folder_dialog_open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{i18n.t("job_library_create_new_folder")}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="new_folder_name"
                        label={i18n.t("job_library_folder_name")}
                        fullWidth
                        value={this.state.new_folder_name}
                        onChange={(event) => { this.setState({ new_folder_name: event.target.value }) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseNewFolderDialog.bind(this)} color="primary">
                        {i18n.t("job_library_cancel")}
                    </Button>
                    <Button onClick={() => 
                    {
                        this.props.onCreateFolder(this.state.action_folder_base + "/" + this.state.new_folder_name);
                        this.setState({ new_folder_dialog_open: false, new_folder_name: "", action_folder_base: "" });
                    }} color="primary">
                        {i18n.t("job_library_create")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderRenameFolderDialog()
    {
        return (
            <Dialog open={this.state.rename_folder_dialog_open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{i18n.t("job_library_rename_folder")}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="new_folder_name"
                        label={i18n.t("job_library_folder_name")}
                        fullWidth
                        value={this.state.new_folder_name}
                        onChange={(event) => { this.setState({ new_folder_name: event.target.value }) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRenameFolderDialog.bind(this)} color="primary">
                        {i18n.t("job_library_cancel")}
                    </Button>
                    <Button onClick={() => 
                    {
                        this.props.onRenameFolder(this.state.action_folder_base, this.state.new_folder_name);
                        this.setState({ rename_folder_dialog_open: false, new_folder_name: "", action_folder_base: "" });
                    }} color="primary">
                        {i18n.t("job_library_rename")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render()
    {

        const { t, classes } = this.props;



        if (this.props.folders !== undefined && this.props.folders.DirectoryPath !== undefined && this.props.folders.DirectoryName !== undefined && this.state.actual_folder !== undefined)
        {
            {/* " it is being generated from the directory *NAME* not the Path" */}
            let actual_path_str = this.props.folders.DirectoryName + this.state.actual_folder.substr(this.props.folders.DirectoryPath.length);

            let folders = actual_path_str.split('/').map((item, index) =>
            {
                return (
                    <Button className={classes.button} key={index} onClick={() =>
                    {
                        if (this.props.folders.DirectoryPath !== undefined)
                        {
                            let partial_path = this.props.folders.DirectoryPath;
                            for (let i = 0; i < index; i++) partial_path += "/" + actual_path_str.split('/')[i + 1];
                            this.handleFolderChange(partial_path);
                        }
                    }}>
                        <Typography variant="h6" component="h6">
                            /{item}
                        </Typography>
                    </Button>
                );
            });

            return (
                <>
                    {this.renderNewFolderDialog()}
                    {this.renderDeleteFolderDialog()}
                    {this.renderRenameFolderDialog()}
                    <span className={classes.root}>
                        <div className={classes.iconsContainer}>
                            <IconButton className={classes.icon} onClick={() =>
                            {
                                if (this.props.folders.DirectoryPath !== undefined)
                                {
                                    let folders = this.state.actual_folder.split('/');
                                    folders.pop();
                                    let res = folders.join('/');
                                    if (res.length >= this.props.folders.DirectoryPath.length)
                                    {
                                        this.handleFolderChange(res);
                                    }
                                }
                            }}>
                                <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                            </IconButton>
                            {/*<IconButton className={classes.icon}>
                                <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                              </IconButton>*/}
                        </div>

                        <div style={{ flex: 1 }}>
                            {folders}
                        </div>
                        <div style={{ paddingRight: 20 }}>
                            <IconButton className={classes.icon} onClick={() => { this.setState({ folder_tree_open: true }) }}>
                                <FolderOpenIcon className={classes.icon}></FolderOpenIcon>
                            </IconButton>

                        </div>


                    </span>
                    <Drawer anchor="right" open={this.state.folder_tree_open || this.props.should_show_moving} onClose={() => { this.setState({ folder_tree_open: false }); this.props.onMoveItemsToFolder(undefined); }}>
                        <TreeView
                            className={classes.tree_view}
                            style={{ width: 500, flexGrow: 1 }}
                            defaultCollapseIcon={<ExpandMoreIcon style={{ fontSize: 20 }} />}
                            defaultExpandIcon={<ChevronRightIcon style={{ fontSize: 20 }} />}
                            defaultExpanded={[this.props.folders.DirectoryPath]}
                            onNodeSelect={(event: React.ChangeEvent<{}>, nodeId: string) =>
                            {
                                this.handleFolderChange(nodeId);
                            }}
                        >
                            {this.renderFolderTree(this.props.folders)}
                        </TreeView>
                        {this.state.folder_moving_happening ?
                            <>
                                <Button onClick={(event) =>
                                {
                                    event.stopPropagation();
                                    this.setState({ action_folder_base: "", folder_moving_happening: false })
                                }} style={{ fontSize: 25 }} startIcon={<CancelIcon />}>
                                    {t("job_library_cancel_cutting")}
                                </Button>
                            </>
                            : null}

                        {!this.state.folder_moving_happening && this.state.folder_editing_happening ?
                            <>
                                <Button onClick={(event) =>
                                {
                                    event.stopPropagation();
                                    this.setState({ action_folder_base: "", folder_editing_happening: false })
                                }} style={{ fontSize: 25 }} startIcon={<CancelIcon />}>
                                    {t("job_library_cancel_editing")}
                                </Button>
                            </>
                            : null}


                        {this.props.should_show_moving ?
                            <>
                                <Button onClick={(event) =>
                                {
                                    event.stopPropagation();
                                    this.props.onMoveItemsToFolder(undefined);
                                }} style={{ fontSize: 25 }} startIcon={<CancelIcon />}>
                                    {t("job_library_cancel_moving_items")}
                                </Button>
                            </>
                            : null}
                    </Drawer>
                </>
            )

        }
        else
        {
            return null;
        }


    }
}

export default withTranslation()(withStyles(styles, { withTheme: true })(ComponentFolderBar));