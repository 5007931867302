"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgActionJobLibrary = exports.TActionJobLibrary = exports.ViewOfItemJobLibrary = void 0;
class ViewOfItemJobLibrary {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.ViewOfItemJobLibrary = ViewOfItemJobLibrary;
var TActionJobLibrary;
(function (TActionJobLibrary) {
    TActionJobLibrary[TActionJobLibrary["PRINT_ITEMS"] = 0] = "PRINT_ITEMS";
    TActionJobLibrary[TActionJobLibrary["DELETE_ITEMS"] = 1] = "DELETE_ITEMS";
    TActionJobLibrary[TActionJobLibrary["MOVE_ITEMS"] = 2] = "MOVE_ITEMS";
    TActionJobLibrary[TActionJobLibrary["CREATE_DIRECTORY"] = 3] = "CREATE_DIRECTORY";
    TActionJobLibrary[TActionJobLibrary["DELETE_DIRECTORY"] = 4] = "DELETE_DIRECTORY";
    TActionJobLibrary[TActionJobLibrary["MOVE_DIRECTORY"] = 5] = "MOVE_DIRECTORY";
    TActionJobLibrary[TActionJobLibrary["RENAME_DIRECTORY"] = 6] = "RENAME_DIRECTORY";
    TActionJobLibrary[TActionJobLibrary["RENAME_ITEM"] = 7] = "RENAME_ITEM";
    TActionJobLibrary[TActionJobLibrary["CHANGE_COPY_NUMBER"] = 8] = "CHANGE_COPY_NUMBER";
})(TActionJobLibrary = exports.TActionJobLibrary || (exports.TActionJobLibrary = {}));
class MsgActionJobLibrary {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgActionJobLibrary = MsgActionJobLibrary;
