import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import ComponentThumbnail from './ComponentThumbnail';
import ComponentToolbar from './ComponentToolbar';
import ComponentFolderBar, { FoldersComponentFolderBar } from './ComponentFolderBar';
import ComponentTableRow from './ComponentTableRow';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import { LayoutResourceLibraryMain } from "./LayoutResourceLibraryMain";
import { Dialog } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  thuimbnailsParent: { // Content element's parent, overflowY: "auto" is mandatory.
    display: "flex",
    flex: 1,
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper
  },
  thumbnails: { // The content we want to scroll, height: 0 is mandatory.
    padding: 10,
    height: 0,

    width: "100%"
  }
});



interface PropsComponentResourceLibraryMain extends WithStyles<typeof styles>
{
  thumbnails: Array<{ cloud: boolean, image_url: string, filename: string, selected: boolean, owner_img_urls: string[], date: Date, size: number, filepath: string, copy_number: number }>;
  thumbnail_size: { width: number, height: number };
  folders: FoldersComponentFolderBar;
  show_log: boolean;
  onShowLogToggle: () => void;
  onSortClick: () => void;
  sort_text: string;
  onThumbnailClick: (id: number) => void;
  onThumbnailEdit: (id: number) => void;
  layout_text: string;
  onLayoutClick: () => void;
  layout: LayoutResourceLibraryMain;
  onMultiSelectToggle: () => void;
  multiselect: boolean;
  show_selected: boolean;
  onShowSelectedToggle: () => void;
  show_owners: boolean;
  onFolderChange: (folder_prm: string) => void;
  onCreateFolder: (folder_prm: string) => void;
  onDeleteFolder: (folder_prm: string) => void;
  onMoveFolder: (folder_src_prm: string, folder_dest_prm: string) => void;
  onRenameFolder: (folder_src_prm: string, new_folder_name_prm: string) => void;
  onRemoveItem: (clicked_item_prm: string) => void;
  onMoveItem: (clicked_item_prm: string, folder_dest_prm: string) => void;
  doResizeAndBlurEffect : boolean;
}

interface IStateComponentResourceLibraryMain
{
  moving_items_happening : boolean;
  moving_item_clicked : string;
}

class ComponentResourceLibraryMain extends React.Component<PropsComponentResourceLibraryMain, IStateComponentResourceLibraryMain> {

  readonly state =
  {
    moving_items_happening: false,
    moving_item_clicked: ""
  }

  

  handleMoveItemClick(file_path: string)
  {
    this.setState({moving_items_happening: true, moving_item_clicked: file_path});
  }

  handleRemoveItemClick(file_path: string)
  {
    this.props.onRemoveItem(file_path);
  }

  handleMoveItemsToFolder(destination_folder: string | undefined)
  {
    if (destination_folder !== undefined)
    {
      this.props.onMoveItem(this.state.moving_item_clicked,destination_folder);
    }
    this.setState({moving_items_happening: false, moving_item_clicked: ""});

  }



  render()
  {

    let classes = this.props.classes;


    let list_view = this.props.layout == LayoutResourceLibraryMain.LIST;

    let thumbnails = this.props.thumbnails.map((item, index) =>
    {

      if (list_view)
      {

        return (
          <ComponentTableRow key={index}
            image_url={item.image_url}
            filename={item.filename}
            selected={item.selected}
            owner_img_urls={item.owner_img_urls}
            date={item.date}
            onClick={() => { this.props.onThumbnailClick(index) }}
            onMoveClick={()=>{this.handleMoveItemClick(item.filepath)}}
            onRemoveClick={()=>{this.handleRemoveItemClick(item.filepath)}}
            onEditClick={()=>{this.props.onThumbnailEdit(index)}}
            show_owners={this.props.show_owners}
            show_cloud_button={item.cloud}>
          </ComponentTableRow>
        );

      } else
      {
        return (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2}>
            {/*
            <Box style={{backgroundColor: "white", paddingTop: "100%", position: "relative"}}>
              <p style={{ margin: 0, position: "absolute", top: 0, left: 0, bottom: 0, right: 0}}>Test</p>
            </Box>*/}
           
            <ComponentThumbnail
              doResizeAndBlurEffect={this.props.doResizeAndBlurEffect}
              onMoveClick={()=>{this.handleMoveItemClick(item.filepath)}}
              onRemoveClick={()=>{this.handleRemoveItemClick(item.filepath)}}
              onEditClick={()=>{this.props.onThumbnailEdit(index)}}
              image_url={item.image_url}
              show_cloud_button={item.cloud}
              filename={item.filename}
              selected={item.selected}
              show_selection={this.props.multiselect ? this.props.multiselect : item.selected}
              owner_img_urls={item.owner_img_urls}
              onClick={() => { this.props.onThumbnailClick(index) }}
              show_owners={this.props.show_owners}>
            </ComponentThumbnail>
            

          </Grid>);
      }
    });

    let thumbnails_parent;

    if (list_view)
    {
      thumbnails_parent =
        <Table>
          {thumbnails}
        </Table>
    } else
    {
      thumbnails_parent =
        <Grid container spacing={2}>
          {thumbnails}
        </Grid>
    }




    return (
      <div className={classes.root}>
        <ComponentFolderBar 
            folders={this.props.folders} 
            onFolderChange={this.props.onFolderChange}
            onCreateFolder={this.props.onCreateFolder}
            onDeleteFolder={this.props.onDeleteFolder}
            onMoveFolder={this.props.onMoveFolder}
            onRenameFolder={this.props.onRenameFolder}
            should_show_moving={this.state.moving_items_happening}
            onMoveItemsToFolder={this.handleMoveItemsToFolder.bind(this)}
            ></ComponentFolderBar>
        <div style={{ flexDirection: "row", display: "flex", flex: 1 }}>
          <ComponentToolbar
            show_selected={this.props.show_selected}
            onShowSelectedToggle={() => { this.props.onShowSelectedToggle() }}
            multiselect={this.props.multiselect}
            onMultiselectToggle={() => { this.props.onMultiSelectToggle() }}
            layout={this.props.layout}
            onSortClick={() => { this.props.onSortClick() }}
            sort_text={this.props.sort_text}
            show_log={this.props.show_log}
            onShowLogToggle={() => { this.props.onShowLogToggle(); }}
            layout_text={this.props.layout_text}
            onLayoutClick={() => { this.props.onLayoutClick() }}>

          </ComponentToolbar>
          <div className={classes.thuimbnailsParent}>
            <div className={classes.thumbnails}>
              {thumbnails_parent}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ComponentResourceLibraryMain);

