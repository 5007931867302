import { IReduxStateSettingsPage } from "./ComponentSettingsPage";
import { MsgEngineStatus, MsgViewConfig } from "@ginf/inkstorm-msg";

const initialState : IReduxStateSettingsPage =
{
    engine_status: new MsgEngineStatus(),
    view_config: new MsgViewConfig(),
    show_connection_lost_message: false
};



export enum TTypeActionReducerSettingsPage
{
  /* accepted actions */
  REFRESH_ENGINE_STATE = "TTypeActionReducerSettingsPage_REFRESH_ENGINE_STATE",
  REFRESH_VIEW_CONFIG = "TTypeActionReducerSettingsPage_REFRESH_VIEW_CONFIG",
  SHOW_CONNECTION_LOST_MESSAGE = "TTypeActionReducerSettingsPage_SHOW_CONNECTION_LOST_MESSAGE"
  /*emitted actions*/
}



export type TActionReducerSettingsPage = 
/*accepted actions*/
  { type: TTypeActionReducerSettingsPage.REFRESH_ENGINE_STATE, data: MsgEngineStatus }
| { type: TTypeActionReducerSettingsPage.REFRESH_VIEW_CONFIG, data: MsgViewConfig }
| { type: TTypeActionReducerSettingsPage.SHOW_CONNECTION_LOST_MESSAGE, data: boolean };
/*emitted actions*/



export function ReducerSettingsPage(
    state = initialState,
    action: TActionReducerSettingsPage
  ): IReduxStateSettingsPage
  {
    
    switch (action.type)
    {
        case TTypeActionReducerSettingsPage.REFRESH_ENGINE_STATE:
          return {...state, engine_status: action.data};
        break;
        case TTypeActionReducerSettingsPage.REFRESH_VIEW_CONFIG:
          return {...state, view_config: action.data};
        break;
        case TTypeActionReducerSettingsPage.SHOW_CONNECTION_LOST_MESSAGE:
          return {...state, show_connection_lost_message: action.data};
        break;

      default:
        return state;
    }
  }