import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, ButtonBase } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    icon: {
        
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        minWidth: 70,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    button: {
        color: theme.palette.text.primary,
        width: 70,
        height: 70
    }
});


interface PropsComponentButtonBarButton extends WithStyles<typeof styles> {
  text: string;
  onClick?: () => void;
  style?:{width?: number, height?: number}
}


class ComponentButtonBarButton extends React.Component<PropsComponentButtonBarButton, {}> {

    render() {

        let classes = this.props.classes;

        return (
            <ButtonBase className={classes.button} style={{...this.props.style}} onClick={() => {
                  if(this.props.onClick != undefined)
                    this.props.onClick() 
                 }}>
                <div>
                {this.props.children}
                <Typography variant="subtitle2">
                    {this.props.text}
                </Typography>
                </div>
            </ButtonBase>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ComponentButtonBarButton);
