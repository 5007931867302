import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ComponentToolbarButton from "../core/ComponenToolbarButton";
import ComponentQueueControl from "../core/ComponentQueueControl";
import ComponentInkDrops from '../core/ComponentInkDrops';
import ComponentColoumnDataShow from '../core/ComponentColumnDataShow';
import ComponentLabeledProgressBar from '../core/ComponentLabeledProgressBar';
import ShortTextIcon from '@material-ui/icons/ShortText';
import ComponentResourceQueue from '../core/ComponentResourceQueue';
import { ResourceQueueItemJob } from '../core/ResourceQueueItemJob';
import { QueueType } from "../core/ComponentResourceQueue";
import { Typography, Divider, SvgIcon, List, ListItem } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import ComponentInkLevels from "./ComponentInkLevels";
import { RootState } from '../RootReducer';
import { connect, ConnectedProps } from 'react-redux';
import { MsgEngineStatus, MsgRestrictionView, MsgStatistics, MsgViewConfig, OEMNumber } from '@ginf/inkstorm-msg';
import { TItemComponentInkDrops } from '../core/ComponentInkDrops';
import { ComponentInchChangeMm, TStateSwitchComponentInchChangeMm } from '../ComponentInchChangeMm';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ConnectionLostBar } from '../ConnectionLostBar';

const styles = (theme: Theme) => createStyles({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "row",
    "& ul li": {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  navigation_bar: {
    minHeight: 40,
    display: "flex",
    alignItems: "center"
  },
  toolbar: {
    minWidth: 72,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  icons_container: {
    width: 60,
    display: "flex",
    justifyContent: "center"
  },
  icon: {
    padding: 0
  },
  queue_and_statistics_container:
  {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  queue_root_container: {
    width: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    flex: 1
  },
  fixBottomContainer: {
    display: "flex",
    color: theme.palette.text.primary,
    flexDirection: "column",
    height: 200
  },
  fixMmInchSwitchContainer: {
    display: "flex",
    color: theme.palette.text.primary,
    flexDirection: "column",
    height: window.oem_config?.mm_inch_dashboard ? 50 : 0
  },
  statisticsDashboardContainer: {
    height: 140,
    display: "flex"
  },
  statisticsDashboardContainerItem: {
    margin: 5,
    backgroundColor: theme.palette.background.default,
    boxShadow: " 3px 3px 5px 0px rgba(50, 50, 50, 0.75)",
    borderRadius: 6,
    overflow: "hidden"
  },
  dataRightBar:
  {
    display: "flex",
    alignItems: "center",
    textAlign: "center"
  }


});

export interface IReduxStateDashboardPage {
  engine_status: MsgEngineStatus,
  view_restrictions: MsgRestrictionView,
  view_config: MsgViewConfig,
  ink_items: Array<Array<TItemComponentInkDrops>>,
  ink_text: Array<{ up_cost: string, up_ink: string, down_cost: string, down_ink: string }>
  show_connection_lost_message: boolean,
}

const mapState = (state: RootState): IReduxStateDashboardPage => (
  {
    engine_status: state.DashboardPage.engine_status,
    view_restrictions: state.DashboardPage.view_restrictions,
    view_config: state.DashboardPage.view_config,
    ink_items: state.DashboardPage.ink_items,
    ink_text: state.DashboardPage.ink_text,
    show_connection_lost_message: state.SettingsPage.show_connection_lost_message,
  });

const mapDispatch = {

}

interface IStateDashboardPage {
  //ink_items: Array<Array<TItemComponentInkDrops>>;
  //ink_text: Array<{ up_cost: string, up_ink: string, down_cost: string, down_ink: string }>;
  actual_ink_items_index: number;
}

// Do the first half of the `connect()` call separately, 
// before declaring the component
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector> & WithTranslation;

export interface PropsDashboardPage extends PropsFromRedux, WithStyles<typeof styles> {
  show_log: boolean;
  show_frame: boolean;
  log_component: JSX.Element;
  frame_component?: JSX.Element;
  onShowLogToggle: () => void;
  onShowFrameToggle: () => void;
  onMmInchChange: (value: TStateSwitchComponentInchChangeMm) => void;
  currentMmInchState: TStateSwitchComponentInchChangeMm;
}

class ComponentDashboardPage extends React.Component<PropsDashboardPage, IStateDashboardPage> {

  readonly state =
    {
      actual_ink_items_index: 0
    }

  formatValueToFixed2 = (value: number) => value.toFixed(2);

  createOEMNumberComponent(oem_number: OEMNumber) {

    const { classes } = this.props;

    return (<div className={classes.dataRightBar}>
      <img style={{ paddingRight: 10 }} src={oem_number.Icon} />
      <Typography>{oem_number.Label}<br /> <strong>
        {(Math.round(oem_number.Value * 10 ** oem_number.NumOfDecimalPlaces) / 10 ** oem_number.NumOfDecimalPlaces).toFixed(oem_number.NumOfDecimalPlaces)} {oem_number.Unit}</strong></Typography>
    </div>)
  }

  createColumnDataOverride() {
    const { t }= this.props;
    let override_data_items: Array<{data: string, data_caption:string}>=[];
    if (this.props.engine_status.box_dashboard_bottom_right){
      this.props.engine_status.box_dashboard_bottom_right.ColumnData.forEach((item) => {
        override_data_items.push({ data: item.value, data_caption: item.text });
      })
    }
    return override_data_items;
  }

  render() {

    const { t, classes } = this.props;

    let engine_state = this.props.view_config.EngineStates[this.props.engine_status.EngineState!];

    let oem_percentage_component = null;
    let oem_number1_component = null;
    let oem_number2_component = null;
    let oem_number3_component = null;

    if (this.props.engine_status.OEMPercentage != undefined) {

      oem_percentage_component =
        <div style={{ flex: 1, marginTop: 10 }}>
          <ComponentLabeledProgressBar value={this.props.engine_status.OEMPercentage.Value} title_text={this.props.engine_status.OEMPercentage.Label} title_icon={
            <img src={this.props.engine_status.OEMPercentage.Icon} />
          } />
        </div>
    }

    if (this.props.engine_status.OEMNumber1 != undefined) {
      oem_number1_component = this.createOEMNumberComponent(this.props.engine_status.OEMNumber1);
    }

    if (this.props.engine_status.OEMNumber2 != undefined) {
      oem_number2_component = this.createOEMNumberComponent(this.props.engine_status.OEMNumber2);
    }

    if (this.props.engine_status.OEMNumber3 != undefined) {
      oem_number3_component = this.createOEMNumberComponent(this.props.engine_status.OEMNumber3);
    }

    return (
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>

          <div className={classes.navigation_bar}>
            <div className={classes.icons_container}>
              <IconButton className={classes.icon}>
                <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
              </IconButton>
              <IconButton className={classes.icon}>
                <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
              </IconButton>
            </div>
            {this.props.show_connection_lost_message ?
              ConnectionLostBar(!this.props.show_connection_lost_message) : <></>}
          </div>


          <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
            <div className={classes.toolbar}>
              <div style={{ flex: 1 }}></div>
              {this.props.frame_component !== undefined ? <ToggleButton value={this.props.show_frame} style={{ padding: 0, borderRadius: 0 }} selected={this.props.show_frame} onChange={() => { this.props.onShowFrameToggle() }}>
                <ComponentToolbarButton text="Frame">
                  <ShortTextIcon></ShortTextIcon>
                </ComponentToolbarButton>
              </ToggleButton> : null}
              <ToggleButton value={this.props.show_log} style={{ padding: 0, borderRadius: 0 }} selected={this.props.show_log} onChange={() => { this.props.onShowLogToggle() }}>
                <ComponentToolbarButton text="LOG">
                  <ShortTextIcon></ShortTextIcon>
                </ComponentToolbarButton>
              </ToggleButton>
            </div>

            <div className={classes.queue_and_statistics_container}>
              <div className={classes.queue_root_container}>
                <div style={{ height: 0 }}>
                  <ComponentResourceQueue type={QueueType.JOB} id="job_queue"></ComponentResourceQueue>
                </div>
              </div>
              <div className={classes.statisticsDashboardContainer}>
                {this.props.view_restrictions.ShowStatistics === true ?
                  <div className={classes.statisticsDashboardContainerItem} style={{ width: "65%" }}>

                    <ComponentInkDrops
                      go_right_icon={
                        <SvgIcon>
                          <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                        </SvgIcon>
                      }
                      go_left_icon={
                        <SvgIcon>
                          <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                        </SvgIcon>
                      }
                      items={this.props.ink_items.length > 0 && this.state.actual_ink_items_index < this.props.ink_items.length ? this.props.ink_items[this.state.actual_ink_items_index] : []}
                      up_ink_text={this.props.ink_text[this.state.actual_ink_items_index]?.up_ink || ""}
                      down_ink_text={this.props.ink_text[this.state.actual_ink_items_index]?.down_ink || ""}
                      up_cost_text={this.props.ink_text[this.state.actual_ink_items_index]?.up_cost || ""}
                      down_cost_text={this.props.ink_text[this.state.actual_ink_items_index]?.down_cost || ""}

                      onLeft={() => {
                        let new_index = this.state.actual_ink_items_index - 1;
                        if (new_index < 0) new_index = this.props.ink_items.length - 1;
                        this.setState({ actual_ink_items_index: new_index });
                      }}

                      onRight={() => {
                        let new_index = this.state.actual_ink_items_index + 1;
                        if (new_index >= this.props.ink_items.length) new_index = 0;
                        this.setState({ actual_ink_items_index: new_index });
                      }}
                    />
                  </div>
                  : <></>}
                <div style={{ display: "flex", flexDirection: "column", width: this.props.view_restrictions.ShowStatistics === true ? "35%" : "100%" }} className={classes.statisticsDashboardContainerItem}>
                  <div>
                    <ComponentColoumnDataShow title_text={this.props.engine_status.box_dashboard_bottom_right?.title || t("page_counter")} items={this.createColumnDataOverride().length != 0 ? this.createColumnDataOverride() : 
                      [
                        { data: this.props.engine_status.EngineTotalPagesPrinted !== undefined && this.props.engine_status.EngineContinousPagesPrinted !== undefined && this.props.engine_status.EngineTotalPagesPrinted >= this.props.engine_status.EngineContinousPagesPrinted ? this.props.engine_status.EngineTotalPagesPrinted : "-", data_caption: t("pages_all") },
                        { data: this.props.engine_status.EngineContinousPagesPrinted !== undefined ? this.props.engine_status.EngineContinousPagesPrinted : "-", data_caption: t("pages_continous") },
                        { data: this.props.engine_status.EngineTotalPagesPrinted !== undefined && this.props.engine_status.EngineContinousPagesPrinted !== undefined && this.props.engine_status.EngineTotalPagesPrinted - this.props.engine_status.EngineContinousPagesPrinted >= 0 ? this.props.engine_status.EngineTotalPagesPrinted - this.props.engine_status.EngineContinousPagesPrinted : "-", data_caption: t("pages_single") },
                      ]
                    } title_icon={
                      this.props.engine_status.box_dashboard_bottom_right?.icon !== undefined ? <img src={this.props.engine_status.box_dashboard_bottom_right.icon}/>:
                      <SvgIcon>
                        <path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
                      </SvgIcon>
                    } />
                  </div>
                  {oem_percentage_component}
                </div>
              </div>


              <div style={{ height: this.props.show_frame ? 150 : 0, bottom: 65, zIndex: 10 }}>
                {this.props.frame_component}
              </div>
              <div style={{ height: this.props.show_log ? 90 : 0, /* position: "absolute", */ bottom: 65, zIndex: 10, width: "50%" }}>
                {this.props.log_component}
              </div>
            </div>
          </div>
        </div>


        <div style={{ display: "flex", flexDirection: "column", width: 180, minWidth: 180, padding: 20, paddingTop: 0, paddingBottom: 10 }}>
          <div style={{ display: "flex", minHeight: 40, flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
            <PrintIcon></PrintIcon>
            <Typography variant="subtitle1" style={{ marginLeft: 5 }}>
              {engine_state}
            </Typography>

          </div>
          <div style={{ flex: 3 }}>
            <ComponentInkLevels key={
              "ink_" +
              this.props.engine_status.InkC?.Percentage?.toString() +
              this.props.engine_status.InkM?.Percentage?.toString() +
              this.props.engine_status.InkY?.Percentage?.toString() +
              this.props.engine_status.InkK?.Percentage?.toString()
            }
              c={this.props.engine_status.InkC?.Percentage || 0}
              m={this.props.engine_status.InkM?.Percentage || 0}
              y={this.props.engine_status.InkY?.Percentage || 0}
              k={this.props.engine_status.InkK?.Percentage || 0}
              c_warning={this.props.engine_status.InkC?.Warning || false}
              m_warning={this.props.engine_status.InkM?.Warning || false}
              y_warning={this.props.engine_status.InkY?.Warning || false}
              k_warning={this.props.engine_status.InkK?.Warning || false}
              c_out={this.props.engine_status.InkC?.Out || false}
              m_out={this.props.engine_status.InkM?.Out || false}
              y_out={this.props.engine_status.InkY?.Out || false}
              k_out={this.props.engine_status.InkK?.Out || false}
            ></ComponentInkLevels>
            <div>
              <List>

                <ListItem>
                  <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between" }}>
                    {oem_number1_component}
                    {oem_number1_component && oem_number2_component ? <Divider orientation="vertical" flexItem style={{ margin: 5 }} /> : null}
                    {oem_number2_component}
                  </div>
                </ListItem>
                {(oem_number1_component || oem_number2_component) && oem_number3_component ? <Divider /> : null}
                <ListItem>
                  {oem_number3_component}
                </ListItem>


              </List>
            </div>
          </div>
          <div style={{ flex: 4, marginBottom: "20%" }}>
            <iframe src={window.oem_config?.dashboard_side_frame_url} style={{ height: "100%", width: "100%", border: 0 }}></iframe>
          </div>

          <div className={classes.fixBottomContainer}>
            <Typography variant="subtitle1">{t("queue_control")}</Typography>
            <Divider style={{ marginBottom: 8 }} />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 5 }}>
              <ComponentQueueControl id="job_queue"></ComponentQueueControl>
            </div>
          </div>

          <div className={classes.fixMmInchSwitchContainer}>
            {window.oem_config?.mm_inch_dashboard ?
              <ComponentInchChangeMm mmOrInch={this.props.currentMmInchState} valueChanged={this.props.onMmInchChange} /> : <></>}
          </div>
        </div>

      </div>
    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true })(connector(ComponentDashboardPage)));
