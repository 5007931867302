"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgJobStatistics = exports.MsgActionJobStatistics = exports.TActionMsgJobStatistics = void 0;
var TActionMsgJobStatistics;
(function (TActionMsgJobStatistics) {
    TActionMsgJobStatistics[TActionMsgJobStatistics["GET_DETAILS"] = 0] = "GET_DETAILS";
})(TActionMsgJobStatistics = exports.TActionMsgJobStatistics || (exports.TActionMsgJobStatistics = {}));
class MsgActionJobStatistics {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgActionJobStatistics = MsgActionJobStatistics;
class MsgJobStatistics {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgJobStatistics = MsgJobStatistics;
