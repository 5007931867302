import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';



i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'universal',
    debug: false,
    resources: {
        universal: {
          translation: {
            connection_lost: "Connection lost",
            connection_lost_message: "Reconnecting..."
        }
      }
    }
  });


export { i18n };
