import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import CloudDownloadOutline from 'mdi-material-ui/CloudDownloadOutline';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import PencilOutline from 'mdi-material-ui/PencilOutline';
import Card from "@material-ui/core/Card";
import { IconButton, Checkbox, Avatar, TableRow, TableCell, SvgIcon } from '@material-ui/core';


const styles = (theme: Theme) => createStyles({
  imageSrc: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    minWidth: 50,
    minHeight: 50
  },
  root: {
    color: theme.palette.text.primary
  },
  icon: {
    width: 28,
    height: 28
  },
  iconButton: {
    padding: 6
  },
  iconButtonDownload: {
    padding: 6,
    marginRight: 3
  },
});

interface PropsComponentTableRow extends WithStyles<typeof styles> {
  image_url: string;
  filename: string;
  selected: boolean;
  onClick: () => void;
  owner_img_urls: string[];
  key: string | number;
  date: Date;
  show_owners: boolean;
  show_cloud_button: boolean;
  onRemoveClick?: () => void;
  onMoveClick?: () => void;
  onEditClick?: () => void;
}

class ComponentTableRow extends React.Component<PropsComponentTableRow, {}> {

  private long_press: boolean = false;
  private timeout_id: number = -1;

  preventPropagation(e: React.SyntheticEvent) {
    e.stopPropagation();
  }

  onMouseDown() {
    this.long_press = false;
    this.timeout_id = window.setTimeout(() => {
      this.long_press = true;
    }, 2000);
  }

  onMouseUp() {
    clearTimeout(this.timeout_id);
    this.timeout_id = -1;
    if(this.long_press) {
      console.log("Long press");
    } else {
      this.props.onClick();
    }
  }

  render() {

    let classes = this.props.classes;
    
    let owner_cell;
    if(this.props.show_owners) {
      let avatars = this.props.owner_img_urls.map((item, index) => {
        return (<Avatar src={item}  style={{position: "absolute", right: index * 20}}/>);
      });

      owner_cell =           
      <TableCell onClick={() => { this.props.onClick() }} >
        <div style={{display: "flex", flexDirection: "row", position: "relative", alignItems: "center"}}>
          {avatars}
        </div>
      </TableCell>;
    }

    let cloud_button;
    if(this.props.show_cloud_button) {
      cloud_button = 
      <IconButton className={classes.iconButtonDownload} onClick={this.preventPropagation.bind(this)}>
        <CloudDownloadOutline className={classes.icon}></CloudDownloadOutline>
      </IconButton>;
    } else {
      cloud_button = <div style={{marginRight: 3, width: 40, height: 40}}></div>
    }

    return (
      <TableRow key={this.props.key} className={classes.root}>
         <TableCell onClick={() => { this.props.onClick() }}>
          <Checkbox checked={this.props.selected} readOnly={true} color="primary"></Checkbox>
         </TableCell>
          <TableCell onClick={() => { this.props.onClick() }}>
            <div className={classes.imageSrc} 
            style={{ backgroundImage: `url(${this.props.image_url})`
            }} />
          </TableCell>
          <TableCell onClick={() => { this.props.onClick() }}>{this.props.filename}</TableCell>
          <TableCell onClick={() => { this.props.onClick() }}>{this.props.date.toLocaleDateString() + " " + this.props.date.toLocaleTimeString()}</TableCell>
          <TableCell onClick={() => { this.props.onClick() }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {cloud_button}
              <IconButton className={classes.iconButton} onClick={(event) =>
              {
                event.stopPropagation();
                if (this.props.onMoveClick !== undefined) this.props.onMoveClick();
              }}>
                <SvgIcon>
                  <path fill="currentColor" d="M14 2H6C4.9 2 4 2.9 4 4V20C4 20.41 4.12 20.8 4.34 21.12C4.41 21.23 4.5 21.33 4.59 21.41C4.95 21.78 5.45 22 6 22H13.53C13 21.42 12.61 20.75 12.35 20H6V4H13V9H18V12C18.7 12 19.37 12.12 20 12.34V8L14 2M18 23L23 18.5L20 15.8L18 14V17H14V20H18V23Z" />
                </SvgIcon>
              </IconButton>
              <IconButton className={classes.iconButton} onClick={(event) =>
              {
                event.stopPropagation();
                if (this.props.onRemoveClick !== undefined) this.props.onRemoveClick();
              }}>
                <TrashCanOutline className={classes.icon}></TrashCanOutline>
              </IconButton>
              <IconButton className={classes.iconButton} onClick={(event) =>
              {
                event.stopPropagation();
                if (this.props.onEditClick !== undefined) this.props.onEditClick();
              }}>
                <PencilOutline className={classes.icon}></PencilOutline>
              </IconButton>
            </div>
          </TableCell>
          { owner_cell }
      </TableRow>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ComponentTableRow);