import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import SortIcon from '@material-ui/icons/Sort';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import ComponentToolbarButton from '../core/ComponenToolbarButton';
import ShortTextIcon from '@material-ui/icons/ShortText';
import { ToggleButton } from '@material-ui/lab';
import { SvgIcon } from '@material-ui/core';
import { LayoutResourceLibraryMain } from './LayoutResourceLibraryMain';
import { withTranslation, WithTranslation } from 'react-i18next';


const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.background.default,
        minWidth: 60,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    label: {
      color: theme.palette.text.primary,
      maxWidth: 60,
      textAlign: "center"
    }
});

interface PropsComponentButtonBar extends WithStyles<typeof styles> {
  show_log: boolean;
  onShowLogToggle: () => void;
  sort_text: string;
  onSortClick: () => void;
  layout_text: string;
  onLayoutClick: () => void;
  layout: LayoutResourceLibraryMain;
  onMultiselectToggle: () => void;
  multiselect: boolean;
  show_selected: boolean;
  onShowSelectedToggle: () => void;
}

class ComponentButtonBar extends React.Component<PropsComponentButtonBar & WithTranslation, {}> {

  render() {

    const { t, classes } = this.props;
    
    let icon;
    if(this.props.layout == LayoutResourceLibraryMain.GRID) {
      icon = <ViewHeadlineIcon></ViewHeadlineIcon>;
    } else {
      icon = <ViewModuleIcon></ViewModuleIcon>;
    }

    return (
     <div className={classes.root}>
         <ComponentToolbarButton 
            text={this.props.sort_text} 
            onClick={() => { this.props.onSortClick() }}>
            <SortIcon></SortIcon>
         </ComponentToolbarButton>
         <ComponentToolbarButton 
            text={this.props.layout_text} 
            onClick={() => { this.props.onLayoutClick() }}>
            {icon}
         </ComponentToolbarButton>
          
         

         <ToggleButton value={this.props.multiselect} style={{padding: 0, borderRadius: 0}} selected={this.props.multiselect} onChange={() => { this.props.onMultiselectToggle() }}>
          <ComponentToolbarButton text={t("job_library_multi_select")} style={{height: 80}}>
              <SvgIcon>
                <path fill="currentColor" d="M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,16H8V4H20V16M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16M18.53,8.06L17.47,7L12.59,11.88L10.47,9.76L9.41,10.82L12.59,14L18.53,8.06Z" />
              </SvgIcon>
          </ComponentToolbarButton>
         </ToggleButton>

         <ToggleButton value={this.props.show_selected} style={{padding: 0, borderRadius: 0}} selected={this.props.show_selected} onChange={() => { this.props.onShowSelectedToggle() }}>
         <ComponentToolbarButton text={t("job_library_selected_only")} style={{height: 90}}>
              <SvgIcon>
                <path fill="currentColor" d="M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,5V19H5V5H19M10,17L6,13L7.41,11.58L10,14.17L16.59,7.58L18,9" />
              </SvgIcon>
          </ComponentToolbarButton>
         </ToggleButton>

         <div style={{flex: 1}}></div>

         <ToggleButton value={this.props.show_log} style={{padding: 0, borderRadius: 0}} selected={this.props.show_log} onChange={() => { this.props.onShowLogToggle() }}>
          <ComponentToolbarButton text="LOG">
              <ShortTextIcon></ShortTextIcon>
          </ComponentToolbarButton>
         </ToggleButton>
     </div>
    )
  }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(ComponentButtonBar));