import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Typography, Divider, SvgIcon } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';

const styles = (theme: Theme) => createStyles({
    root: {
      width: "100%",
      color: theme.palette.text.primary
    },
    track_c_slider: {
        color: "#007fa6",
        height: 8,
        borderRadius: 4
    },
    track_m_slider: {
        color: "#b31e7b",
        height: 8,
        borderRadius: 4
    },
    track_y_slider: {
        color: "#c3b900",
        height: 8,
        borderRadius: 4
    },
    track_k_slider: {
        color: "#000000",
        height: 8,
        borderRadius: 4
    },
    rail_slider: {
        height: 8,
        borderRadius: 4
    },
    thumb_slider: {
        opacity: 0
    },
    slider_root: {
      padding: 0,
      height: 8,
      marginRight: 5
    }
});

interface PropsComponentInkLevels extends WithStyles<typeof styles> {
  c: number,
  m: number,
  y: number,
  k: number,
  c_warning: boolean,
  m_warning: boolean,
  y_warning: boolean,
  k_warning: boolean,
  c_out: boolean,
  m_out: boolean,
  y_out: boolean,
  k_out: boolean
}

class ComponentInkLevels extends React.Component<PropsComponentInkLevels & WithTranslation, {}> {

  render() {
    
    const { t, classes } = this.props;

    let colors = [
      { color: "C", value: this.props.c, track_class: classes.track_c_slider, warning: this.props.c_warning, out: this.props.c_out  }, 
      { color: "M", value: this.props.m, track_class: classes.track_m_slider, warning: this.props.m_warning, out: this.props.m_out },
      { color: "Y", value: this.props.y, track_class: classes.track_y_slider, warning: this.props.y_warning, out: this.props.y_out },
      { color: "K", value: this.props.k, track_class: classes.track_k_slider, warning: this.props.k_warning, out: this.props.k_out }];
    let sliders = [];
    for(let i = 0; i < colors.length; i++) {
      sliders.push(
        <div style={{marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", height: 20}}>
          <Typography style={{marginRight: 5, width: 20}} variant="subtitle2">{colors[i].color}</Typography>
          <Slider classes={{root: classes.slider_root, rail: classes.rail_slider, track: colors[i].track_class, thumb: classes.thumb_slider}} disabled defaultValue={colors[i].value} />
          {colors[i].warning ? <SvgIcon style={{color: "orange", fontSize: "1.2em"}}>
            <path fill="currentColor" d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
          </SvgIcon> : ""}
          {colors[i].out ? <SvgIcon style={{color: "red", fontSize: "1.2em"}}>
            <path fill="currentColor" d="M15.46 15.88L16.88 14.46L19 16.59L21.12 14.47L22.53 15.88L20.41 18L22.54 20.12L21.12 21.54L19 19.41L16.88 21.53L15.47 20.12L17.59 18L15.46 15.88M5 19C5 19.55 5.45 20 6 20H13.34C13.61 20.75 14 21.42 14.53 22H6C4.34 22 3 20.66 3 19C3 18.4 3.18 17.84 3.5 17.36L9 7.81V6C8.45 6 8 5.55 8 5V4C8 2.9 8.9 2 10 2H14C15.11 2 16 2.9 16 4V5C16 5.55 15.55 6 15 6V7.81L17.5 12.18C16.86 12.35 16.24 12.63 15.69 13L13 8.35V4H11V8.35L5.18 18.43C5.07 18.59 5 18.79 5 19Z" />
          </SvgIcon> : ""}
          {!colors[i].out && !colors[i].warning ? <SvgIcon style={{color: "green", fontSize: "1.2em"}}>
          <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
          </SvgIcon> : ""}

        </div>
      )
    }

    return (
      <div className={classes.root}>
          <Typography variant="subtitle1">{t("ink_levels")}</Typography>
          <Divider style={{marginBottom: 8}}/>
          {sliders}
      </div>
    )
  }
}
export default withTranslation()(withStyles(styles, {withTheme: true})(ComponentInkLevels));