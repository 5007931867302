"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgHostNetworkSettings = exports.MsgSetHostNetworkSettings = exports.MsgGetHostNetworkSettings = exports.HostNetworkSettings = exports.THostNetworkSettingsIP = void 0;
var THostNetworkSettingsIP;
(function (THostNetworkSettingsIP) {
    THostNetworkSettingsIP[THostNetworkSettingsIP["STATIC"] = 0] = "STATIC";
    THostNetworkSettingsIP[THostNetworkSettingsIP["DHCP"] = 1] = "DHCP";
})(THostNetworkSettingsIP = exports.THostNetworkSettingsIP || (exports.THostNetworkSettingsIP = {}));
class HostNetworkSettings {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.HostNetworkSettings = HostNetworkSettings;
class MsgGetHostNetworkSettings {
    constructor(init) {
        this.Dummy = 0;
        Object.assign(this, init);
    }
}
exports.MsgGetHostNetworkSettings = MsgGetHostNetworkSettings;
class MsgSetHostNetworkSettings {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgSetHostNetworkSettings = MsgSetHostNetworkSettings;
class MsgHostNetworkSettings {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgHostNetworkSettings = MsgHostNetworkSettings;
