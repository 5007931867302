import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import CloudDownloadOutline from 'mdi-material-ui/CloudDownloadOutline';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import PencilOutline from 'mdi-material-ui/PencilOutline';
import Card from "@material-ui/core/Card";
import { IconButton, Checkbox, Avatar, SvgIcon, Button } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({

  image: {
    position: 'relative',
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.5,
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 40,
    display: 'flex',
    alignItems: "center",
    padding: 2,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    opacity: 0.9,
    zIndex: 100
  },
  imageSrcBackground: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    overflow: "hidden"
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    overflow: "hidden"
  },
  imageSrcNoEffect: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflow: "hidden"
  },
  checkboxContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 2
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.default,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    flex: 1,
    textAlign: "left",
    lineHeight: 1,
    wordBreak: "break-all"
  },
  icon: {
    width: 28,
    height: 28
  },
  root: {
    borderRadius: 0,
    overflow: "visible",
    width: "100%",
    height: "100%"
  },
  iconButton: {
    padding: 6
  },
  iconButtonDownload: {
    padding: 6,
    marginRight: 3
  },
  avatarContainer: {
    position: 'absolute',
    right: -6,
    top: -6,
    zIndex: 2,
  }
});

interface PropsComponentThumbnail extends WithStyles<typeof styles>
{
  image_url: string;
  filename?: string;
  selected?: boolean;
  show_selection?: boolean;
  onClick?: () => void;
  owner_img_urls?: string[];
  show_owners?: boolean;
  show_cloud_button?: boolean;
  onRemoveClick?: () => void;
  onMoveClick?: () => void;
  onEditClick?: () => void;
  doResizeAndBlurEffect: boolean;
  fillHeight?: boolean;
}

class ComponentThumbnail extends React.Component<PropsComponentThumbnail, {}> {

  private long_press: boolean = false;
  private timeout_id: number = -1;

  private img_container_ref_: React.RefObject<HTMLDivElement> = React.createRef();
  private img_ref_: React.RefObject<HTMLImageElement> = React.createRef();

  preventPropagation(e: React.SyntheticEvent)
  {
    e.stopPropagation();
  }

  updateImgSize()
  {
    let container = this.img_container_ref_.current;
    let img = this.img_ref_.current;

    if (container !== null && img !== null && container !== undefined && img !== undefined && container.clientHeight != 0 && container.clientWidth != 0 && img.clientWidth != 0 && img.clientHeight != 0)
    {
      let ratio_container = container.clientWidth / container.clientHeight;
      let ratio_img = img.clientWidth / img.clientHeight;

      if (ratio_img > ratio_container)
      {
        img.style.width = String(container.clientWidth) + "px";
        img.style.height = String(container.clientWidth / ratio_img) + "px";
      }
      else
      {
        img.style.width = String(container.clientHeight * ratio_img) + "px";
        img.style.height = String(container.clientHeight) + "px";
      }
    }
  }

  componentDidMount()
  {
    if (this.props.doResizeAndBlurEffect) window.addEventListener("resize", this.updateImgSize.bind(this));

  }

  componentWillUnmount()
  {
    if (this.props.doResizeAndBlurEffect) window.removeEventListener("resize", this.updateImgSize.bind(this));
  }


  render()
  {

    if (this.props.doResizeAndBlurEffect) window.setTimeout(this.updateImgSize.bind(this), 0);

    let classes = this.props.classes;

    let selection = this.props.show_selection ?
      <Checkbox checked={this.props.selected} readOnly={true} style={{ width: "100%", height: "100%", borderRadius: 0, backgroundColor: "rgba(0,0,0,0.2)" }} color="primary"></Checkbox>
      : undefined;

    let avatars;
    if (this.props.show_owners && this.props.owner_img_urls)
    {
      avatars = this.props.owner_img_urls.map((item, index) =>
      {
        return (<Avatar src={item} style={{ position: "absolute", right: index * 20 }} />);
      });
    }

    let cloud_button;
    if (this.props.show_cloud_button)
    {
      cloud_button =
        <IconButton className={classes.iconButtonDownload} onMouseDown={this.preventPropagation.bind(this)} >
          <CloudDownloadOutline className={classes.icon}></CloudDownloadOutline>
        </IconButton>;
    }

    let show_bottom_buttons = (this.props.filename !== undefined && this.props.filename !== "")
      || this.props.onMoveClick !== undefined
      || this.props.onRemoveClick !== undefined;


    return (

      <div style={{ paddingTop: this.props.fillHeight ? "0" : "100%", height: this.props.fillHeight ? "100%" : "auto", width: "100%", position: "relative" }}>
        <div style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}>
          <Card raised={true} className={classes.root}>
            <ButtonBase key={this.props.filename}
              className={classes.image}
              disabled={this.props.onClick === undefined}
              onClick={this.props.onClick}
              focusVisibleClassName={classes.focusVisible}
              style={{ width: "100%", height: "100%" }}
            >
              <span className={classes.checkboxContainer}>
                {selection}
              </span>
              <span className={classes.avatarContainer}>
                {avatars}
              </span>

              {this.props.doResizeAndBlurEffect ?
                <span className={classes.imageSrcBackground}>
                  <img src={this.props.image_url} style={{ width: "100%", height: "100%", filter: "blur(20px)", WebkitFilter: "blur(20px)", maxWidth: "100%", maxHeight: "100%" }} />
                </span> : null}

              {this.props.doResizeAndBlurEffect ?
                <span className={classes.imageSrc} ref={this.img_container_ref_} style={{ bottom: show_bottom_buttons ? 40 : 0 }}>
                  <img ref={this.img_ref_} src={this.props.image_url} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                </span>
                :
                <span className={classes.imageSrcNoEffect} ref={this.img_container_ref_}>
                  <img ref={this.img_ref_} src={this.props.image_url} style={{ width: "100%", height: "100%" }} />
                </span>
              }


              <span className={classes.imageSrcBackground} style={{ backgroundColor: this.props.selected ? "rgba(0,0,0,0.4)" : "rgba(255,255,255,0)" }}>

              </span>
              <span className={classes.imageBackdrop} />
              {
                show_bottom_buttons ?
                  <span className={classes.imageButton}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      className={classes.imageTitle}>
                      {this.props.filename}
                    </Typography>
                    {cloud_button}
                    {this.props.onMoveClick !== undefined ?
                      <IconButton className={classes.iconButton} onClick={(event) =>
                      {
                        event.stopPropagation();
                        if (this.props.onMoveClick !== undefined) this.props.onMoveClick();
                      }}>
                        <SvgIcon>
                          <path fill="currentColor" d="M14 2H6C4.9 2 4 2.9 4 4V20C4 20.41 4.12 20.8 4.34 21.12C4.41 21.23 4.5 21.33 4.59 21.41C4.95 21.78 5.45 22 6 22H13.53C13 21.42 12.61 20.75 12.35 20H6V4H13V9H18V12C18.7 12 19.37 12.12 20 12.34V8L14 2M18 23L23 18.5L20 15.8L18 14V17H14V20H18V23Z" />
                        </SvgIcon>
                      </IconButton>
                      : null}
                    {this.props.onRemoveClick !== undefined ?
                      <IconButton className={classes.iconButton} onClick={(event) =>
                      {
                        event.stopPropagation();
                        if (this.props.onRemoveClick !== undefined) this.props.onRemoveClick();
                      }}>
                        <TrashCanOutline className={classes.icon}></TrashCanOutline>
                      </IconButton>
                      : null}

                    {this.props.onEditClick !== undefined ?
                      <IconButton className={classes.iconButton} onClick={(event) =>
                      {
                        event.stopPropagation();
                        if (this.props.onEditClick !== undefined) this.props.onEditClick();
                      }}>
                        <PencilOutline className={classes.icon}></PencilOutline>
                      </IconButton>
                      : null}


                  </span>
                  : null}

            </ButtonBase>
          </Card>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ComponentThumbnail);