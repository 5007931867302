import { ActorSocketIO, MsgSocketIO } from '@ginf/appcore-actor';
import { TIdActor } from '@ginf/appcore-actor';
import { FactoryClass } from '@ginf/appcore-actor'
import { ResourceQueueItemJob } from "./core/ResourceQueueItemJob"
import { TActionReducerQueue } from './core/ReducerQueue';
import { TActionReducerQueueControl } from "./core/ReducerQueueControl";
import { TComponentResourceLibrarySideCopies } from "./core/ComponentResourceLibrarySide";
import { TTypeActionReducerQueue } from './core/ReducerQueue';
import {
  MsgRegisterView,
  MsgEngineStatus,
  MsgViewOfDirectoryListJobLibrary,
  MsgViewOfDirectoryJobLibrary,
  MsgActionDirectoryJobLibrary,
  TActionDirectoryJobLibrary,
  TActionMsgJobStatistics,
  MsgJobStatisticsInkStorm,
  MsgRestrictionView,
  MsgViewConfig,
  MsgViewOfQueueProcessResource,
  MsgModifyQueueProcessResource,
  TActionModifyQueueProcessResource,
  MsgControlQueueProcessResource,
  TActionControlQueueProcessResource,
  MsgActionJobLibrary,
  TActionJobLibrary,
  MsgActionJobStatistics,
  ViewOfProcessResourceJob,
  TStatusQueueProcessResource,
  MsgStatistics,
  MsgViewLanguage,
  TViewUnit,
  MsgUnitChange,
  MsgLogItem,
  MsgKeepAlive
} from '@ginf/inkstorm-msg';
import { rootReducer } from './RootReducer';
import { createStore, applyMiddleware } from 'redux';
import { TTypeActionReducerQueueControl } from './core/ReducerQueueControl';
import { TTypeActionReducerDashboardPage } from './dashboard-page/ReducerDashboardPage';
import { TTypeActionReducerComponentJobLibraryPage } from './job-library-page/ReducerComponentJobLibraryPage';
import { TTypeActionReducerJobEditorPage } from './job-editor-page/ReducerJobEditorPage';
import { i18n } from './i18n';
import { TTypeActionReducerMaintenancePage } from './maintenance-page/ReducerMaintenancePage';
import { TTypeActionReducerSettingsPage } from './settings-page/ReducerSettingsPage';
import isReachable from 'is-reachable';
import { TTypeActionReducerApp } from './ReducerApp';
import { TStateSwitchComponentInchChangeMm } from './ComponentInchChangeMm';


let actual_job_library_folder: string | undefined = undefined;
let actual_job_editing_path: string | undefined = undefined;

// This middleware will just add the property "async dispatch"
// to actions with the "async" propperty set to true
const asyncDispatchMiddleware = (store: { dispatch: (arg0: any) => void; }) => (next: (arg0: any) => any) => (action: any) => {
  let syncActivityFinished = false;
  let actionQueue: any = [];

  function flushQueue() {
    actionQueue.forEach((a: any) => store.dispatch(a)); // flush queue
    actionQueue = [];
  }

  function asyncDispatch(asyncAction: any) {
    actionQueue = actionQueue.concat([asyncAction]);

    if (syncActivityFinished) {
      flushQueue();
    }
  }

  const actionWithAsyncDispatch =
    Object.assign({}, action, { asyncDispatch });

  const res = next(actionWithAsyncDispatch);

  syncActivityFinished = true;
  flushQueue();

  return res;
};

export class ActorSocketIOViewMain extends ActorSocketIO {
  store_ = {
    MsgViewOfQueueProcessResource,
    MsgEngineStatus,
    MsgViewOfDirectoryListJobLibrary,
    MsgViewOfDirectoryJobLibrary,
    MsgJobStatisticsInkStorm,
    MsgRestrictionView,
    MsgViewConfig,
    MsgStatistics,
    MsgViewLanguage,
    MsgUnitChange,
    MsgLogItem,
    MsgKeepAlive
  };

  private thumbnail_cache_: Map<string, string> = new Map();
  private view_config_?: MsgViewConfig;
  private view_of_queue_process_resource?: MsgViewOfQueueProcessResource;
  private backend_timeout_timer_: NodeJS.Timeout;

  public AppStore: any;

  public ViewUnitChanged(new_unit: TViewUnit) {
    this.emitToController(new MsgUnitChange({ NewUnit: new_unit }));
  }

  store_handler = (store: any) => (next: (arg0: any) => any) => (action: any) => {
    if (action.queue_id == "job_queue") {
      switch (action.type) {
        case TTypeActionReducerQueue.PAUSE_RESOURCE:
          this.emitToController(new MsgModifyQueueProcessResource({ Action: TActionModifyQueueProcessResource.PAUSE, ResourceId: action.resource_id }));
          break;

        case TTypeActionReducerQueue.START_RESOURCE:
          this.emitToController(new MsgModifyQueueProcessResource({ Action: TActionModifyQueueProcessResource.START, ResourceId: action.resource_id }));
          break;

        case TTypeActionReducerQueue.CANCEL_RESOURCE:
          this.emitToController(new MsgModifyQueueProcessResource({ Action: TActionModifyQueueProcessResource.CANCEL, ResourceId: action.resource_id }));
          break;

        case TTypeActionReducerQueue.MOVE_DOWN_RESOURCE:
          this.emitToController(new MsgModifyQueueProcessResource({ Action: TActionModifyQueueProcessResource.MOVE_DOWN, ResourceId: action.resource_id }));
          break;

        case TTypeActionReducerQueue.MOVE_UP_RESOURCE:
          this.emitToController(new MsgModifyQueueProcessResource({ Action: TActionModifyQueueProcessResource.MOVE_UP, ResourceId: action.resource_id }));
          break;



        case TTypeActionReducerQueueControl.PAUSE_QUEUE:
          this.emitToController(new MsgControlQueueProcessResource({ Action: TActionControlQueueProcessResource.PAUSE_QUEUE }));
          break;

        case TTypeActionReducerQueueControl.CANCEL_QUEUE:
          this.emitToController(new MsgControlQueueProcessResource({ Action: TActionControlQueueProcessResource.CANCEL_QUEUE }));
          break;

        case TTypeActionReducerQueueControl.START_QUEUE:
          this.emitToController(new MsgControlQueueProcessResource({ Action: TActionControlQueueProcessResource.START_QUEUE }));
          break;
      }
    }

    switch (action.type) {
      case TTypeActionReducerComponentJobLibraryPage.ACTUAL_FOLDER_CHANGED:
        this.emitToController(new MsgActionDirectoryJobLibrary({ Action: TActionDirectoryJobLibrary.GET_DIRECTORY, DirectoryPath: action.data }));
        actual_job_library_folder = action.data;
        break;

      case TTypeActionReducerComponentJobLibraryPage.CREATE_NEW_FOLDER:
        this.emitToController(new MsgActionJobLibrary({ Action: TActionJobLibrary.CREATE_DIRECTORY, Directory: action.data }));
        break;

      case TTypeActionReducerComponentJobLibraryPage.DELETE_FOLDER:
        this.emitToController(new MsgActionJobLibrary({ Action: TActionJobLibrary.DELETE_DIRECTORY, Directory: action.data }));
        break;

      case TTypeActionReducerComponentJobLibraryPage.MOVE_FOLDER:
        this.emitToController(new MsgActionJobLibrary({ Action: TActionJobLibrary.MOVE_DIRECTORY, Directory: action.data.folder, DestinationDirectory: action.data.folder_dest }));
        break;

      case TTypeActionReducerComponentJobLibraryPage.RENAME_FOLDER:
        this.emitToController(new MsgActionJobLibrary({ Action: TActionJobLibrary.RENAME_DIRECTORY, Directory: action.data.folder, DestinationDirectory: action.data.new_folder_name }));
        break;


      case TTypeActionReducerComponentJobLibraryPage.REMOVE_JOBS:
        this.emitToController(new MsgActionJobLibrary({ Action: TActionJobLibrary.DELETE_ITEMS, Jobs: action.data }));
        break;

      case TTypeActionReducerComponentJobLibraryPage.MOVE_JOBS:
        this.emitToController(new MsgActionJobLibrary({ Action: TActionJobLibrary.MOVE_ITEMS, Jobs: action.data.jobs, DestinationDirectory: action.data.folder_dest }));
        break;

      case TTypeActionReducerComponentJobLibraryPage.PRINT_JOBS:
      case TTypeActionReducerJobEditorPage.PRINT_JOBS:
        let print_message = new MsgActionJobLibrary({ Action: TActionJobLibrary.PRINT_ITEMS });
        print_message.Jobs = new Array();
        print_message.CopyNumber = new Array();
        (action.data as TComponentResourceLibrarySideCopies).forEach(job => {
          print_message.Jobs?.push(job.id);
          print_message.CopyNumber?.push(job.copy);
        });
        this.emitToController(print_message);
        break;

      case TTypeActionReducerJobEditorPage.GET_DETAILS:
        actual_job_editing_path = action.data;
        this.emitToController(new MsgActionJobStatistics({ Action: TActionMsgJobStatistics.GET_DETAILS, JobId: action.data }));
        break;

      case TTypeActionReducerJobEditorPage.CANCEL_EDITING:
        actual_job_editing_path = "";
        action.asyncDispatch({ type: TTypeActionReducerJobEditorPage.LOAD_JOB, data: new MsgJobStatisticsInkStorm() }, 100);
        break;

      case TTypeActionReducerJobEditorPage.CHANGE_COPYNUMBER_JOB:
        this.emitToController(new MsgActionJobLibrary({ Action: TActionJobLibrary.CHANGE_COPY_NUMBER, NewCopyNumber: action.data.new_copy_number, Jobs: [action.data.job_id] }));
        break;

      case TTypeActionReducerJobEditorPage.RENAME_JOB:
        this.emitToController(new MsgActionJobLibrary({ Action: TActionJobLibrary.RENAME_ITEM, NewJobName: action.data.new_name, Jobs: [action.data.job_id] }));
        break;

      case TTypeActionReducerApp.USER_CHANGED_UNIT_VIEW:
        this.emitToController(new MsgUnitChange({ NewUnit: action.data == TStateSwitchComponentInchChangeMm.MM ? TViewUnit.MM : TViewUnit.INCH }));
        break;
    }
    let result = next(action)
    return result;
  }

  private emitToController(message_prm: any) {
    this.emit(message_prm, "controller");
  }

  private registerView(id_prm: TIdActor) {
    this.emitToController(new MsgRegisterView({ actor_id: id_prm }));
    setTimeout(() => {
      this.registerView(id_prm);
    }, 5000);

    /* setInterval(() => {
      this.emitToController(new MsgRegisterView({ actor_id: id_prm }));
      console.log(" ")
    }, 5000); */
  }

  constructor(id_prm: TIdActor, uri_prm: string, options_prm?: SocketIOClient.ConnectOpts) {
    super(id_prm, uri_prm, options_prm);
    this.AppStore = applyMiddleware(asyncDispatchMiddleware, this.store_handler)(createStore)(rootReducer);
    this.registerView(id_prm);

    setInterval(() => {
      // remove thumbnail cache every 30 minutes 
      this.thumbnail_cache_ = new Map();
    }, 1800000);

    this.backend_timeout_timer_ = setTimeout(() => {
      this.AppStore.dispatch({ type: TTypeActionReducerSettingsPage.SHOW_CONNECTION_LOST_MESSAGE, data: true });
    }, 5000);
  }


  processEngineStatusMessage(message: MsgEngineStatus) {
    this.AppStore.dispatch({ type: TTypeActionReducerDashboardPage.REFRESH_ENGINE_STATE, data: message });
    this.AppStore.dispatch({ type: TTypeActionReducerApp.REFRESH_ENGINE_STATE, data: message });
    this.AppStore.dispatch({ type: TTypeActionReducerJobEditorPage.REFRESH_ENGINE_STATE, data: message });
    this.AppStore.dispatch({ type: TTypeActionReducerMaintenancePage.REFRESH_ENGINE_STATE, data: message });
    this.AppStore.dispatch({ type: TTypeActionReducerSettingsPage.REFRESH_ENGINE_STATE, data: message });
  }

  processLanguage(message: MsgViewLanguage) {
    if (message.ExpressionsJSON !== undefined) {
      let lang = JSON.parse(message.ExpressionsJSON);
      for (var ns in lang) {
        for (let key of Object.keys(lang[ns])) {
          i18n.addResource("en", ns, key, lang[ns][key]);
        }
      }
      i18n.changeLanguage("en");
    }
  }

  private queueUpdateAvailable: boolean = true
  private lastItemQueueDump: MsgViewOfQueueProcessResource | undefined = undefined
  private timeoutForLastItem: NodeJS.Timeout | undefined

  processViewOfQueueProcessResource(message: MsgViewOfQueueProcessResource, id: TIdActor) {
    this.queueUpdateAvailable = false
    let job_queue_items = new Array<ResourceQueueItemJob>();
    message.Queue?.forEach(item => {
      let job = item as ViewOfProcessResourceJob;
      if (job.ResourceId !== undefined) {

        let thumbnailuri = this.thumbnail_cache_.has(job.ResourceId) ? this.thumbnail_cache_.get(job.ResourceId) : undefined;
        if (thumbnailuri === undefined && job.ThumbnailImage !== "" && job.ThumbnailImage !== undefined) {
          this.thumbnail_cache_.set(job.ResourceId, job.ThumbnailImage);
          thumbnailuri = job.ThumbnailImage;
        }

        let job_status = this.view_config_!.JobQueueItemStates[job.Status!];

        job_queue_items.push(
          {
            resource_id: job.ResourceId,
            name: job.DisplayName || "Print job",
            copies: job.CopiesNum,
            copies_done: job.CopiesDoneNum,
            pages_done: job.PagesDoneNum,
            total_pages: job.TotalPagesNum,
            processing: job.Status == this.view_config_!.JobQueueItemProcessingState,
            status: job_status || "unknown status",
            thumbnail_uri: thumbnailuri || "",
            paused: job.Status === this.view_config_!.JobQueueItemPausedState
          }

        );
      }

    });
    
    this.AppStore.dispatch({ type: TTypeActionReducerQueueControl.REFRESH_PAUSED, queue_id: "job_queue", data: message.Status === TStatusQueueProcessResource.PAUSED } as TActionReducerQueueControl);
    this.AppStore.dispatch({ type: TTypeActionReducerQueue.REFRESH_ITEMS, queue_id: "job_queue", data: job_queue_items } as TActionReducerQueue);


    ///this creates an issue that the last item is not gonna be shown, because it's most likely going to be in the dump

    setTimeout(() => {
      this.queueUpdateAvailable = true

    }, 0.0001);
  }

  process<T>(message: T, id: TIdActor): void {
    if (this.AppStore !== undefined) {
      if (message instanceof MsgViewOfQueueProcessResource) {
        if (this.view_config_ != undefined) {
          if (this.queueUpdateAvailable) {
            this.processViewOfQueueProcessResource(message, id);
            if (this.timeoutForLastItem !== undefined) {
              clearTimeout(this.timeoutForLastItem)
              this.timeoutForLastItem = undefined
            }
          }
          else {
            this.lastItemQueueDump = message
            if (this.timeoutForLastItem === undefined) {
              this.timeoutForLastItem = setTimeout(() => {
                this.processViewOfQueueProcessResource(this.lastItemQueueDump!, id);
                this.lastItemQueueDump = undefined
              }, 200);
            }
          }
        } else {
          this.view_of_queue_process_resource = message;
        }
      } else if (message instanceof MsgEngineStatus) {
        this.processEngineStatusMessage(message);
      }
      else if (message instanceof MsgViewOfDirectoryListJobLibrary) {
        this.AppStore.dispatch({ type: TTypeActionReducerComponentJobLibraryPage.REFRESH_FOLDERS, data: message });
        if (actual_job_library_folder === undefined) {
          this.AppStore.dispatch({ type: TTypeActionReducerComponentJobLibraryPage.ACTUAL_FOLDER_CHANGED, data: message.DirectoryPath });
        }
      }
      else if (message instanceof MsgViewOfDirectoryJobLibrary) {
        if (actual_job_library_folder === message.DirectoryPath) {
          this.AppStore.dispatch({ type: TTypeActionReducerComponentJobLibraryPage.REFRESH_JOBS, data: message });
        }
      }
      else if (message instanceof MsgJobStatisticsInkStorm) {
        if (actual_job_editing_path === (message as MsgJobStatisticsInkStorm).FilePath) {
          this.AppStore.dispatch({ type: TTypeActionReducerJobEditorPage.LOAD_JOB, data: message });
        }
      }
      else if (message instanceof MsgRestrictionView) {
        this.AppStore.dispatch({ type: TTypeActionReducerDashboardPage.REFRESH_VIEW_RESTRICTIONS, data: message });
        this.AppStore.dispatch({ type: TTypeActionReducerJobEditorPage.REFRESH_VIEW_RESTRICTIONS, data: message });
      }
      else if (message instanceof MsgViewConfig) {
        this.view_config_ = message;
        this.AppStore.dispatch({ type: TTypeActionReducerDashboardPage.REFRESH_VIEW_CONFIG, data: message });
        this.AppStore.dispatch({ type: TTypeActionReducerJobEditorPage.REFRESH_VIEW_CONFIG, data: message });
        this.AppStore.dispatch({ type: TTypeActionReducerMaintenancePage.REFRESH_VIEW_CONFIG, data: message });
        this.AppStore.dispatch({ type: TTypeActionReducerSettingsPage.REFRESH_VIEW_CONFIG, data: message });
        if (this.view_of_queue_process_resource != undefined) {
          this.processViewOfQueueProcessResource(this.view_of_queue_process_resource, id);
          this.view_of_queue_process_resource = undefined;
        }
      }
      else if (message instanceof MsgStatistics) {
        this.AppStore.dispatch({ type: TTypeActionReducerDashboardPage.REFRESH_STATISTICS, data: message });
      }
      else if (message instanceof MsgViewLanguage) {
        this.processLanguage(message);
      }
      else if (message instanceof MsgUnitChange) {
        this.AppStore.dispatch({ type: TTypeActionReducerApp.CHANGE_UNIT_VIEW, data: message.NewUnit });
      }
      else if (message instanceof MsgLogItem) {
        if (message.LogItem !== undefined) {
          this.AppStore.dispatch({ type: TTypeActionReducerApp.ADD_LOG, data: message.LogItem });
        }
      }
      else if (message instanceof MsgKeepAlive) {
        clearTimeout(this.backend_timeout_timer_);
        this.AppStore.dispatch({ type: TTypeActionReducerSettingsPage.SHOW_CONNECTION_LOST_MESSAGE, data: false });
        this.backend_timeout_timer_ = setTimeout(() => {
          this.AppStore.dispatch({ type: TTypeActionReducerSettingsPage.SHOW_CONNECTION_LOST_MESSAGE, data: true });
        }, 3000);
      }
    }
  }

};