import { IReduxStateMaintenancePage } from "./ComponentMaintenancePage";
import { MsgEngineStatus, MsgViewConfig } from "@ginf/inkstorm-msg";

const initialState : IReduxStateMaintenancePage =
{
    engine_status: new MsgEngineStatus(),
    view_config: new MsgViewConfig(),
    show_connection_lost_message:false

};



export enum TTypeActionReducerMaintenancePage
{
  /* accepted actions */
  REFRESH_ENGINE_STATE = "TTypeActionReducerMaintenancePage_REFRESH_ENGINE_STATE",
  REFRESH_VIEW_CONFIG = "TTypeActionReducerMaintenancePage_REFRESH_VIEW_CONFIG"
  /*emitted actions*/
}



export type TActionReducerMaintenancePage = 
/*accepted actions*/
  { type: TTypeActionReducerMaintenancePage.REFRESH_ENGINE_STATE, data: MsgEngineStatus }
| { type: TTypeActionReducerMaintenancePage.REFRESH_VIEW_CONFIG, data: MsgViewConfig };
/*emitted actions*/



export function ReducerMaintenancePage(
    state = initialState,
    action: TActionReducerMaintenancePage
  ): IReduxStateMaintenancePage
  {
    
    switch (action.type)
    {
        case TTypeActionReducerMaintenancePage.REFRESH_ENGINE_STATE:
          return {...state, engine_status: action.data};
        break;
        case TTypeActionReducerMaintenancePage.REFRESH_VIEW_CONFIG:
          return {...state, view_config: action.data};
        break;

      default:
        return state;
    }
  }