import { MsgEngineStatus, TViewUnit } from "@ginf/inkstorm-msg";
import { IReduxStateApp } from "./App";
import { TStateSwitchComponentInchChangeMm } from "./ComponentInchChangeMm";


const initialState: IReduxStateApp =
{
  mm_or_inch: TStateSwitchComponentInchChangeMm.MM,
  log_entries: new Array()
};



export enum TTypeActionReducerApp {
  /* accepted actions */
  CHANGE_UNIT_VIEW = "TTypeActionReducerApp_CHANGE_UNIT_VIEW",
  REFRESH_ENGINE_STATE = "TTypeActionReducerApp_REFRESH_ENGINE_STATE",
  ADD_LOG = "TTypeActionReducerApp_ADD_LOG",
  /*emitted actions*/
  USER_CHANGED_UNIT_VIEW = "TTypeActionReducerApp_USER_CHANGED_UNIT_VIEW",
}



export type TActionReducerApp =
  /*accepted actions*/
  { type: TTypeActionReducerApp.CHANGE_UNIT_VIEW, data: TViewUnit }
  | { type: TTypeActionReducerApp.ADD_LOG, data: Array<string> }
  | { type: TTypeActionReducerApp.REFRESH_ENGINE_STATE, data: MsgEngineStatus }
  /*emitted actions*/
  | { type: TTypeActionReducerApp.USER_CHANGED_UNIT_VIEW, data: TStateSwitchComponentInchChangeMm };



export function ReducerApp(
  state = initialState,
  action: TActionReducerApp
): IReduxStateApp {

  switch (action.type) {
    case TTypeActionReducerApp.CHANGE_UNIT_VIEW:
      return { ...state, mm_or_inch: action.data == TViewUnit.INCH ? TStateSwitchComponentInchChangeMm.INCH : TStateSwitchComponentInchChangeMm.MM };
      break;
    case TTypeActionReducerApp.REFRESH_ENGINE_STATE:
      return { ...state, mm_or_inch: action.data.mm_or_inch == "mm" /* TViewUnit.INCH */ ? TStateSwitchComponentInchChangeMm.MM : TStateSwitchComponentInchChangeMm.INCH };
      break;
    case TTypeActionReducerApp.ADD_LOG:
      let new_log: string[] = []
      //Don't keep more than 100 log entries
      if (state.log_entries.length < 100) {
        //the spread operator should also create a new object
        new_log = [...state.log_entries];
        //new_log = JSON.parse(JSON.stringify(state.log_entries));
      }
      new_log.push(...action.data);
      return { ...state, log_entries: new_log };
      break;

    default:
      return state;
  }
}