import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import ComponentResourceLibraryMain from "../core/ComponentResourceLibraryMain";
import ComponentResourceLibrarySide, { TComponentResourceLibrarySideCopies } from "../core/ComponentResourceLibrarySide";
import { LayoutResourceLibraryMain } from "../core/LayoutResourceLibraryMain";
import { FoldersComponentFolderBar } from '../core/ComponentFolderBar';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../RootReducer';
import { TTypeActionReducerComponentJobLibraryPage } from './ReducerComponentJobLibraryPage';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton } from '@material-ui/core';
import { ComponentInchChangeMm, TStateSwitchComponentInchChangeMm } from '../ComponentInchChangeMm';
import { withTranslation, WithTranslation } from 'react-i18next';
import i18n from 'i18next';
import FolderIcon from '@material-ui/icons/Folder';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { ConnectionLostBar } from '../ConnectionLostBar';

const styles = (theme: Theme) => createStyles({
    root: {
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    navigation_bar: {
        minHeight: 40,
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
    },
    icons_container: {
        width: 60,
        display: "flex",
        justifyContent: "center"
    },
    icon: {
        padding: 0
    },
    label: {
        color: theme.palette.text.primary
    },
    mmInchContainer: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    }
});

export type TArrayOfThumbnails = Array<{ cloud: boolean, image_url: string, filename: string, selected: boolean, owner_img_urls: string[], date: Date, size: number, filepath: string, copy_number: number }>;

enum TTypeSort {
    DATE = "DATE",
    OWNER = "OWNER",
    NAME = "NAME"
}

export interface IReduxStateComponentJobLibraryPage {
    thumbnails: TArrayOfThumbnails,
    folders: FoldersComponentFolderBar,
    prefix_path: string,
    show_connection_lost_message: boolean
}


const mapState = (state: RootState): IReduxStateComponentJobLibraryPage =>
({
    thumbnails: state.Library.thumbnails,
    folders: state.Library.folders,
    prefix_path: state.Library.prefix_path,
    show_connection_lost_message: state.SettingsPage.show_connection_lost_message
});

const mapDispatch = {
    actualFolderChanged: (folder_prm: string) => ({ type: TTypeActionReducerComponentJobLibraryPage.ACTUAL_FOLDER_CHANGED, data:  folder_prm }),
    createNewFolder: (folder_prm: string) => ({ type: TTypeActionReducerComponentJobLibraryPage.CREATE_NEW_FOLDER, data: folder_prm }),
    deleteFolder: (folder_prm: string) => ({ type: TTypeActionReducerComponentJobLibraryPage.DELETE_FOLDER, data: folder_prm }),
    moveFolder: (folder_prm: string, dest_folder_prm: string) => ({ type: TTypeActionReducerComponentJobLibraryPage.MOVE_FOLDER, data: { folder: folder_prm, folder_dest: dest_folder_prm } }),
    renameFolder: (folder_prm: string, new_folder_name_prm: string) => ({ type: TTypeActionReducerComponentJobLibraryPage.RENAME_FOLDER, data: { folder: folder_prm, new_folder_name: new_folder_name_prm } }),
    printJobs: (jobs_prm: TComponentResourceLibrarySideCopies) => ({ type: TTypeActionReducerComponentJobLibraryPage.PRINT_JOBS, data: jobs_prm }),
    removeJobs: (jobs_prm: Array<string>) => ({ type: TTypeActionReducerComponentJobLibraryPage.REMOVE_JOBS, data: jobs_prm }),
    moveJobs: (jobs_prm: Array<string>, dest_folder_prm: string) => ({ type: TTypeActionReducerComponentJobLibraryPage.MOVE_JOBS, data: { jobs: jobs_prm, folder_dest: dest_folder_prm } })

}

// Do the first half of the `connect()` call separately, 
// before declaring the component
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector> & WithTranslation;


interface PropsComponentJobLibraryPage extends PropsFromRedux, WithStyles<typeof styles> {
    visible: boolean;
    onPrintRequested: () => void;
    log_component: JSX.Element;
    onEditRequested: (filename: string, filepath: string) => void;
    onMmInchChange: (value: TStateSwitchComponentInchChangeMm) => void;
    currentMmInchState: TStateSwitchComponentInchChangeMm;
}

interface StateComponentJobLibraryPage {
    show_log: boolean;
    actual_sorting: TTypeSort;
    filtered_thumbnails: TArrayOfThumbnails;
    layout_text: string;
    layout: LayoutResourceLibraryMain;
    multiselect: boolean;
    show_selected: boolean;
    show_clear_selected: boolean;
    copies: TComponentResourceLibrarySideCopies;
    resource_lib_info: Array<{ name: string, value: string }>;
    resource_info: Array<{ name: string, value: string }>;

    show_remove_items_dialog: boolean;
    number_of_items_to_remove: number;
    one_item_to_remove: string | undefined;
}

class ComponentJobLibraryPage extends React.Component<PropsComponentJobLibraryPage, StateComponentJobLibraryPage> {

    orderThumbnails(array_prm: TArrayOfThumbnails): TArrayOfThumbnails {
        switch (this.state.actual_sorting) {
            case TTypeSort.DATE:
                array_prm.sort((a, b) => {
                    if (a.date < b.date) return 1;
                    if (a.date > b.date) return -1;
                    return 0;
                });
                break;

            case TTypeSort.NAME:
                array_prm.sort((a, b) => {
                    if (a.filename < b.filename) return -1;
                    if (a.filename > b.filename) return 1;
                    return 0;
                });
                break;

            case TTypeSort.OWNER:
                array_prm.sort((a, b) => {
                    if (a.date < b.date) return -1;  /* todo */
                    if (a.date > b.date) return 1;  /* todo */
                    return 0;
                });
                break;
        }
        return array_prm;
    }


    orderFilteredThumbnails() {
        this.filtered_thumbnails = this.orderThumbnails(this.filtered_thumbnails);
    }


    componentDidUpdate(prevProps: PropsComponentJobLibraryPage, prevState: StateComponentJobLibraryPage) {
        if (prevProps !== this.props) {
            this.filtered_thumbnails = this.props.thumbnails;
            let folder_size = 0;
            this.props.thumbnails.forEach(job => { folder_size += job.size });
            this.orderFilteredThumbnails();


            this.setState({
                filtered_thumbnails: this.filtered_thumbnails,
                resource_lib_info: [{ name: i18n.t("job_library_size"), value: Math.round((folder_size / (1024.0 * 1024.0)) * 100) / 100.0 + " MB" }, { name: i18n.t("job_library_jobs"), value: String(this.props.thumbnails.length) }]
            });
        }
    }

    handleMoveJobs(job_clicked_prm: string, folder_dest_prm: string) {
        if (this.filtered_thumbnails.find(job => job.filepath === job_clicked_prm && job.selected) !== undefined) {
            /* clicked job was selected, this means we need to move all the selected jobs */
            let jobs_to_move: Array<string> = new Array();
            this.filtered_thumbnails.filter(job => job.selected).forEach(job => {
                jobs_to_move.push(job.filepath);
            });
            this.props.moveJobs(jobs_to_move, folder_dest_prm);
            this.filtered_thumbnails.forEach(job => job.selected = false);
            this.setState({ filtered_thumbnails: this.filtered_thumbnails, copies: new Array(), show_clear_selected: false });
        }
        else {
            /* clicked job was not selected, only move clicked job */
            this.props.moveJobs([job_clicked_prm], folder_dest_prm);
            this.setState({ copies: new Array(), show_clear_selected: false });
        }
    }

    handleRemoveJobs(job_clicked_prm: string) {
        if (this.filtered_thumbnails.find(job => job.filepath === job_clicked_prm && job.selected) !== undefined) {
            /* clicked job was selected, this means we need to remove all the selected jobs */
            this.setState({ show_remove_items_dialog: true, number_of_items_to_remove: this.filtered_thumbnails.filter(job => job.selected).length, filtered_thumbnails: this.filtered_thumbnails });
        }
        else {
            /* clicked job was not selected, only remove clicked job */
            this.setState({ show_remove_items_dialog: true, one_item_to_remove: job_clicked_prm, number_of_items_to_remove: 1 });
        }
    }

    filtered_thumbnails: TArrayOfThumbnails;

    constructor(props: PropsComponentJobLibraryPage) {
        super(props);

        i18n.on("languageChanged", () => {
            this.refreshSidebarData();
        });

        this.state = {
            show_log: false,
            actual_sorting: TTypeSort.DATE,
            layout: LayoutResourceLibraryMain.GRID,
            layout_text: "LIST",
            filtered_thumbnails: this.props.thumbnails,
            multiselect: false,
            show_selected: false,
            show_clear_selected: false,
            copies: new Array(),
            resource_info: new Array(),
            resource_lib_info: new Array(),

            show_remove_items_dialog: false,
            number_of_items_to_remove: 0,
            one_item_to_remove: undefined
        };

        this.filtered_thumbnails = this.props.thumbnails;
    }

    prev_visible = false;

    renderRemoveJobsDialog() {
        return (
            <Dialog open={this.state.show_remove_items_dialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{i18n.t("job_library_delete_jobs")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {i18n.t("job_library_remove_question", { count: this.state.number_of_items_to_remove })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.setState({ show_remove_items_dialog: false, one_item_to_remove: undefined },
                            () => {
                                setTimeout(() => { this.setState({ number_of_items_to_remove: 0 }); }, 500);
                            })
                    }} color="primary">
                        {i18n.t("job_library_cancel")}
                    </Button>
                    <Button onClick={() => {
                        if (this.state.one_item_to_remove !== undefined) {
                            this.props.removeJobs([this.state.one_item_to_remove]);
                        }
                        else {
                            let jobs_to_remove: Array<string> = new Array();
                            this.filtered_thumbnails.filter(job => job.selected).forEach(job => {
                                jobs_to_remove.push(job.filepath);
                            });
                            this.props.removeJobs(jobs_to_remove);
                        }
                        this.filtered_thumbnails.forEach(job => job.selected = false);
                        this.setState({ filtered_thumbnails: this.filtered_thumbnails, show_remove_items_dialog: false, one_item_to_remove: undefined, show_clear_selected: false, copies: new Array() },
                            () => { setTimeout(() => { this.setState({ number_of_items_to_remove: 0 }); }, 500); });
                    }} color="primary">
                        {i18n.t("job_library_remove")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleEditJob(id: number) {
        this.props.onEditRequested(this.filtered_thumbnails[id].filename, this.filtered_thumbnails[id].filepath);
    }

    refreshSidebarData() {
        let selected_items = this.props.thumbnails.filter((item) => { return item.selected; });

        let selected_items_size = 0;
        let copies: TComponentResourceLibrarySideCopies = new Array();



        selected_items.forEach(job => {
            selected_items_size += job.size;

            let prev_copy_entry = this.state.copies.find(entry => entry.id === job.filepath);

            if (prev_copy_entry === undefined) {
                copies.push({ id: job.filepath, name: job.filename, copy: job.copy_number });
            }
            else {
                copies.push(prev_copy_entry);
            }
        });

        let selected_items_size_readable = Math.round((selected_items_size / (1024.0 * 1024.0)) * 100) / 100.0 + " MB";




        let show_clear_selected = selected_items.length > 0;

        if (selected_items.length == 0) {
            this.setState({
                filtered_thumbnails: this.state.filtered_thumbnails,
                show_clear_selected: show_clear_selected,
                copies: new Array(),
                resource_info: new Array()
            });
        }
        else {
            this.setState({
                filtered_thumbnails: this.state.filtered_thumbnails,
                show_clear_selected: show_clear_selected,
                copies: copies,
                resource_info: selected_items.length > 1 ?
                    [{ name: i18n.t("job_library_total_size"), value: selected_items_size_readable }] :
                    [{ name: i18n.t("job_library_size"), value: selected_items_size_readable },
                    { name: i18n.t("job_library_date"), value: selected_items[0].date.toLocaleDateString() },
                    { name: i18n.t("job_library_time"), value: selected_items[0].date.toLocaleTimeString() }
                    ]
            });
        }

        if (this.state.layout == LayoutResourceLibraryMain.LIST) {
            this.setState({ layout: LayoutResourceLibraryMain.LIST, layout_text: i18n.t("job_library_grid") });
        } else {
            this.setState({ layout: LayoutResourceLibraryMain.GRID, layout_text: i18n.t("job_library_list") });
        }
    }



    render() {
        const { t, classes } = this.props;

        if (this.props.visible && !this.prev_visible) {
            this.state = Object.assign({}, this.state, { filtered_thumbnails: this.filtered_thumbnails });
        } else if (!this.props.visible && this.prev_visible) {
            this.filtered_thumbnails = this.state.filtered_thumbnails;
            this.state = Object.assign({}, this.state, { filtered_thumbnails: this.state.filtered_thumbnails.slice(0, 9) });
        }

        this.prev_visible = this.props.visible;
        // console.log(this.state.filtered_thumbnails)
        return (
            <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    {this.renderRemoveJobsDialog()}
                    
                    {this.props.show_connection_lost_message ?
                        <div className={classes.navigation_bar}>
                            <div className={classes.icons_container}>
                                {/* <IconButton className={classes.icon}>
                                    <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                                </IconButton> */}
                            </div>
                            {ConnectionLostBar(!this.props.show_connection_lost_message, "0.7vw")}

                            <div style={{ flex: 1 }}></div>
                        </div>

                        : <></>}

                    {this.state.filtered_thumbnails !== undefined ?
                        <ComponentResourceLibraryMain
                            doResizeAndBlurEffect={navigator.appVersion.indexOf("rk3288") === -1}  /* check if gole */
                            folders={this.props.folders}
                            onCreateFolder={this.props.createNewFolder}
                            onDeleteFolder={this.props.deleteFolder}
                            onMoveFolder={this.props.moveFolder}
                            onFolderChange={this.props.actualFolderChanged}
                            onRenameFolder={this.props.renameFolder}
                            onMoveItem={this.handleMoveJobs.bind(this)}
                            onRemoveItem={this.handleRemoveJobs.bind(this)}
                            onThumbnailEdit={this.handleEditJob.bind(this)}
                            show_owners={false}
                            onShowSelectedToggle={
                                () => {
                                    let filtered_thumbnails;
                                    if (!this.state.show_selected) {
                                        filtered_thumbnails = this.props.thumbnails.filter((item) => {
                                            return item.selected;
                                        });
                                    } else {
                                        filtered_thumbnails = this.props.thumbnails;
                                    }

                                    this.setState({ show_selected: !this.state.show_selected, filtered_thumbnails: filtered_thumbnails });
                                }
                            }
                            show_selected={this.state.show_selected}
                            onMultiSelectToggle={
                                () => {
                                    if (this.state.multiselect) {
                                        for (let i = 0; i < this.state.filtered_thumbnails.length; i++) {
                                            this.state.filtered_thumbnails[i].selected = false;
                                        }
                                    }
                                    this.setState({ multiselect: !this.state.multiselect, filtered_thumbnails: this.state.filtered_thumbnails });
                                }
                            }
                            multiselect={this.state.multiselect}
                            layout={this.state.layout}
                            onLayoutClick={
                                () => {
                                    if (this.state.layout == LayoutResourceLibraryMain.GRID) {
                                        this.setState({ layout: LayoutResourceLibraryMain.LIST, layout_text: t("job_library_grid") });
                                    } else {
                                        this.setState({ layout: LayoutResourceLibraryMain.GRID, layout_text: t("job_library_list") });
                                    }
                                }

                            }
                            layout_text={this.state.layout_text}
                            onThumbnailClick={
                                (id: number) => {
                                    if (this.state.multiselect) {
                                        this.state.filtered_thumbnails[id].selected = !this.state.filtered_thumbnails[id].selected;
                                    } else {
                                        let item_was_selected = this.state.filtered_thumbnails[id].selected;
                                        for (let i = 0; i < this.state.filtered_thumbnails.length; i++) {
                                            this.state.filtered_thumbnails[i].selected = false;
                                        }
                                        this.state.filtered_thumbnails[id].selected = !item_was_selected;
                                    }

                                    this.refreshSidebarData();

                                }
                            }
                            sort_text={this.state.actual_sorting === TTypeSort.DATE ? t("job_library_sort_name") : t("job_library_sort_date")}
                            onSortClick={
                                () => {
                                    let actual_sorting;
                                    if (this.state.actual_sorting == TTypeSort.DATE) {
                                        actual_sorting = TTypeSort.NAME;
                                    } else {
                                        actual_sorting = TTypeSort.DATE;
                                    }

                                    this.setState({ actual_sorting: actual_sorting }, () => {
                                        this.orderFilteredThumbnails();
                                        this.setState({ filtered_thumbnails: this.filtered_thumbnails });
                                    });

                                }
                            }
                            show_log={this.state.show_log}
                            onShowLogToggle={() => {
                                this.setState({ show_log: !this.state.show_log })
                            }}
                            thumbnail_size={{ width: 236, height: 236 }}
                            thumbnails={this.state.filtered_thumbnails}>

                        </ComponentResourceLibraryMain> : <></>}
                    <div>
                        <div style={{ height: this.state.show_log ? 90 : 0 }}>
                            {this.props.log_component}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", width: 180, minWidth: 180, flexDirection: "column" }}>
                    <ComponentResourceLibrarySide
                        onPrintSelectedClicked={() => {
                            this.props.printJobs(this.state.copies);
                            this.props.onPrintRequested();
                        }}
                        onPrintFolderClicked={() => {
                            let copies: TComponentResourceLibrarySideCopies = new Array();
                            let to_be_printed = this.props.thumbnails;
                            this.orderThumbnails(to_be_printed).forEach(job => {
                                copies.push({ copy: job.copy_number, name: job.filename, id: job.filepath });
                            });
                            this.props.onPrintRequested();
                            this.props.printJobs(copies);
                        }}
                        header_text_control={t("job_library_job_control")}
                        header_text_library_info={t("job_library_folder_info")}
                        header_text_resource_info={t("job_library_job_info")}
                        resource_info={this.state.resource_info}
                        resource_library_info={this.state.resource_lib_info}
                        onClearSelected={
                            () => {

                                for (let i = 0; i < this.state.filtered_thumbnails.length; i++) {
                                    this.state.filtered_thumbnails[i].selected = false;
                                }

                                let filtered_thumbnails;
                                if (this.state.show_selected) {
                                    filtered_thumbnails = this.props.thumbnails.filter((item) => {
                                        return item.selected;
                                    });
                                } else {
                                    filtered_thumbnails = this.props.thumbnails;
                                }

                                this.setState({ filtered_thumbnails: filtered_thumbnails, show_clear_selected: false, copies: new Array(), resource_info: new Array() });
                            }
                        }
                        show_clear_selected={this.state.show_clear_selected}
                        onCopiesChange={
                            (copies: TComponentResourceLibrarySideCopies) => {
                                this.setState({ copies: copies });
                            }
                        }
                        copies={this.state.copies}
                    ></ComponentResourceLibrarySide>
                    <div className={classes.mmInchContainer}>
                        {window.oem_config?.mm_inch_job_library?
                        
                        <ComponentInchChangeMm mmOrInch={this.props.currentMmInchState} valueChanged={this.props.onMmInchChange} />:<></>}
                    </div>

                </div>
            </div>
        )
    }
}

export default withTranslation()(withStyles(styles, { withTheme: true })(connector(ComponentJobLibraryPage)));