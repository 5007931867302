import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowY: "auto",
    color: theme.palette.text.primary
  },
  entriesContainer: {
    padding: 5,
    height: 0
  },
  entry: {
    fontWeight: "bold"
  }
});

interface PropsComponentIFrame extends WithStyles<typeof styles> {
  frame_url: string,
}

class ComponentIFrame extends React.Component<PropsComponentIFrame, {}> {


  render() {

    let classes = this.props.classes;

    return (
      <div className={classes.root}>
        <div className={classes.entriesContainer}>
          <iframe width={"100%"} src={this.props.frame_url}></iframe>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ComponentIFrame);
