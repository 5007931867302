import { IStateQueueControl } from './ComponentQueueControl'
import { TIdentifierReducerQueue } from './ReducerQueue'


const initialState : IStateQueueControl =
{
    paused: new Map(),
    resources: new Map()
};


export enum TTypeActionReducerQueueControl
{
  /* accepted actions */
  REFRESH_PAUSED = "TTypeActionReducerQueueControl_REFRESH_PAUSED",

  /* emitted actions */
  PAUSE_QUEUE = "TTypeActionReducerQueueControl_PAUSE_QUEUE",
  START_QUEUE = "TTypeActionReducerQueueControl_START_QUEUE",
  CANCEL_QUEUE = "TTypeActionReducerQueueControl_CANCEL_QUEUE"
}



export type TActionReducerQueueControl = 
/*accepted actions*/
{ queue_id: TIdentifierReducerQueue, type: TTypeActionReducerQueueControl.REFRESH_PAUSED, data: boolean  }

/* emitted actions */
| { queue_id: TIdentifierReducerQueue, type: TTypeActionReducerQueueControl.PAUSE_QUEUE }
| { queue_id: TIdentifierReducerQueue, type: TTypeActionReducerQueueControl.START_QUEUE }
| { queue_id: TIdentifierReducerQueue, type: TTypeActionReducerQueueControl.CANCEL_QUEUE }


export function ReducerQueueControl(
    state = initialState,
    action: TActionReducerQueueControl
  ): IStateQueueControl
  {
    
    switch (action.type)
    {
        case TTypeActionReducerQueueControl.REFRESH_PAUSED:
          let m : Map<TIdentifierReducerQueue, boolean> = new Map();
          state.paused.forEach((val,key) => {
            m.set(key,val);
          });
          m.set(action.queue_id,action.data);
          return {...state, paused: m};
        break;

      default:
        return state;
    }
  }