import { IReduxStateDashboardPage } from "./ComponentDashboardPage";
import { MsgEngineStatus, MsgStatistics, MsgRestrictionView, MsgViewConfig } from "@ginf/inkstorm-msg";
import { TItemComponentInkDrops } from '../core/ComponentInkDrops';

const initialState: IReduxStateDashboardPage =
{
  engine_status: new MsgEngineStatus(),
  view_restrictions: new MsgRestrictionView(),
  view_config: new MsgViewConfig(),
  ink_items: [],
  ink_text: [],
  show_connection_lost_message: false,
};



export enum TTypeActionReducerDashboardPage
{
  /* accepted actions */
  REFRESH_ENGINE_STATE = "TTypeActionReducerDashboardPage_REFRESH_ENGINE_STATE",
  REFRESH_PWB_STATE = "TTypeActionReducerDashboardPage_REFRESH_PWB_STATE",
  REFRESH_ENGINE_PARAMS = "TTypeActionReducerDashboardPage_REFRESH_ENGINE_PARAMS",
  REFRESH_VIEW_RESTRICTIONS = "TTypeActionReducerDashboardPage_REFRESH_VIEW_RESTRICTIONS",
  REFRESH_VIEW_CONFIG = "TTypeActionReducerDashboardPage_REFRESH_VIEW_CONFIG",
  REFRESH_STATISTICS = "TTypeActionReducerDashboardPage_REFRESH_STATISTICS"
}



export type TActionReducerDashboardPage =
  /*accepted actions*/
  { type: TTypeActionReducerDashboardPage.REFRESH_ENGINE_STATE, data: MsgEngineStatus }
  | { type: TTypeActionReducerDashboardPage.REFRESH_VIEW_RESTRICTIONS, data: MsgRestrictionView }
  | { type: TTypeActionReducerDashboardPage.REFRESH_VIEW_CONFIG, data: MsgViewConfig }
  | { type: TTypeActionReducerDashboardPage.REFRESH_STATISTICS, data: MsgStatistics }


export function ReducerDashboardPage(
  state = initialState,
  action: TActionReducerDashboardPage
): IReduxStateDashboardPage
{

  switch (action.type)
  {
    case TTypeActionReducerDashboardPage.REFRESH_ENGINE_STATE:
      return { ...state, engine_status: action.data };
      break;
    
    case TTypeActionReducerDashboardPage.REFRESH_VIEW_RESTRICTIONS:
      return { ...state, view_restrictions: action.data };
      break;
    
    case TTypeActionReducerDashboardPage.REFRESH_VIEW_CONFIG:
      return { ...state, view_config: action.data };
      break;

    case TTypeActionReducerDashboardPage.REFRESH_STATISTICS:

      let ink_items: Array<Array<TItemComponentInkDrops>> = [];
      let ink_text: Array<{ up_cost: string, up_ink: string, down_cost: string, down_ink: string }> = [];

      action.data.StatisticsItems?.forEach((statistics) => {

        let ink_item = statistics.StatisticItem?.map((stat_item) => {
          let item: TItemComponentInkDrops = {
            color: stat_item.Color!,
            color_name: stat_item.ColorName!,
            color_name_color: stat_item.ColorNameColor!,
            down_drops: stat_item.DownDrops!,
            down_drops_cost: stat_item.DownDropsCost!,
            up_drops: stat_item.UpDrops!,
            up_drops_cost: stat_item.UpDropsCost!
          };
          return item;
        });
        ink_items.push(ink_item!);

      });

      ink_text = action.data.StatisticsTexts?.map((statistics_text) => {
        let item: { up_cost: string, up_ink: string, down_cost: string, down_ink: string };
        item = {
          up_cost: statistics_text.UpCost!,
          up_ink: statistics_text.UpInk!,
          down_cost: statistics_text.DownCost!,
          down_ink: statistics_text.DownInk!
        }
        return item;
      })!;
      
      return { ...state, ink_items: ink_items, ink_text: ink_text };
      break;

    default:
      return state;
  }
}