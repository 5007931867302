"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgActionDirectoryJobLibrary = exports.TActionDirectoryJobLibrary = exports.MsgViewOfDirectoryListJobLibrary = exports.MsgViewOfDirectoryJobLibrary = void 0;
class MsgViewOfDirectoryJobLibrary {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgViewOfDirectoryJobLibrary = MsgViewOfDirectoryJobLibrary;
class MsgViewOfDirectoryListJobLibrary {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgViewOfDirectoryListJobLibrary = MsgViewOfDirectoryListJobLibrary;
var TActionDirectoryJobLibrary;
(function (TActionDirectoryJobLibrary) {
    TActionDirectoryJobLibrary[TActionDirectoryJobLibrary["GET_DIRECTORY_LIST"] = 0] = "GET_DIRECTORY_LIST";
    TActionDirectoryJobLibrary[TActionDirectoryJobLibrary["GET_DIRECTORY"] = 1] = "GET_DIRECTORY";
})(TActionDirectoryJobLibrary = exports.TActionDirectoryJobLibrary || (exports.TActionDirectoryJobLibrary = {}));
class MsgActionDirectoryJobLibrary {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.MsgActionDirectoryJobLibrary = MsgActionDirectoryJobLibrary;
