import { IReduxStateComponentJobLibraryPage, TArrayOfThumbnails } from "./ComponentJobLibraryPage";
import { MsgViewOfDirectoryJobLibrary, MsgViewOfDirectoryListJobLibrary, ViewOfItemJobLibraryInkStorm } from "@ginf/inkstorm-msg";
import { FoldersComponentFolderBar } from '../core/ComponentFolderBar';
import  { TComponentResourceLibrarySideCopies } from "../core/ComponentResourceLibrarySide";

const initialState: IReduxStateComponentJobLibraryPage =
{
    thumbnails: new Array(),
    folders: new FoldersComponentFolderBar(),
    prefix_path: "",
    show_connection_lost_message:false

};

export enum TTypeActionReducerComponentJobLibraryPage
{
    /* accepted actions */
    REFRESH_JOBS = "TTypeActionReducerComponentJobLibraryPage_REFRESH_JOBS",
    REFRESH_FOLDERS = "TTypeActionReducerComponentJobLibraryPage_REFRESH_FOLDERS",

    /* emitted actions */
    ACTUAL_FOLDER_CHANGED = "TTypeActionReducerComponentJobLibraryPage_ACTUAL_FOLDER_CHANGED",
    PRINT_JOBS = "TTypeActionReducerComponentJobLibraryPage_PRINT_JOBS",
    CREATE_NEW_FOLDER = "TTypeActionReducerComponentJobLibraryPage_CREATE_NEW_FOLDER",
    DELETE_FOLDER = "TTypeActionReducerComponentJobLibraryPage_DELETE_FOLDER",
    MOVE_FOLDER = "TTypeActionReducerComponentJobLibraryPage_MOVE_FOLDER",
    RENAME_FOLDER = "TTypeActionReducerComponentJobLibraryPage_RENAME_FOLDER",
    REMOVE_JOBS = "TTypeActionReducerComponentJobLibraryPage_REMOVE_JOB",
    MOVE_JOBS = "TTypeActionReducerComponentJobLibraryPage_MOVE_JOBS"
}



export type TActionReducerComponentJobLibraryPage =
    /*accepted actions*/
    { type: TTypeActionReducerComponentJobLibraryPage.REFRESH_JOBS, data: MsgViewOfDirectoryJobLibrary }
    | { type: TTypeActionReducerComponentJobLibraryPage.REFRESH_FOLDERS, data: MsgViewOfDirectoryListJobLibrary }

    /* emitted actions */
    | { type: TTypeActionReducerComponentJobLibraryPage.ACTUAL_FOLDER_CHANGED, data: string }
    | { type: TTypeActionReducerComponentJobLibraryPage.CREATE_NEW_FOLDER, data: string }
    | { type: TTypeActionReducerComponentJobLibraryPage.DELETE_FOLDER, data: string }
    | { type: TTypeActionReducerComponentJobLibraryPage.MOVE_FOLDER, data: {folder: string, folder_dest: string } }
    | { type: TTypeActionReducerComponentJobLibraryPage.RENAME_FOLDER, data: {folder: string, new_folder_name: string } }
    | { type: TTypeActionReducerComponentJobLibraryPage.PRINT_JOBS, data: TComponentResourceLibrarySideCopies }
    | { type: TTypeActionReducerComponentJobLibraryPage.REMOVE_JOBS, data:  Array<string>}
    | { type: TTypeActionReducerComponentJobLibraryPage.MOVE_JOBS, data:  {jobs: Array<string>, folder_dest: string}}


export function ReducerComponentJobLibraryPage(
    state = initialState,
    action: TActionReducerComponentJobLibraryPage
): IReduxStateComponentJobLibraryPage
{

    switch (action.type)
    {
        case TTypeActionReducerComponentJobLibraryPage.REFRESH_JOBS:
            let thumbnails: TArrayOfThumbnails = new Array();
            let folder_size = 0;
            action.data.Items?.forEach(item => 
            {
                folder_size += item.FileSize || 0;
                let thumbnailuri = "";
                if (navigator.appVersion.indexOf("rk3288") === -1)
                {
                    thumbnailuri = item.ThumbnailURI  || "";
                }
                else
                {
                    let prepared = (item as ViewOfItemJobLibraryInkStorm).PreparedThumbnail;
                    thumbnailuri = (prepared !== undefined && prepared !== "" ? prepared : item.ThumbnailURI || "");
                }
                thumbnails.push({
                    cloud: false,
                    date: item.AddedTimeISO ? new Date(item.AddedTimeISO) : new Date(),
                    filename: (item.JobName ? item.JobName : "Loading..."),
                    image_url: thumbnailuri,
                    selected: state.thumbnails.find(thumb => thumb.filepath === item.Filename)?.selected || false,
                    owner_img_urls: new Array(),
                    size: item.FileSize || 0,
                    filepath: item.Filename || "",
                    copy_number: item.CopyNumber || 1
                });
            });
            return {
                ...state,
                thumbnails: thumbnails
            };
            break;

        case TTypeActionReducerComponentJobLibraryPage.REFRESH_FOLDERS:
            // if(action.data.SubDirectories){
            //     action.data = replaceDirectoryPath(action.data)
            // }
            let prefix = action.data.DirectoryPath?.split("/")
            prefix?.pop()
            let prefix_path = prefix?.join("/") || ""
            console.log("prefix_path:   ", prefix_path)
            // cutPathByPrefix(action.data, prefix_path)
            console.log("----------REFRESH_FOLDERS:   ", action.data)
            return { ...state, folders: action.data, prefix_path: prefix_path };
            break;

        default:
            return state;
    }
}
function cutPathByPrefix(msg:MsgViewOfDirectoryListJobLibrary, prefix: string): MsgViewOfDirectoryListJobLibrary
{
    msg.DirectoryPath =msg.DirectoryPath?.split(prefix)[1] || msg.DirectoryPath
    if(msg.SubDirectories){
        msg.SubDirectories.map((subdir)=>{
            return cutPathByPrefix(subdir, prefix)
        })
    }
    return msg
}
function appendPrefix(msg:MsgViewOfDirectoryListJobLibrary, prefix: string): MsgViewOfDirectoryListJobLibrary
{
    msg.DirectoryPath = prefix + msg.DirectoryPath
    if(msg.SubDirectories){
        msg.SubDirectories.map((subdir)=>{
            return appendPrefix(subdir, prefix)
        })
    }
    return msg
}
